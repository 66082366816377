<script setup lang="ts">
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useContentInfo } from "@/stores/contentInfo";
import { onMounted } from "vue";

import activity_up from "@image/memberFunction/02/activity_up.png";
import activity_down from "@image/memberFunction/02/activity_down.png";
import btn_n from "@image/memberFunction/common/btn_n.png";
import 裝飾左 from "@image/memberFunction/02/裝飾左.png";
import 裝飾右 from "@image/memberFunction/02/裝飾右.png";

import ContentDiscountWeb from "@/components/content/Discount/contentDiscountWeb.vue";
const contentInfo = useContentInfo();
const router = useRouter();

const { SaleList } = storeToRefs(contentInfo);

const activeItems = ref<boolean[]>([]);
watch(SaleList, () => {
  activeItems.value = SaleList.value.map(() => {
    return false;
  });
});

const toggleItem = (index: any) => {
  activeItems.value[index] = !activeItems.value[index];
};
function toService() {
  router.push({ name: "Service" });
}

function getSaleUrl(url: string) {
  console.log("getSaleUrl");
  contentInfo.getSaleUrlData(url);
}

onMounted(() => {
  contentInfo.toGetSale();
});
</script>

<template>
  <div class="hidden md:block">
    <ContentDiscountWeb />
  </div>
  <div
    class="mx-5 text-[#fff] mb-5 h-[94dvh] overflow-y-auto block md:hidden gamePlatform-scrollbar">
    <ul class="relative mt-[1vh] pb-[14dvh] md:mb-0">
      <li
        class="mt-[3vw] first:mt-0 relative"
        v-for="(items, index) in SaleList"
        :key="items.nId">
        <button
          class="z-10 absolute w-[98%] left-1/2 -translate-x-1/2 top-[5px]"
          @click="toggleItem(index)">
          <div class="rounded-[6px] relative">
            <div
              class="z-10 rounded-[8px] overflow-hidden absolute top-[calc(50%-.1vh)] -translate-y-1/2 left-1/2 -translate-x-1/2 h-[calc(100%-.4vh)] w-[calc(100%-1vw)]">
              <img class="w-full relative" :src="items.img_url" alt="" />
            </div>
            <img v-img-src="activity_up" alt="" />
          </div>
        </button>

        <div class="relative">
          <img v-img-src="activity_down" alt="" />

          <div
            class="text-[14px] absolute flex justify-between w-full px-[12px] bottom-[5px]">
            <p class="font-bold py-[.2vh]">
              {{ items.sTitle0 }}
            </p>
            <button
              type="button"
              class="relative px-[4vw] mr-[3vw]"
              @click="toggleItem(index)"
              v-if="!activeItems[index]">
              <img v-img-src="btn_n" alt="" class="h-full w-full absolute" />
              <p
                class="text-primary relative z-10 py-[.2vh] left-[4vw] tracking-[.4vw] font-bold">
                {{ $t("Context.Discount.Details") }}
              </p>
            </button>
          </div>
        </div>
        <transition name="zoom" mode="out-in">
          <div
            v-if="activeItems[index]"
            class="mt-[2vw] w-full member-bg-border">
            <div class="w-full h-full z-20 relative py-[12px]">
              <div class="flex justify-center items-center mt-[12px]">
                <img v-img-src="裝飾左" alt="" class="w-[30%]" />
                <p class="mx-[12px] text-[14px] text-yellow-400">
                  {{ $t("Context.Discount.ActivityInfo") }}
                </p>
                <img v-img-src="裝飾右" alt="" class="w-[30%]" />
              </div>
              <div class="mt-[12px] px-[12px] text-[14px] leading-[24px]">
                <div class="discount-table" v-html="items.sContent0"></div>
              </div>
              <div class="text-[14px] flex justify-around my-[12px]">
                <button
                  type="button"
                  class="btn-y-border px-[4vw]"
                  @click="toService()">
                  <p class="text-secondary z-10 py-[.2vh] tracking-[.4vw]">
                    {{ $t("Context.Discount.ContactService") }}
                  </p>
                </button>
                <button
                  v-if="items.join_event_url"
                  type="button"
                  class="btn-n-border px-[4vw]"
                  @click="getSaleUrl(items.join_event_url)">
                  <p class="text-primary z-10 py-[.2vh] tracking-[.4vw]">
                    {{ $t("Context.Discount.Join") }}
                  </p>
                </button>
                <button
                  type="button"
                  class="btn-n-border px-[4vw]"
                  @click="toggleItem(index)">
                  <p class="text-primary z-10 py-[.2vh] tracking-[.4vw]">
                    {{ $t("Context.Discount.ClosePage") }}
                  </p>
                </button>
              </div>
            </div>
          </div>
        </transition>
      </li>
    </ul>
  </div>
</template>

<style>
.zoom-enter-active {
  transform: scale(0);
  transition: all 0.3s ease-out;
}
.zoom-leave-active {
  transition: all 0.3s ease-in; /* 过渡动画的属性和持续时间 */
}

.zoom-leave-to {
  transform: scale(0);
}

.zoom-enter-to {
  transform: scale(1);
}
.bgColor {
  background: linear-gradient(45deg, #3f3f3f, #3f3f3f);
  border-style: solid;
  border-image: linear-gradient(180deg, #e4d3bc, #563d2a, #552f06) 1;
  border-image-slice: 1 1 1 1;
}

.bgColor::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid transparent;
  border-image: linear-gradient(180deg, #e4d3bc, #563d2a, #552f06);
  border-image-slice: 1;
  z-index: -1;
}
.discount-table table {
  width: 100%;
  margin: auto;
  border-collapse: collapse;
  margin-bottom: 20px; /* 添加一些底部间距，根据需要调整 */
}

.discount-table th,
.discount-table td {
  border: 1px solid #ddd;
  padding: 2px;
  text-align: center;
}
</style>
