<script setup lang="ts">
import { onMounted } from "vue";
import { useLoadingPage } from "@/stores/loadingPage";
import loading_page_2 from "@image/memberFunction/文字框/loading-page-2.png";
const LoadingPage = useLoadingPage();

onMounted(() => {
  LoadingPage.toGameUrl();
});
</script>
<template>
  <img v-img-src="loading_page_2" alt="" class="object-cover h-[100vh]" />
</template>
