<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useErrorAlert } from "@/stores/errorAlert";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";

import USDT_copy from "@image/memberFunction/12/USDT_copy.png";
import 驚嘆號icon from "@image/memberFunction/11/驚嘆號icon.png";

const { t } = useI18n();
const errorAlert = useErrorAlert();
const memberService = useMemberService();
const store = useCounterStore();

const { USDTFormInfo } = storeToRefs(memberService);

async function copyUrl(url: string) {
  // await navigator.clipboard.writeText(url);
  const textArea: HTMLInputElement = document.createElement("input");
  textArea.value = url;
  document.body.appendChild(textArea);
  textArea.select();
  // 执行复制命令并移除文本框
  document.execCommand("copy")
    ? errorAlert.AddErrorAlert(t("MemberFunction.Deposit.CopyCompleted"))
    : errorAlert.AddErrorAlert("複製失敗");

  document.body.removeChild(textArea);
}
</script>
<template>
  <div class="py-[2em] px-[9vw]">
    <div class="text-primary">
      <div
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[12px] md:mt-[3dvh]">
        <p class="mr-[1em] text-primary col-span-4">
          {{ $t("MemberFunction.Deposit.Order") }}
        </p>
        <div class="relative col-span-8 text-primary/70 break-words">
          {{ USDTFormInfo.sOrder }}
        </div>
      </div>
      <div
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[12px] md:mt-[3dvh]">
        <p class="mr-[1em] text-primary col-span-4">
          {{ $t("MemberFunction.Deposit.Protocol") }}
        </p>
        <div class="relative col-span-8 text-primary/70">
          {{ USDTFormInfo.sProtocol }}
        </div>
      </div>
      <div
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[12px] md:mt-[3dvh]">
        <p class="mr-[1em] text-primary col-span-4">
          {{ $t("MemberFunction.Deposit.DepositValue") }}
        </p>
        <div class="relative col-span-8 text-primary/70">
          ${{ store.addCommas(parseInt(USDTFormInfo.nMoney).toString()) }}
        </div>
      </div>
      <div
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[12px] md:mt-[3dvh]">
        <p class="mr-[1em] text-primary col-span-4">
          {{ $t("MemberFunction.Deposit.PaymentValue") }}
        </p>
        <div class="relative col-span-8 flex">
          <p class="text-primary/70">
            {{
              store.getClearPot(
                parseFloat(USDTFormInfo.nMoney) /
                  USDTFormInfo.nUsdtExchangeRate,
                3
              )
            }}
            <span class="text-red-600">{{
              $t("MemberFunction.Deposit.USDT")
            }}</span>
          </p>
          <button
            type="button"
            class="ml-2"
            @click="
              copyUrl(
                `${store.getClearPot(
                  parseFloat(USDTFormInfo.nMoney) /
                    USDTFormInfo.nUsdtExchangeRate,
                  3
                )} USDT`
              )
            ">
            <img v-img-src="USDT_copy" alt="" class="h-[16px] mx-auto inline" />
            <span class="text-[14px] ml-1 text-yellow-200">{{
              $t("MemberFunction.Deposit.Copy")
            }}</span>
          </button>
        </div>
      </div>
      <div
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[12px] md:mt-[3dvh]">
        <p class="mr-[1em] text-primary col-span-4">
          {{ $t("MemberFunction.Deposit.WalletAddress") }}
        </p>
        <div class="relative col-span-8 break-words">
          <span class="text-primary/70">{{ USDTFormInfo.sAddress }} </span>
          <button
            type="button"
            class="ml-2"
            @click="copyUrl(USDTFormInfo.sAddress)">
            <img v-img-src="USDT_copy" alt="" class="h-[16px] mx-auto inline" />
            <span class="text-[14px] ml-1 text-yellow-200">{{
              $t("MemberFunction.Deposit.Copy")
            }}</span>
          </button>
        </div>
      </div>
      <div
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[12px] md:mt-[3dvh]">
        <p class="mr-[1em] text-primary col-span-4">
          {{ $t("MemberFunction.Deposit.TransferCutOff") }}
        </p>
        <div class="relative col-span-8 text-primary/70">
          {{ USDTFormInfo.sLimitTime }}
        </div>
      </div>
    </div>

    <div class="mt-[3dvh] md:static flex flex-col justify-center items-center">
      <div class="mt-[3dvh]">
        <div class="flex justify-center items-center leading-[36px]">
          <img v-img-src="驚嘆號icon" alt="" class="w-[16px] mr-1" />
          <span class="text-red-600">
            {{ $t("MemberFunction.Deposit.Notice") }}
          </span>
        </div>
        <div class="text-primary text-[14px] md:text-[16px] leading-[20px]">
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>
              {{ $t("MemberFunction.Deposit.CurrentExchangeRate") }} 1USDT=
              <span class="text-red-600">{{
                USDTFormInfo.nUsdtExchangeRate
              }}</span
              >TWD。
            </p>
          </div>
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>{{ $t("MemberFunction.Deposit.SubmitUSDTNoticeList1") }}</p>
          </div>
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>{{ $t("MemberFunction.Deposit.SubmitUSDTNoticeList2") }}</p>
          </div>
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>
              {{ $t("MemberFunction.Deposit.SubmitUSDTNoticeList3") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
