<script setup lang="ts">
import { useCounterStore } from "@/stores/counter";
import { useLogin } from "@/stores/login";
import search_fail from "@image/memberFunction/common/search_fail.png";
import btn_bb from "@image/memberFunction/common/btn_bb.png";

const store = useCounterStore();
const login = useLogin();

function cancel() {
  store.hiddenAllAlert();
  store.hiddenAlertBg();
}

function submit() {
  store.hiddenAllAlert();
  store.hiddenAlertBg();
  login.logout();
}
</script>

<template>
  <div
    class="absolute md:sticky top-[40%] -translate-y-1/2 left-1/2 -translate-x-1/2 md:translate-x-0 w-[95vw] lg:w-auto">
    <div
      class="text-primary flex flex-col items-center justify-center relative">
      <img class="w-[300px]" v-img-src="search_fail" alt="" />
      <div class="absolute">
        <h3 class="text-center text-[20px] xl:text-[24px] font-bold">
          {{ $t("MemberFunction.LogoutAlert.Title") }}
        </h3>
        <div class="flex items-center mb-[12px] mt-[24px] gap-x-9">
          <button
            @click="submit"
            class="relative flex justify-center items-center font-bold">
            <img class="w-[80px]" v-img-src="btn_bb" alt="" />
            <div class="absolute">
              {{ $t("MemberFunction.LogoutAlert.Submit") }}
            </div>
          </button>
          <button
            @click="cancel"
            class="relative flex justify-center items-center font-bold border-[#bda669]">
            <img class="w-[80px]" v-img-src="btn_bb" alt="" />
            <div class="absolute">
              {{ $t("MemberFunction.LogoutAlert.Cancel") }}
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
</style>
