<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { storeToRefs } from "pinia";
import { useMemberService } from "@/stores/memberFunction/memberService";
const memberService = useMemberService();

const { DepositUSDTList } = storeToRefs(memberService);

const emit = defineEmits(["changeDepositPage"]);
function changeDepositPage(page: number) {
  emit("changeDepositPage", page);
}
</script>

<template>
  <div
    class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[16px] md:mt-[3dvh] w-full">
    <p class="mr-[1em] col-span-5">
      {{ $t("MemberFunction.Deposit.BindUSDTWallet") }}
    </p>
  </div>

  <div class="grid grid-cols-1 gap-[12px] w-full mt-[2dvh]">
    <template v-for="storeIndex in 1" :key="'DepositUSDTList' + storeIndex">
      <button
        type="button"
        class="select-store-border min-h-[100px] px-[12px] text-[14px] flex justify-start items-center"
        v-if="DepositUSDTList[storeIndex - 1]">
        <p class="text-start">
          {{ $t("MemberFunction.Deposit.WalletCategory") }}:
          {{ DepositUSDTList[storeIndex - 1].sProtocol }}<br />
          {{ $t("MemberFunction.Deposit.WalletAddress") }}:
          {{ DepositUSDTList[storeIndex - 1].sUsdtAddr }}<br />
          {{ $t("MemberFunction.Deposit.VerifyState") }}:
          {{
            DepositUSDTList[storeIndex - 1].nStatus === "0"
              ? $t("MemberFunction.Deposit.Pending")
              : $t("MemberFunction.Deposit.Passed")
          }}<br />
        </p>
      </button>
      <button
        type="button"
        class="add-store-border min-h-[100px] py-[6px] text-[24px] font-bold"
        @click="changeDepositPage(97)"
        v-else>
        +
      </button>
    </template>
  </div>
</template>
<style>
.add-store-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/11/pankcard01_bg.png") 1;
  -webkit-border-image: url("@image/memberFunction/11/pankcard01_bg.png") 1;
  -o-border-image: url("@image/memberFunction/11/pankcard01_bg.png") 1;
  border-image: url("@image/memberFunction/11/pankcard01_bg.png") 1 fill;
}
.select-store-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/12/verify_box.png") 1;
  -webkit-border-image: url("@image/memberFunction/12/verify_box.png") 1;
  -o-border-image: url("@image/memberFunction/12/verify_box.png") 1;
  border-image: url("@image/memberFunction/12/verify_box.png") 1 fill;
}
</style>
