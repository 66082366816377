import { ref, computed } from "vue";
// import { useI18n } from "vue-i18n";
import { i18nGlobal } from "@/i18n";
import { useRouter } from "vue-router";
import { defineStore } from "pinia";
import { localize, setLocale } from "@vee-validate/i18n";

import TwImage from "@image/memberFunction/01/laguge_tw_01.png";
import ChImage from "@image/memberFunction/01/lanbnt_Ch.png";
import JpImage from "@image/memberFunction/01/lanbnt_Jp.png";
import EnImage from "@image/memberFunction/01/lanbnt_En.png";
import ViImage from "@image/memberFunction/01/lanbnt_Vn.png";

interface LanguageLists {
  title: string;
  isSelect: boolean;
  type: "zh-TW" | "zh-CH" | "en-US" | "vi-VN";
  image: string;
}

export const useLanguagesStore = defineStore("languages", () => {
  const LanguageType = computed(() => {
    let type;
    switch (i18nGlobal.locale) {
      case "zh-TW":
        type = "tw";
        break;
      case "zh-CH":
        type = "cn";
        break;
      case "my-MM":
        type = "vn";
        break;
      case "en-US":
        type = "en";
        break;
      case "vi-VN":
        type = "vn";
        break;
      default:
        type = "tw";
        break;
    }
    return type;
  });
  const languageLists = ref<LanguageLists[]>([
    {
      title: "繁中",
      isSelect: true,
      type: "zh-TW",
      image: TwImage,
    },
    {
      title: "簡中",
      isSelect: false,
      type: "zh-CH",
      image: ChImage,
    },
    {
      title: "英文",
      isSelect: false,
      type: "en-US",
      image: EnImage,
    },
    {
      title: "越南文",
      isSelect: false,
      type: "vi-VN",
      image: ViImage,
    },
    // {
    //   title: "日文",
    //   isSelect: false,
    //   type: "ja-JP",
    //   image: JpImage
    // },
    // {
    //   title: "緬甸文",
    //   isSelect: false,
    //   type: "my-MM",
    //   image: ChImage,
    // }
  ]);
  const nowLanguage = computed(() => {
    return languageLists.value.find((item) => {
      return item.isSelect;
    });
  });
  function selectLanguage(type: string) {
    languageLists.value.forEach((item) => {
      item.isSelect = false;
      if (item.type === type) {
        item.isSelect = true;
      }
    });
  }

  const isShowLanguageLists = ref<boolean>(false);
  function toggleLanguageLists() {
    isShowLanguageLists.value = !isShowLanguageLists.value;
  }
  function openLanguageLists() {
    isShowLanguageLists.value = true;
  }
  function closeLanguageLists() {
    isShowLanguageLists.value = false;
  }

  // type: { zh-TW, eu-US }
  function SettingLanguage(type: "zh-TW" | "zh-CH" | "en-US" | "vi-VN") {
    localStorage.setItem("Language", type);
    selectLanguage(type);
    i18nGlobal.locale = type;
    closeLanguageLists();
    setLocale(type);
  }
  function ChangeLanguage(type: "zh-TW" | "zh-CH" | "en-US" | "vi-VN") {
    SettingLanguage(type);
    location.reload();
  }
  function InitLanguage() {
    const type = localStorage.getItem("Language");
    if (
      type === "zh-TW" ||
      type === "zh-CH" ||
      type === "en-US" ||
      type === "vi-VN"
    ) {
      SettingLanguage(type);
    } else {
      SettingLanguage("zh-TW");
    }
  }

  return {
    LanguageType,
    ChangeLanguage,
    languageLists,
    toggleLanguageLists,
    isShowLanguageLists,
    nowLanguage,
    InitLanguage,
  };
});
