<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

import { useCounterStore } from "@/stores/counter";
import { useLogin } from "@/stores/login";
import { useMemberService } from "@/stores/memberFunction/memberService";

import VipLevel from "@/components/menberFunction/VIPPrivilege/VipLevel.vue";
import HeaderPhotoNavbar from "@/components/header/headerPhotoNavbar.vue";

import VIPFlagImage1 from "@image/memberFunction/common/VIP1.png";
import VIPFlagImage2 from "@image/memberFunction/common/VIP2.png";
import VIPFlagImage3 from "@image/memberFunction/common/VIP3.png";
import VIPFlagImage4 from "@image/memberFunction/common/VIP4.png";
import VIPFlagImage5 from "@image/memberFunction/common/VIP5.png";

import image1 from "@image/memberFunction/05/my_app_deposit.png";
import image2 from "@image/memberFunction/05/my_app_pick.png";
import my_top_id_icon from "@image/memberFunction/05/my_top_id_icon.png";
import 認證藍勾 from "@image/memberFunction/06/認證藍勾.png";
import my_top_overage_icon from "@image/memberFunction/05/my_top_overage_icon.png";

import member_pic_01 from "@image/memberFunction/main/member_pic_01.png";

const router = useRouter();
const { t } = useI18n();

const login = useLogin();
const store = useCounterStore();
const memberService = useMemberService();
const { isHeaderPhotoNavbar } = storeToRefs(store);
const { memberData, isZeroVipLevel, isCashMember } = storeToRefs(login);
const { MailCount } = storeToRefs(memberService);

const VipData = computed(() => {
  if (JSON.stringify(memberData.value) === "{}") return { nVipLv: false };
  return memberData.value.aVipData;
});

function toPath(path: string) {
  router.push({ name: path });
}

interface HeaderInfoLists {
  title: string;
  path: string;
}
const headerInfoLists = ref<HeaderInfoLists[]>([
  {
    title: t("Header.Deposit"),
    path: "DepositPage",
  },
  {
    title: t("Header.Withdraw"),
    path: "WithdrawPage",
  },
]);
const imageFile = (index: number) => {
  let getImage;
  switch (index) {
    case 0:
      getImage = image1;
      break;
    case 1:
      getImage = image2;
      break;
    default:
      getImage = "";
      break;
  }
  return getImage;
};

function openLogoutAlert() {
  store.toggleAlertBg();
  store.openLogoutAlert();
}

function toggleHeaderPhotoNavbar() {
  store.toggleHeaderPhotoNavbar();
}
</script>

<template>
  <div class="w-[80vw] md:w-auto">
    <div class="flex justify-between items-center w-full">
      <div class="flex" :class="isCashMember ? 'mt-0' : 'mt-[2dvh]'">
        <button
          type="button"
          class="relative block md:hidden"
          @click="toPath('MemberAccount')">
          <img v-img-src="member_pic_01" alt="" class="w-[40px] mr-[1em]" />
          <span
            class="absolute right-0 -translate-x-1/2 top-0 flex justify-center items-center rounded-full h-[20px] w-[20px] bg-red-500"
            v-if="parseInt(MailCount) > 0">
            <p class="text-primary">{{ MailCount }}</p>
          </span>
        </button>
        <button
          type="button"
          class="relative hidden md:block"
          @click.stop="toggleHeaderPhotoNavbar">
          <img v-img-src="member_pic_01" alt="" class="w-[40px] mr-[1em]" />
          <span
            class="absolute right-0 -translate-x-1/2 top-0 flex justify-center items-center rounded-full h-[20px] w-[20px] bg-red-500"
            v-if="parseInt(MailCount) > 0">
            <p class="text-primary">{{ MailCount }}</p>
          </span>
          <HeaderPhotoNavbar
            class="absolute top-[70px]"
            v-if="isHeaderPhotoNavbar" />
        </button>

        <div class="flex flex-col justify-center mr-[1em] text-primary">
          <div class="mb-[5px]">
            <img
              v-img-src="my_top_id_icon"
              alt=""
              class="inline w-[14px] mr-[2px] mb-[2px]" />
            <span class="text-primary text-[14px]"
              >{{ $t("Header.Account") }}: {{ memberData.sAccount
              }}{{ login.isCashMember ? "" : " (信用)" }}</span
            >
            <img
              v-img-src="認證藍勾"
              alt=""
              class="inline w-[14px] ml-[2px] mb-[2px]"
              v-if="memberData.nRank !== '0'" />
          </div>
          <div class="flex items-center mb-[2px]">
            <img
              v-img-src="my_top_overage_icon"
              alt=""
              class="inline w-[14px] mr-[2px]" />
            <span class="text-primary text-[14px]"
              >{{ $t("Header.Balance") }}:</span
            >
            <span class="text-coin text-[18px] ml-1">
              {{ store.getClearPotAndAddCommas(memberData.nMoney).toString() }}
            </span>
          </div>
        </div>
        <ul class="hidden md:flex items-center" v-if="isCashMember">
          <li
            class="mx-[1vw]"
            v-for="(item, index) in headerInfoLists"
            :key="item.title + 'headerInfo'">
            <button
              class="flex flex-col items-center justify-between text-primary hover:opacity-50"
              @click="toPath(item.path)"
              v-if="
                login.memberData.nRank == '0' &&
                item.title !== `${$t('Header.Transfer')}`
              ">
              <img
                v-img-src="imageFile(index)"
                alt=""
                class="w-[30px] h-[30px] object-contain" />
              <p class="text-[12px] text-center">{{ item.title }}</p>
            </button>
            <button
              class="flex flex-col items-center justify-between text-primary hover:opacity-50"
              @click="toPath(item.path)"
              v-else-if="login.memberData.nRank !== '0'">
              <img
                v-img-src="imageFile(index)"
                alt=""
                class="w-[30px] h-[30px] object-contain" />
              <p class="text-[12px] text-center">{{ item.title }}</p>
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="mt-3 md:hidden" v-if="isCashMember">
      <VipLevel v-if="!isZeroVipLevel" />
    </div>
    <!-- </div> -->
  </div>
</template>
