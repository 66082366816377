<script setup lang="ts">
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useLogin } from "@/stores/login";
import { useCounterStore } from "@/stores/counter";
import { useMemberService } from "@/stores/memberFunction/memberService";
import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";
import VerifyState from "@/components/menberFunction/memberAccount/memberAccountVerifyState.vue";

import 筆_icon from "@image/memberFunction/10/筆_icon.png";
import good_icon from "@image/memberFunction/10/good_icon.png";
import nogood_icon from "@image/memberFunction/10/nogood_icon.png";

const router = useRouter();
const login = useLogin();
const store = useCounterStore();
const memberService = useMemberService();
const { changepwdMsg } = storeToRefs(memberService);
const { isWithdrawPageAlert } = storeToRefs(store);
const { memberData } = storeToRefs(login);

// 修改登入密碼
function openChangePasswordAlert() {
  store.toggleAlertBg();
  store.openChangePasswordAlert();
  changepwdMsg.value = "";
}

// 修改交易密碼
function openChangeTradePasswordAlert() {
  store.toggleAlertBg();
  store.openChangeTradePasswordAlert();
  changepwdMsg.value = "";
}

// 實名認證
function toBankVerified() {
  router.push({ name: "BankVerified" });
}

//#region Email
interface MailFormData {
  sEmail: string;
}
const MailForm = ref<null | any>(null);
const isEditMailForm = ref<boolean>(false);
function ToggleEditMailForm() {
  isEditMailForm.value = !isEditMailForm.value;
  MailForm.value.setFieldValue("sEmail", memberData.value.user_email);
}
const isFormValid = (formData: MailFormData) => {
  return Object.values(formData).every((value) => value !== undefined);
};
function MailSubmit() {
  const data: MailFormData = MailForm.value.getValues();
  if (!isFormValid(data)) return;

  memberService.MailUpdate(data);
  MailForm.value.resetForm();
  isEditMailForm.value = false;
}
//#endregion

//#region Line
interface LineFormData {
  sLineId: string;
}
const LineForm = ref<null | any>(null);
const isEditLineForm = ref<boolean>(false);
function ToggleEditLineForm() {
  isEditLineForm.value = !isEditLineForm.value;
  LineForm.value.setFieldValue("sLineId", memberData.value.sLineId);
}
const isLineFormValid = (formData: LineFormData) => {
  return Object.values(formData).every((value) => value !== undefined);
};
function LineSubmit() {
  const data: LineFormData = LineForm.value.getValues();
  if (!isLineFormValid(data)) return;

  memberService.LineUpdate(data);
  LineForm.value.resetForm();
  isEditLineForm.value = false;
}
//#endregion
</script>

<template>
  <div
    class="md:grid grid-cols-12 gap-x-[3vw] block gap-y-[1dvh] px-[1vw] pb-[10dvh] pt-0 md:pt-[5dvh]">
    <SidebarWeb class="col-span-3 hidden md:block mb-[7dvh]" />
    <div class="col-span-9">
      <div class="px-0 py-0 md:px-[5vw] md:py-[2dvh] relative z-10">
        <p class="mt-[1dvh] text-[24px] text-primary font-bold hidden md:block">
          {{ $t("MemberFunction.Management.MemberManagement") }}
        </p>
        <div
          class="px-[1em] pt-[1em] pb-[10dvh] lg:pb-0"
          v-if="!isWithdrawPageAlert">
          <div
            class="z-20 relative member-bg-border w-full h-full py-[1em] px-[1em]">
            <div class="text-primary">
              <label
                for="account"
                class="grid grid-cols-8 text-[14px] lg:text-[20px] mt-[.5em] lg:mt-[2em]">
                <p class="col-span-2">
                  {{ $t("MemberFunction.Management.MemberAccount") }}
                </p>
                <p class="col-span-6">{{ memberData.sAccount }}</p>
              </label>
              <label
                for="name"
                class="grid grid-cols-8 text-[14px] lg:text-[20px] mt-[1.5rem]">
                <p class="col-span-2">
                  {{ $t("MemberFunction.Management.MemberName") }}
                </p>
                <div class="col-span-6 flex items-center">
                  <VerifyState :Info="memberData.sRealName" />
                </div>
              </label>
              <label
                for="sBirthday"
                class="grid grid-cols-8 text-[14px] lg:text-[20px] mt-[1.5rem]">
                <p class="col-span-2">
                  {{ $t("MemberFunction.Management.Birthday") }}
                </p>
                <div class="col-span-6 flex items-center">
                  <VerifyState :Info="memberData.sBirthday" />
                </div>
              </label>
              <label
                for="sBirthday"
                class="grid grid-cols-8 text-[14px] lg:text-[20px] mt-[1.5rem]">
                <p class="col-span-2">
                  {{ $t("MemberFunction.Management.IdCardNumber") }}
                </p>
                <div class="col-span-6 flex items-center">
                  <VerifyState :Info="memberData.sIdCard" />
                </div>
              </label>
              <label
                for="coin"
                class="grid grid-cols-8 text-[14px] lg:text-[20px] mt-[1.5rem]">
                <p class="col-span-2">
                  {{ $t("MemberFunction.Management.WalletBalance") }}
                </p>
                <p class="col-span-6">
                  {{
                    store.getClearPotAndAddCommas(memberData.nMoney).toString()
                  }}
                </p>
              </label>
              <label
                :for="$t('MemberFunction.Management.PhoneNumber')"
                class="grid grid-cols-8 text-[14px] lg:text-[20px] mt-[1.5rem]">
                <p class="col-span-2">
                  {{ $t("MemberFunction.Management.PhoneNumber") }}
                </p>
                <p class="col-span-6">{{ memberData.user_phone }}</p>
              </label>
              <VForm ref="MailForm">
                <label
                  :for="$t('MemberFunction.Management.Email')"
                  class="grid grid-cols-8 gap-[12px] text-[14px] lg:text-[20px] mt-[1.5rem]">
                  <p class="col-span-2">
                    {{ $t("MemberFunction.Management.Email") }}
                  </p>
                  <div class="col-span-6 flex items-center">
                    <VField
                      class="bg-[url('@image/memberFunction/文字框/文字框_長.png')] bg-100 rounded-md px-[10px] placeholder:text-[14px]"
                      :label="$t('MemberFunction.Management.Email')"
                      name="sEmail"
                      type="sEmail"
                      :value="memberData.user_email"
                      :rules="{
                        required: true,
                      }"
                      :placeholder="
                        $t('MemberFunction.Management.EmailPlaceholder')
                      "
                      :class="{
                        hidden: !isEditMailForm,
                      }" />
                    <p
                      :class="{
                        hidden: isEditMailForm,
                        'text-primary': memberData.user_email,
                        'text-primary/50': !memberData.user_email,
                      }">
                      {{
                        memberData.user_email
                          ? memberData.user_email
                          : `*${$t("MemberFunction.Management.Email")}`
                      }}
                    </p>
                    <button
                      type="button"
                      class="w-[16px] h-[16px] ml-[2vw]"
                      @click="ToggleEditMailForm"
                      v-if="!isEditMailForm">
                      <img v-img-src="筆_icon" alt="" class="w-[16px] h-full" />
                    </button>
                    <div
                      class="flex justify-center items-center ml-[2vw]"
                      v-else>
                      <button
                        type="button"
                        class="w-[20px] h-[20px] mr-[3vw]"
                        @click="MailSubmit">
                        <img
                          v-img-src="good_icon"
                          alt=""
                          class="w-[20px] h-[20px]" />
                      </button>
                      <button
                        type="button"
                        class="w-[20px] h-[20px]"
                        @click="ToggleEditMailForm">
                        <img
                          v-img-src="nogood_icon"
                          alt=""
                          class="w-[20px] h-[20px]" />
                      </button>
                    </div>
                  </div>
                </label>
              </VForm>
              <VForm ref="LineForm">
                <label
                  for="Line"
                  class="grid grid-cols-8 gap-[12px] text-[14px] lg:text-[20px] mt-[1.5rem]">
                  <p class="col-span-2">
                    {{ $t("MemberFunction.Management.LineId") }}
                  </p>
                  <div class="col-span-6 flex items-center">
                    <VField
                      class="bg-[url('@image/memberFunction/文字框/文字框_長.png')] bg-100 rounded-md px-[10px] placeholder:text-[14px]"
                      label="Line"
                      name="sLineId"
                      type="sLineId"
                      :value="'memberData.user_line'"
                      :rules="{
                        required: true,
                      }"
                      :placeholder="
                        $t('MemberFunction.Management.LineIdPlaceholder')
                      "
                      :class="{
                        hidden: !isEditLineForm,
                      }" />
                    <p
                      :class="{
                        hidden: isEditLineForm,
                        'text-primary': memberData.sLineId,
                        'text-primary/50': !memberData.sLineId,
                      }">
                      {{ memberData.sLineId ? memberData.sLineId : "*LINE ID" }}
                    </p>
                    <button
                      type="button"
                      class="w-[16px] h-[16px] ml-[2vw]"
                      @click="ToggleEditLineForm"
                      v-if="!isEditLineForm">
                      <img v-img-src="筆_icon" alt="" class="w-[16px] h-full" />
                    </button>
                    <div
                      class="flex justify-center items-center ml-[2vw]"
                      v-else>
                      <button
                        type="button"
                        class="w-[20px] h-[20px] mr-[3vw]"
                        @click="LineSubmit">
                        <img
                          v-img-src="good_icon"
                          alt=""
                          class="w-[20px] h-[20px]" />
                      </button>
                      <button
                        type="button"
                        class="w-[20px] h-[20px]"
                        @click="ToggleEditLineForm">
                        <img
                          v-img-src="nogood_icon"
                          alt=""
                          class="w-[20px] h-[20px]" />
                      </button>
                    </div>
                  </div>
                </label>
              </VForm>

              <label
                for="phone"
                class="grid grid-cols-8 text-[14px] lg:text-[20px] mt-[1.5rem]">
                <p class="col-span-2">
                  {{ $t("MemberFunction.Management.LoginPassword") }}
                </p>
                <div class="col-span-6">
                  <button
                    type="button"
                    class="btn-y-border"
                    @click="openChangePasswordAlert">
                    <p class="text-[12px] text-secondary px-[1em] py-[.2em]">
                      {{ $t("MemberFunction.Management.ModifyNow") }}
                    </p>
                  </button>
                </div>
              </label>
              <label
                for="phone"
                class="grid grid-cols-8 text-[14px] lg:text-[20px] mt-[1.5rem]">
                <p class="col-span-2">
                  {{ $t("MemberFunction.Management.TradePassword") }}
                </p>
                <div class="col-span-6">
                  <button
                    type="button"
                    class="btn-y-border"
                    @click="openChangeTradePasswordAlert">
                    <p class="text-[12px] text-secondary px-[1em] py-[.2em]">
                      {{ $t("MemberFunction.Management.ModifyNow") }}
                    </p>
                  </button>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
</style>
