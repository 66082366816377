<script setup lang="ts">
import { getCurrentInstance } from "vue";
import type { ComponentInternalInstance } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";

import success_bg from "@image/memberFunction/11/success_bg.png";
import tick from "@image/memberFunction/11/tick.png";

const { appContext } = getCurrentInstance() as ComponentInternalInstance;
const router = useRouter();
const memberService = useMemberService();
const store = useCounterStore();
const { addMemberBankMsg } = storeToRefs(memberService);

function closeMailBoxAlert() {
  store.hiddenAllAlert();
  store.hiddenAlertBg();
}

function toDepositPage() {
  router.push({ name: "DepositPage" });
}
</script>
<template>
  <div class="fixed top-[20%] left-1/2 -translate-x-1/2 z-[999]">
    <div
      class="w-[280px] h-[350px] text-primary relative flex justify-center items-center">
      <img class="w-full h-full" v-img-src="success_bg" alt="" />
      <div
        class="h-full flex flex-col justify-center items-center absolute text-primary text-[18px] md:text-[20px]">
        <img class="w-[70px] mt-[12px]" v-img-src="tick" alt="" />
        <div class="flex flex-col justify-center items-center mt-[3rem]">
          <p>
            {{ addMemberBankMsg }}
          </p>
          <!-- <button
            type="button"
            class="mt-[3rem] btn-y-border px-[1.5em] md:px-[2em] py-[6px] md:py-[.5em]"
            @click="toDepositPage"
          >
            <p class="text-[12px] md:text-[14px] text-secondary">前往儲值</p>
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>
