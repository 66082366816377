<script setup lang="ts">
import { ref, onMounted, getCurrentInstance, watch } from "vue";
import type { ComponentInternalInstance } from "vue";
import { storeToRefs } from "pinia";
import { Swiper, SwiperSlide } from "swiper/vue";
import { FreeMode, Scrollbar, Mousewheel } from "swiper/modules";
import type { SwiperOptions, Swiper as SwiperClass } from "swiper/types";
import "swiper/css/scrollbar";
import "swiper/css/bundle";
import "swiper/css";
import { useCounterStore } from "@/stores/counter";
import { useLogin } from "@/stores/login";
import { useContentInfo } from "@/stores/contentInfo";
import { useLoadingPage } from "@/stores/loadingPage";
import { useMemberService } from "@/stores/memberFunction/memberService";

import ContentSwiperWeb from "@/components/content/contextSwiperWeb.vue";
import ContextMarqueeWeb from "@/components/content/contextMarqueeWeb.vue";
import ContentGamePlatformWeb from "@/components/content/contextGamePlatformWeb.vue";
import ContextDownloadAPPWeb from "@/components/content/contextDownloadAPPWeb.vue";
import ContentMemberSlideAPP from "@/components/content/contentMemberSlideAPP.vue";
import ContextServiceWeb from "@/components/content/contextServiceWeb.vue";

import ContentMemberInfoAPP from "@/components/content/contentMemberInfoAPP.vue";
import ContentGamePlatformListAPP from "@/components/content/contentGamePlatformListAPP.vue";
import ContentGamePlatformAPP from "@/components/content/contentGamePlatform/contentGamePlatformAPP.vue";
import ContentGamePlatformAPP2 from "@/components/content/contentGamePlatform/contentGamePlatformAPP2.vue";
import ContentGamePlatformAPP3 from "@/components/content/contentGamePlatform/contentGamePlatformAPP3.vue";

const { appContext } = getCurrentInstance() as ComponentInternalInstance;
const store = useCounterStore();
const login = useLogin();
const contentInfoStore = useContentInfo();
const loadingPage = useLoadingPage();
const memberService = useMemberService();
const { isLoadingPage } = storeToRefs(loadingPage);
const { slidesTipState, gameBoxType } = storeToRefs(contentInfoStore);
const { NewJumpMes } = storeToRefs(memberService);
const modules = [FreeMode, Scrollbar, Mousewheel];

const gamePlatformSwiper = ref<SwiperClass | null>(null);
function slideToGamePlatformSwiper(index: number) {
  if (!gamePlatformSwiper.value) return;
  gamePlatformSwiper.value.slideTo(index, 500);
}

function onSwiper(swiper: SwiperClass) {
  gamePlatformSwiper.value = swiper;
}

function handleSlideChange(swiper: SwiperClass) {
  const index = swiper.activeIndex;

  keepSlidesIndex(index);

  if (
    Object.values(contentInfoStore.gamePlatformList[index]).length <= 6 &&
    gameBoxType.value !== "3"
  ) {
    slidesTipState.value = "none";
  }
  if (
    Object.values(contentInfoStore.gamePlatformList[index]).length <= 3 &&
    gameBoxType.value === "3"
  ) {
    slidesTipState.value = "none";
  }

  appContext.config.globalProperties.$mitt.emit(
    "open-game-platform-btn",
    index
  );
}

function openNewsAlert() {
  if (NewJumpMes.value.length === 0) return;
  if (store.TodayNewsCookies() === "2") return;
  store.openNewsAlert();
  store.toggleAlertBg();
}

onMounted(async () => {
  // 若上一頁, 重整
  window.onpageshow = async function (event) {
    if (event.persisted) {
      // window.location.reload();
      loadingPage.openLoadingPage();
      await contentInfoStore.CheckLogin();
      if (login.isLoginAlert) {
        setTimeout(() => {
          login.closeLoginAlert();
        }, 1000);
      }
      // setTimeout(() => {
      loadingPage.closeLoadingPage();
      // }, 300);
    }
  };
  loadingPage.openLoadingPage();
  await memberService.getNewMessageData();
  openNewsAlert();
  await contentInfoStore.CheckLogin();
  if (login.isLoginAlert) {
    // setTimeout(() => {
    login.closeLoginAlert();
    // }, 1000);
  }
  slidesTipState.value = "none";
  // setTimeout(() => {
  loadingPage.closeLoadingPage();
  // }, 1000);
});

// 向左向右滑動
const slidesIndex = ref<number>(0);
function keepSlidesIndex(num: number) {
  slidesIndex.value = num;
}
</script>

<template>
  <!-- Web -->
  <div
    class="bg-[url('@image/memberFunction/02_web/bg.png')] bg-cover hidden md:block">
    <div
      class="bg-[url('@image/memberFunction/common/RP_rain.gif')] bg-cover h-[100dvh] w-[100vw] fixed z-[200] pointer-events-none"></div>
    <ContentSwiperWeb />
    <div class="max-w-[1366px] px-[1vw] mx-auto">
      <ContextMarqueeWeb class="mt-[3vh]" />
      <ContextDownloadAPPWeb />
      <ContextServiceWeb />
      <ContentGamePlatformWeb />
    </div>
  </div>

  <!-- APP 因為有header h-[6vh] 當層 h-[94vh]-->
  <div
    class="flex flex-col justify-center items-center bg-center h-[calc(94dvh)] md:hidden">
    <div
      class="bg-[url('@image/memberFunction/common/RP_rain.gif')] bg-cover bg-center h-[100dvh] w-[100vw] fixed z-[9999] left-0 pointer-events-none"></div>
    <ContentSwiperWeb />
    <ContentMemberInfoAPP />
    <div
      class="grid grid-cols-12 overflow-hidden h-[calc(100%-55px)] md:max-h-[calc(100%-100px)]">
      <ContentGamePlatformListAPP
        class="col-span-2"
        @slideToGamePlatformSwiper="slideToGamePlatformSwiper" />
      <!-- 向左, 向右滑動 -->
      <ContentMemberSlideAPP />
      <div
        class="col-span-10 overflow-y-hidden max-h-[calc(100%)] mx-[5px] gamePlatform-scrollbar">
        <Swiper
          :space-between="60"
          :sticky="true"
          :modules="modules"
          @swiper="onSwiper"
          @slideChange="handleSlideChange"
          ref="gamePlatformSwiper"
          class="h-full"
          direction="vertical">
          <!-- for 館別 -->
          <SwiperSlide
            v-for="(value, index) in contentInfoStore.gamePlatformList"
            :key="'ContentGamePlatformAPPSwiper' + value"
            class="h-auto">
            <ContentGamePlatformAPP2
              :Lists="Object.values(value)"
              :Index="index"
              :SlidesIndex="slidesIndex"
              v-if="gameBoxType === '2'" />
            <ContentGamePlatformAPP3
              :Lists="Object.values(value)"
              :Index="index"
              :SlidesIndex="slidesIndex"
              v-else-if="gameBoxType === '3'" />
            <ContentGamePlatformAPP
              :Lists="Object.values(value)"
              :Index="index"
              :SlidesIndex="slidesIndex"
              v-else />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </div>
</template>
