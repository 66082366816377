<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

import { useLogin } from "@/stores/login";
import { useCounterStore } from "@/stores/counter";

import HeaderInfoWeb from "@/components/header/headerLogin/headerInfoWeb.vue";
import memberAccountWeb from "@/components/menberFunction/memberAccount/memberAccountWeb.vue";
import MemberAccountSwiper from "@/components/menberFunction/memberAccount/memberAccountSwiper.vue";

import topBtnsImg1 from "@image/memberFunction/05/my_app_deposit.png";
import topBtnsImg2 from "@image/memberFunction/05/my_app_pick.png";
import topBtnsImg4 from "@image/memberFunction/05/my_app_QR.png";
import topBtnsImg5 from "@image/memberFunction/05/my_app_card.png";

import my_top_bg from "@image/memberFunction/05/my_top_bg.png";

const { t } = useI18n();

const router = useRouter();
const store = useCounterStore();
const login = useLogin();
const { isCashMember } = storeToRefs(login);

const topBtns = ref<string[]>([
  t("MemberFunction.MemberAccount.Deposit"),
  t("MemberFunction.MemberAccount.Withdraw"),
  t("MemberFunction.MemberAccount.BankCard"),
  t("MemberFunction.MemberAccount.Promote"),
]);

const getTopBtnsImg = (title: string) => {
  let image;
  switch (title) {
    case t("MemberFunction.MemberAccount.Deposit"):
      image = topBtnsImg1;
      break;
    case t("MemberFunction.MemberAccount.Withdraw"):
      image = topBtnsImg2;
      break;
    case t("MemberFunction.MemberAccount.Promote"):
      image = topBtnsImg4;
      break;
    case t("MemberFunction.MemberAccount.BankCard"):
      image = topBtnsImg5;
      break;
    default:
      break;
  }
  return image;
};
function toPath(title: string) {
  switch (title) {
    case t("MemberFunction.MemberAccount.Deposit"):
      router.push({ name: "DepositPage" });
      break;
    case t("MemberFunction.MemberAccount.Withdraw"):
      router.push({ name: "WithdrawPage" });
      break;
    case t("MemberFunction.MemberAccount.BankCard"):
      router.push({ name: "MemberBankPage" });
      break;
    case t("MemberFunction.MemberAccount.Promote"):
      router.push({ name: "PromoteLink" });
      break;
    default:
      console.log("default");
      break;
  }
}

function openLogoutAlert() {
  store.toggleAlertBg();
  store.openLogoutAlert();
}

// function reSizeEvent() {
//   const isAppSize = window.innerWidth > 768;
//   if (isAppSize) {
//     router.push({ name: "main" });
//   }
// }
onMounted(() => {
  // window.addEventListener("resize", reSizeEvent);
});
onUnmounted(() => {
  // window.removeEventListener("resize", reSizeEvent);
});
</script>

<template>
  <div class="overflow-none">
    <memberAccountWeb class="hidden md:grid" />
    <div
      class="relative min-h-[calc(100dvh+82px)] overflow-none block md:hidden">
      <img
        v-img-src="my_top_bg"
        alt=""
        class="absolute top-[-5dvh] w-full"
        :class="isCashMember ? 'h-[calc(26dvh)]' : ' h-[calc(20dvh)]'" />
      <HeaderInfoWeb
        class="absolute left-1/2 -translate-x-1/2 top-[1vh] z-20" />
      <div
        class="absolute left-1/2 -translate-x-1/2 z-20 w-[80%] md:w-[70%] bg-[url('@image/memberFunction/05/my_mid_bg.png')] bg-100"
        :class="isCashMember ? 'top-[16%]' : 'top-[16%]'">
        <ul
          v-if="isCashMember"
          class="grid z-20 w-full py-[12px] px-[12px]"
          :class="{
            'grid-cols-3': login.memberData.nRank == '0',
            'grid-cols-4': login.memberData.nRank !== '0',
          }">
          <li
            class="flex flex-col justify-center items-center px-[1vw]"
            v-for="item in topBtns"
            :key="item + '-topBtn'">
            <button
              class="flex flex-col items-center"
              @click="toPath(item)"
              v-if="
                login.memberData.nRank == '0' &&
                item !== $t('MemberFunction.MemberAccount.Promote')
              ">
              <img
                v-img-src="getTopBtnsImg(item)"
                alt=""
                class="w-[30px] h-[30px] object-contain" />
              <p
                class="text-primary text-[14px] font-bold md:text-[16px] w-[110%]">
                {{ item }}
              </p>
            </button>
            <button
              class="flex flex-col items-center"
              @click="toPath(item)"
              v-else-if="login.memberData.nRank !== '0'">
              <img
                v-img-src="getTopBtnsImg(item)"
                alt=""
                class="w-[30px] h-[30px] object-contain" />
              <p class="text-primary text-[14px] font-bold md:text-[16px]">
                {{ item }}
              </p>
            </button>
          </li>
        </ul>
        <div class="absolute w-[90vw] md:w-[70vw] left-1/2 -translate-x-1/2">
          <MemberAccountSwiper />
          <button
            @click="openLogoutAlert"
            class="block md:hidden btn-logout-border w-[90%] relative left-1/2 -translate-x-1/2">
            <p class="text-main text-[18px] text-center py-[3px]">
              {{ $t("Header.Logout") }}
            </p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.btn-logout-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/05/my_login_btn.png") 1;
  -webkit-border-image: url("@image/memberFunction/05/my_login_btn.png") 1;
  -o-border-image: url("@image/memberFunction/05/my_login_btn.png") 1;
  border-image: url("@image/memberFunction/05/my_login_btn.png") 1 fill;
}
</style>
