<script setup lang="ts">
import { ref, watchEffect, computed } from "vue";
import * as APItypes from "@/assets/API/APItype";

import { useContentInfo } from "@/stores/contentInfo";

import section_casino_bg from "@image/memberFunction/02_web/section_casino_bg.png";
import section_btn from "@image/memberFunction/02_web/section_btn.png";

interface PageLists {
  list: APItypes.IndexInfoGameDataListInfo;
  isSelect: boolean;
}
const contentInfoStore = useContentInfo();

const props = defineProps({
  GamePlatformType: String,
  GameKind: Object as () => APItypes.AGameKind,
});
const pageLists = ref<PageLists[]>([]);

watchEffect(() => {
  if (!props.GamePlatformType) return [];
  const lists = contentInfoStore.getContentListInfo(props.GamePlatformType);
  // const listAry = Object.values(lists);
  // console.log(lists, "lists");
  pageLists.value = lists.map((item, index) => {
    return {
      list: Object.values(item)[0],
      isSelect: index === 0,
    };
  });
});

function selectBtn(
  index: number,
  itemInfo: APItypes.IndexInfoGameDataListInfo
) {
  contentInfoStore.goUrl(itemInfo);
  pageLists.value.forEach((item, itemIndex) => {
    item.isSelect = false;
    if (itemIndex === index) {
      item.isSelect = true;
    }
  });
}

const showImage = computed(() => {
  let isSelectList = pageLists.value.find((item) => {
    return item.isSelect;
  });
  if (!isSelectList) return "";
  return isSelectList.list.sImgUrl;
});
</script>

<template>
  <div class="h-full w-full mt-[12px] bg-100 py-[10vh]">
    <div class="grid grid-cols-2">
      <div class="relative py-[1vh]">
        <img
          v-img-src="section_casino_bg"
          alt=""
          class="object-contain scale-y-[125%] lg:scale-y-110" />
        <div
          class="absolute top-[55%] xl:top-[55%] left-1/2 -translate-y-1/2 -translate-x-1/2 px-[2vw] w-[95%]">
          <div class="w-full">
            <img
              :src="props.GameKind?.sImgIconPcContent"
              alt=""
              class="max-w-[60%]" />
          </div>
          <p
            class="text-tertiary text-[16px] xl:text-[20px] mt-[1vh] tracking-wide">
            {{ props.GameKind?.sContent0 }}
          </p>
          <div class="mt-[1vh] md:mt-[3vh]">
            <ul class="grid grid-cols-5 gap-x-[2vw] gap-y-[.5vh]">
              <li
                v-for="(item, index) in pageLists"
                :key="'PlatformPage' + item.list.nId">
                <button
                  class="group relative"
                  :class="{ grayscale: item.list.nActive === '0' }"
                  @click="selectBtn(index, item.list)">
                  <div class="">
                    <img v-img-src="section_btn" alt="" />
                    <div
                      class="flex flex-col justify-center items-center absolute top-[43%] -translate-y-1/2 left-1/2 -translate-x-1/2 w-full">
                      <img
                        :src="item.list.sImgIcon"
                        alt=""
                        class="object-fill max-w-[60%]" />
                      <p class="text-[12px] font-bold text-secondary">
                        {{ item.list.sTitle0 }}
                      </p>
                    </div>
                    <p
                      class="text-primary text-[12px] font-bold absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
                      v-if="item.list.nActive === '0'">
                      {{ $t("Context.InMaintenance") }}
                    </p>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="flex justify-center items-center relative">
        <transition name="fade" mode="out-in">
          <img
            :key="showImage"
            :src="showImage"
            alt=""
            class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-[80%]" />
        </transition>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
