<script setup lang="ts">
import { ref, computed, watchEffect } from "vue";
import { storeToRefs } from "pinia";
import { useContentInfo } from "@/stores/contentInfo";
import { useCounterStore } from "@/stores/counter";
import * as APItypes from "@/assets/API/APItype";

import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css/scrollbar";
import "swiper/css/bundle";
import "swiper/css";

import stamp from "@image/memberFunction/common/stamp.png";

import playFormLayoutImg from "@image/memberFunction/01/game_company_m.png"; // 單數第一張
import playFormLayoutImg2 from "@image/memberFunction/01/game_company_s.png"; // 雙數
import playFormLayoutImg3 from "@image/memberFunction/01/game_company_bb.png"; // 只有一張
import playFormLayoutImg4 from "@image/memberFunction/01/game_company_sl.png"; // 只有四張
import playFormLayoutImg6 from "@image/memberFunction/01/game_company_b.png"; // 只有兩張

const contentInfoStore = useContentInfo();
const store = useCounterStore();
const { slidesTipState } = storeToRefs(contentInfoStore);
const { UserAgentWebview } = storeToRefs(store);

const props = defineProps({
  Lists: Array as () => APItypes.IndexInfoGameDataListInfo[],
  Index: Number,
  SlidesIndex: Number,
});
const emit = defineEmits(["checkSlideTip", "changeSlide"]);

const SwiperList = computed(() => {
  if (!props.Lists) return [];
  let GetArr = [];
  for (let i = 0; i < props.Lists.length; i += 6) {
    GetArr.push(props.Lists.slice(i, i + 6));
  }
  return GetArr;
});

const getBgImage = (AllCount: number, index: number) => {
  let isOdd = AllCount % 2 === 1;
  let isTwoMore = false;
  let image;
  switch (
    AllCount // 先判斷是否只有一張或兩張
  ) {
    case 1:
      image = playFormLayoutImg3;
      break;
    case 2:
      image = playFormLayoutImg6;
      break;
    case 3:
      image = playFormLayoutImg;
      break;
    case 4:
      image = playFormLayoutImg4;
      break;
    default:
      isTwoMore = true;
      break;
  }
  if (AllCount === 4) return playFormLayoutImg4;
  if (!isTwoMore) return image;
  if (isOdd) {
    // 大於兩張的話判斷
    switch (
      index // 單數
    ) {
      case 0:
        image = playFormLayoutImg;
        break;
      default:
        image = playFormLayoutImg2;
        break;
    }
  } else {
    // 雙數
    image = playFormLayoutImg2;
  }
  return image;
};
const getCol = (AllCount: number, index: number, nActive: string) => {
  let isOdd = AllCount % 2 === 1;
  let isTwoMore = false;
  let isActive = nActive === "0" ? "grayscale" : "";
  let col;
  switch (
    AllCount // 先判斷是否只有一張或兩張
  ) {
    case 1:
      col = 2;
      break;
    case 2:
      col = 2;
      break;
    case 3:
      col = 2;
      break;
    default:
      isTwoMore = true;
      break;
  }
  if (AllCount === 4) return `col-span-1 ${isActive}`;
  if (!isTwoMore) return `col-span-${col} ${isActive}`;
  if (isOdd) {
    // 大於兩張的話判斷
    switch (
      index // 單數
    ) {
      case 0:
        col = 2;
        break;
      default:
        col = 1;
        break;
    }
  } else {
    // 雙數
    col = 1;
  }
  return `col-span-${col} ${isActive}`;
};

function goUrl(itemInfo: APItypes.IndexInfoGameDataListInfo) {
  contentInfoStore.goUrl(itemInfo);
}

// 大於6
const SixSwiper = ref<any>(null);
function onSixSwiper(swiper: any) {
  SixSwiper.value = swiper;
}
watchEffect(() => {
  if (props.Index === props.SlidesIndex && props.Lists!.length > 6) {
    changeSlide(SixSwiper.value);
  }
});

function changeSlide(SlideSwiper: any) {
  if (!SlideSwiper) return;
  const totalIndex = SlideSwiper.slides.length;
  const currentIndex = SlideSwiper.activeIndex;
  if (currentIndex === 0) {
    slidesTipState.value = "right";
    return;
  }
  if (currentIndex > 0 && currentIndex + 1 < totalIndex) {
    slidesTipState.value = "both";
    return;
  }
  if (currentIndex + 1 >= totalIndex) {
    slidesTipState.value = "left";
    return;
  }
}
</script>

<template>
  <div
    class="grid grid-cols-2 grid-rows-6 gap-1"
    :class="UserAgentWebview ? 'h-[calc(100%-70px)]' : 'h-[calc(100%-50px)]'">
    <template v-if="props.Lists!.length === 1">
      <!-- 單獨一張 -->
      <a
        v-for="(item, index) in props.Lists"
        @click="goUrl(Object.values(item)[0])"
        class="relative mb-2 overflow-hidden row-span-6"
        :class="getCol(props.Lists!.length, Object.values(item)[0] + 1, Object.values(item)[0].nActive)"
        :key="Object.values(item)[0] + 'playFormLayoutOnly'"
        :disabled="Object.values(item)[0].nActive === '0'">
        <img
          v-img-src="getBgImage(props.Lists!.length, index + 1)"
          alt=""
          class="h-full w-full" />
        <div class="absolute w-full h-full top-0 m-[6px]">
          <div class="relative h-[98%] w-full">
            <div class="absolute text-[16px] text-game m-[12px]">
              <img
                :src="Object.values(item)[0].sImgIcon"
                alt=""
                class="w-[100px]" />
              <p class="font-bold text-[16px]">
                {{ Object.values(item)[0].sTitle0 }}
              </p>
            </div>
            <img
              :src="Object.values(item)[0].sImgUrl"
              alt=""
              class="absolute bottom-0 -translate-y-2 right-[6%] h-[250px] mx-auto" />
          </div>
        </div>
        <!-- <p
          class="text-game text-[48px] font-bold shadow-md left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 absolute"
          v-if="Object.values(item)[0].nActive === '0'">
          {{ $t("Context.InMaintenance") }}
        </p> -->
        <img
          v-img-src="stamp"
          alt=""
          class="absolute z-[999] w-[60px] -translate-x-[0] -translate-y-[100%]"
          v-if="Object.values(item)[0].nActive === '0'" />
        <img
          :src="Object.values(item)[0].sCoverImgUrl"
          alt=""
          class="absolute z-[999] w-[45px] right-[-5px] top-0"
          v-if="Object.values(item)[0].nCoverpic === '1'" />
        <img
          :src="Object.values(item)[0].sCoverImgUrl"
          alt=""
          class="absolute z-[999] w-[45px] left-[-5px] top-[0px]"
          v-if="Object.values(item)[0].nCoverpic === '2'" />
      </a>
    </template>
    <template v-else-if="props.Lists!.length === 2">
      <!-- 單獨兩張 -->
      <a
        v-for="(item, index) in props.Lists"
        @click="goUrl(Object.values(item)[0])"
        class="relative row-span-3"
        :class="getCol(props.Lists!.length, index + 1, Object.values(item)[0].nActive)"
        :key="Object.values(item)[0] + 'playFormLayoutOnlyTwo'"
        :disabled="Object.values(item)[0].nActive === '0'">
        <img
          v-img-src="getBgImage(props.Lists!.length, index + 1)"
          alt=""
          class="h-full w-full" />
        <div class="absolute w-full h-full top-0 m-[6px]">
          <div class="relative h-full">
            <div class="h-[93.8%] w-full relative overflow-hidden">
              <img
                :src="Object.values(item)[0].sImgUrl"
                alt=""
                class="h-[100%] ml-auto mr-[6px]" />
            </div>
            <div
              class="absolute h-full top-0 w-[30%] mr-auto flex flex-col justify-around items-center text-[15px] text-game mx-[6px]">
              <div class="flex flex-col justify-center items-center">
                <img
                  :src="Object.values(item)[0].sImgIcon"
                  alt=""
                  class="w-[100px]" />
                <p class="font-bold mt-2 whitespace-nowrap">
                  {{ Object.values(item)[0].sTitle0 }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <p
          class="text-game font-bold shadow-md text-[42px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 absolute"
          v-if="Object.values(item)[0].nActive === '0'"
        >
          {{}}
        </p> -->
        <img
          v-img-src="stamp"
          alt=""
          class="absolute z-[999] w-[60px] -translate-x-[0] -translate-y-[100%]"
          v-if="Object.values(item)[0].nActive === '0'" />
        <img
          :src="Object.values(item)[0].sCoverImgUrl"
          alt=""
          class="absolute z-[999] w-[45px] right-[-5px] top-0"
          v-if="Object.values(item)[0].nCoverpic === '1'" />
        <img
          :src="Object.values(item)[0].sCoverImgUrl"
          alt=""
          class="absolute z-[999] w-[45px] left-[-5px] top-[0px]"
          v-if="Object.values(item)[0].nCoverpic === '2'" />
      </a>
    </template>

    <template v-else-if="props.Lists!.length === 3">
      <!-- 單獨三張 -->
      <a
        v-for="(item, index) in props.Lists"
        @click="goUrl(Object.values(item)[0])"
        class="relative row-span-2"
        :class="getCol(props.Lists!.length, index + 1, Object.values(item)[0].nActive)"
        :key="Object.values(item)[0] + 'playFormLayoutOnlyTwo'"
        :disabled="Object.values(item)[0].nActive === '0'">
        <img
          v-img-src="getBgImage(props.Lists!.length, index + 1)"
          alt=""
          class="h-full w-full" />
        <div class="absolute w-full h-full top-0 m-[6px]">
          <div class="relative h-[100%] w-full">
            <div class="h-[93%] w-full relative overflow-hidden">
              <img
                :src="Object.values(item)[0].sImgUrl"
                alt=""
                class="absolute w-[50%] right-[10%]" />
            </div>
            <div
              class="absolute h-full w-[50%] -top-1 left-0 flex flex-col justify-around items-center text-[14px] text-game mx-[6px]">
              <div class="flex flex-col justify-center items-center">
                <img
                  :src="Object.values(item)[0].sImgIcon"
                  alt=""
                  class="w-[60px]" />
                <p class="font-bold mt-1 truncate w-[100px]">
                  {{ Object.values(item)[0].sTitle0 }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <p
          class="text-game font-bold shadow-md text-[42px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 absolute"
          v-if="Object.values(item)[0].nActive === '0'">
          {{ $t("Context.InMaintenance") }}
        </p> -->
        <img
          v-img-src="stamp"
          alt=""
          class="absolute z-[999] w-[60px] -translate-x-[0] -translate-y-[100%]"
          v-if="Object.values(item)[0].nActive === '0'" />
        <img
          :src="Object.values(item)[0].sCoverImgUrl"
          alt=""
          class="absolute z-[999] w-[45px] right-[-5px] top-0"
          v-if="Object.values(item)[0].nCoverpic === '1'" />
        <img
          :src="Object.values(item)[0].sCoverImgUrl"
          alt=""
          class="absolute z-[999] w-[45px] left-[-5px] top-[0px]"
          v-if="Object.values(item)[0].nCoverpic === '2'" />
      </a>
    </template>
    <template v-else-if="props.Lists!.length === 4">
      <!-- 單獨四張 -->
      <a
        v-for="(item, index) in props.Lists"
        @click="goUrl(Object.values(item)[0])"
        class="relative row-span-3"
        :class="getCol(props.Lists!.length, index + 1, Object.values(item)[0].nActive)"
        :key="Object.values(item)[0] + 'playFormLayoutOnlyTwo'"
        :disabled="Object.values(item)[0].nActive === '0'">
        <img
          v-img-src="getBgImage(props.Lists!.length, index + 1)"
          alt=""
          class="h-full w-full" />
        <div class="absolute w-full h-full top-0 m-[6px]">
          <div class="relative h-[98%] w-full">
            <div class="h-[90%] w-full relative">
              <img
                :src="Object.values(item)[0].sImgUrl"
                alt=""
                class="absolute w-[68%] bottom-[-7.8%] right-[5%]" />
            </div>
            <div
              class="absolute h-full w-[30%] -top-[30%] left-[1%] flex flex-col justify-around items-center text-[14px] text-game mx-[6px]">
              <div class="flex flex-col justify-center items-center">
                <img
                  :src="Object.values(item)[0].sImgIcon"
                  alt=""
                  class="w-[45px] h-[45px] object-contain" />
                <p
                  class="text-center font-bold mt-1 text-[12px] w-[120%]"
                  style="text-wrap: nowrap">
                  {{ Object.values(item)[0].sTitle0 }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <p
          class="text-game font-bold shadow-md text-[24px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 absolute"
          v-if="Object.values(item)[0].nActive === '0'"
        >
          {{}}
        </p> -->
        <img
          v-img-src="stamp"
          alt=""
          class="absolute z-[999] w-[60px] -translate-x-[0] -translate-y-[100%]"
          v-if="Object.values(item)[0].nActive === '0'" />
        <img
          :src="Object.values(item)[0].sCoverImgUrl"
          alt=""
          class="absolute z-[999] w-[45px] right-[-5px] top-0"
          v-if="Object.values(item)[0].nCoverpic === '1'" />
        <img
          :src="Object.values(item)[0].sCoverImgUrl"
          alt=""
          class="absolute z-[999] w-[45px] left-[-5px] top-[0px]"
          v-if="Object.values(item)[0].nCoverpic === '2'" />
      </a>
    </template>

    <!-- v-else-if="props.Lists!.length === 6"> -->
    <template
      v-else-if="props.Lists!.length >= 5 && props.Lists!.length <= 6 && props.Lists!.length % 2 === 0">
      <!-- 雙數張 -->
      <a
        v-for="(item, index) in props.Lists"
        @click="goUrl(Object.values(item)[0])"
        class="relative row-span-2"
        :class="getCol(props.Lists!.length, index, Object.values(item)[0].nActive)"
        :key="Object.values(item)[0] + 'playFormLayoutEven'"
        :disabled="Object.values(item)[0].nActive === '0'">
        <img
          v-img-src="getBgImage(props.Lists!.length, index)"
          alt=""
          class="h-full w-full" />
        <div class="absolute w-full h-full top-0">
          <div class="relative h-[101%] w-full">
            <img
              :src="Object.values(item)[0].sImgUrl"
              alt=""
              class="absolute w-[60%] bottom-1 -right-0" />

            <div
              class="absolute top-[12px] left-[12px] flex flex-col justify-start items-start">
              <img
                :src="Object.values(item)[0].sImgIcon"
                alt=""
                class="h-[25px]" />
              <p class="font-bold mt-1 text-game text-[12px]">
                {{ Object.values(item)[0].sTitle0 }}
              </p>
            </div>
          </div>
        </div>
        <!-- <p
          class="text-game font-bold shadow-md text-[18px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 absolute"
          v-if="Object.values(item)[0].nActive === '0'">
          {{ $t("Context.InMaintenance") }}
        </p> -->
        <img
          v-img-src="stamp"
          alt=""
          class="absolute z-[999] w-[60px] -translate-x-[0] -translate-y-[100%]"
          v-if="Object.values(item)[0].nActive === '0'" />
        <img
          :src="Object.values(item)[0].sCoverImgUrl"
          alt=""
          class="absolute z-[999] w-[45px] right-[-5px] top-0"
          v-if="Object.values(item)[0].nCoverpic === '1'" />
        <img
          :src="Object.values(item)[0].sCoverImgUrl"
          alt=""
          class="absolute z-[999] w-[45px] left-[-5px] top-[0px]"
          v-if="Object.values(item)[0].nCoverpic === '2'" />
      </a>
    </template>

    <!-- v-else-if="props.Lists!.length === 5"> -->
    <template
      v-else-if="props.Lists!.length >= 5 && props.Lists!.length < 6 && props.Lists!.length % 2 === 1">
      <!-- 單數第一張 -->
      <a
        @click="goUrl(Object.values(props.Lists![0])[0])"
        class="relative row-span-2"
        :class="getCol(props.Lists!.length, 0, Object.values(props.Lists![0])[0].nActive)"
        :disabled="Object.values(props.Lists![0])[0].nActive === '0'">
        <img
          v-img-src="getBgImage(props.Lists!.length, 0)"
          alt=""
          class="h-full w-full" />
        <div class="absolute w-full h-full top-0 m-[6px]">
          <div class="relative h-[98%] w-full">
            <div class="h-[95%] w-full relative overflow-hidden">
              <img
                :src="Object.values(props.Lists![0])[0].sImgUrl"
                alt=""
                class="absolute w-[50%] right-[10%]" />
            </div>
            <div
              class="absolute h-full w-[50%] -top-1 left-0 flex flex-col justify-around items-center text-[14px] text-game mx-[6px]">
              <div class="flex flex-col justify-center items-center">
                <img
                  :src="Object.values(props.Lists![0])[0].sImgIcon"
                  alt=""
                  class="w-[60px]" />
                <p class="font-bold mt-1 truncate w-[100px]">
                  {{ Object.values(props.Lists![0])[0].sTitle0 }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <p
          class="text-game text-[24px] font-bold shadow-md left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 absolute"
          v-if="Object.values(props.Lists![0])[0].nActive === '0'">
          {{}}
        </p> -->
        <img
          v-img-src="stamp"
          alt=""
          class="absolute z-[999] w-[60px] -translate-x-[0] -translate-y-[100%]"
          v-if="Object.values(props.Lists![0])[0].nActive === '0'" />
        <img
          :src="Object.values(props.Lists![0])[0].sCoverImgUrl"
          alt=""
          class="absolute z-[999] w-[45px] right-[-5px] top-0"
          v-if="Object.values(props.Lists![0])[0].nCoverpic === '1'" />
        <img
          :src="Object.values(props.Lists![0])[0].sCoverImgUrl"
          alt=""
          class="absolute z-[999] w-[45px] left-[-5px] top-[0px]"
          v-if="Object.values(props.Lists![0])[0].nCoverpic === '2'" />
      </a>

      <!-- 雙數張 -->
      <template
        v-for="(item, index) in props.Lists"
        :key="item + 'playFormLayoutOdd'">
        <a
          @click="goUrl(Object.values(item)[0])"
          class="relative row-span-2"
          :class="getCol(props.Lists!.length, index, Object.values(item)[0].nActive)"
          :disabled="Object.values(item)[0].nActive === '0'"
          v-if="index !== 0">
          <img
            v-img-src="getBgImage(props.Lists!.length, index)"
            alt=""
            class="h-full w-full" />
          <div class="absolute w-full h-full top-0">
            <div class="relative h-[101%] w-full">
              <img
                :src="Object.values(item)[0].sImgUrl"
                alt=""
                class="absolute w-[60%] bottom-1 -right-0" />
              <div
                class="absolute top-[12px] left-[12px] flex flex-col justify-start items-start">
                <img
                  :src="Object.values(item)[0].sImgIcon"
                  alt=""
                  class="h-[25px]" />
                <p
                  class="font-bold mt-1 truncate w-[100px] text-game text-[12px]">
                  {{ Object.values(item)[0].sTitle0 }}
                </p>
              </div>
            </div>
          </div>
          <!-- <p
            class="text-game font-bold shadow-md text-[18px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 absolute"
            v-if="Object.values(item)[0].nActive === '0'">
            {{ $t("Context.InMaintenance") }}
          </p> -->
          <img
            v-img-src="stamp"
            alt=""
            class="absolute z-[999] w-[60px] -translate-x-[0] -translate-y-[100%]"
            v-if="Object.values(item)[0].nActive === '0'" />
          <img
            :src="Object.values(item)[0].sCoverImgUrl"
            alt=""
            class="absolute z-[999] w-[45px] right-[-5px] top-0"
            v-if="Object.values(item)[0].nCoverpic === '1'" />
          <img
            :src="Object.values(item)[0].sCoverImgUrl"
            alt=""
            class="absolute z-[999] w-[45px] left-[-5px] top-[0px]"
            v-if="Object.values(item)[0].nCoverpic === '2'" />
        </a>
      </template>
    </template>

    <template v-else-if="props.Lists!.length > 6">
      <!-- 雙數張 -->

      <div class="relative col-span-2 row-span-6">
        <Swiper
          :space-between="'3vh'"
          class="h-full w-full col-span-2"
          @swiper="onSixSwiper"
          @slideChange="changeSlide">
          <!-- for 館別 -->
          <SwiperSlide
            v-for="(list, index) in SwiperList"
            :key="index + 'playFormLayoutEvenSwiper'"
            class="h-auto">
            <div class="grid grid-cols-2 grid-rows-3 gap-1 h-full">
              <button
                v-for="(item, index) in list"
                @click="goUrl(Object.values(item)[0])"
                class="relative"
                :class="getCol(6, index, Object.values(item)[0].nActive)"
                :key="Object.values(item)[0] + 'playFormLayoutEven'"
                :disabled="Object.values(item)[0].nActive === '0'">
                <img
                  v-img-src="getBgImage(6, index)"
                  alt=""
                  class="h-full w-full" />
                <div class="absolute w-full h-full top-0">
                  <div class="relative h-[101%] w-full">
                    <img
                      :src="Object.values(item)[0].sImgUrl"
                      alt=""
                      class="absolute w-[60%] bottom-1 -right-0" />

                    <div
                      class="absolute top-[12px] left-[12px] flex flex-col justify-start items-start">
                      <img
                        :src="Object.values(item)[0].sImgIcon"
                        alt=""
                        class="h-[25px]" />
                      <p
                        class="font-bold mt-1 truncate w-[100px] text-game text-[12px]">
                        {{ Object.values(item)[0].sTitle0 }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- <p
                  class="text-game font-bold shadow-md text-[18px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10 absolute"
                  v-if="Object.values(item)[0].nActive === '0'">
                  {{ $t("Context.InMaintenance") }}
                </p> -->
                <img
                  v-img-src="stamp"
                  alt=""
                  class="absolute z-[999] w-[60px] -translate-x-[0] -translate-y-[100%]"
                  v-if="Object.values(item)[0].nActive === '0'" />
                <img
                  :src="Object.values(item)[0].sCoverImgUrl"
                  alt=""
                  class="absolute z-[999] w-[45px] right-[-5px] top-0"
                  v-if="Object.values(item)[0].nCoverpic === '1'" />
                <img
                  :src="Object.values(item)[0].sCoverImgUrl"
                  alt=""
                  class="absolute z-[999] w-[45px] left-[-5px] top-[0px]"
                  v-if="Object.values(item)[0].nCoverpic === '2'" />
              </button>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </template>
  </div>
</template>

<style>
.gamePlatform-scrollbar {
}
.gamePlatform-scrollbar::-webkit-scrollbar {
  /* width: 8px; */
  display: none;
}

/* Track */
.gamePlatform-scrollbar::-webkit-scrollbar-track {
  background: #21252927;
  border-radius: 20px;
}

/* Handle */
.gamePlatform-scrollbar::-webkit-scrollbar-thumb {
  background: #21252969;
  border-radius: 20px;
}

/* Handle on hover */
.gamePlatform-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #212529;
}

.platform-neon-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/01/game_company_s.png");
  -webkit-border-image: url("@image/memberFunction/01/game_company_s.png");
  -o-border-image: url("@image/memberFunction/01/game_company_s.png");
  border-image: url("@image/memberFunction/01/game_company_s.png") 1 fill;
}
</style>
