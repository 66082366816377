<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import PromotionRegisterPage from "@/components/login/PromotionRegisterPage.vue";
import { useMemberService } from "@/stores/memberFunction/memberService";
import MemberServiceContent from "@/components/menberFunction/memberServiceContent.vue";

import join_us_arrow_left from "@image/memberFunction/07/join-us_arrow_left.png";
import join_us_arrow_right from "@image/memberFunction/07/join-us_arrow_right.png";
import login_up_btn from "@image/memberFunction/07/login_up_btn.png";
import cs_csbtn from "@image/memberFunction/11_web/cs_csbtn.png";
import cs_icon_s from "@image/memberFunction/04/cs_icon_s.png";

const memberService = useMemberService();
const router = useRouter();

// 1: 註冊
const Mode = ref<number>(1);

function toMain() {
  router.push({ name: "main" });
}
</script>

<template>
  <div
    class="flex flex-col h-[100dvh] bg-cover bg-left bg-[url('@image/memberFunction/01/bg_01.png')] items-center justify-start lg:justify-center">
    <button @click="toMain" class="mt-[1em] lg:mt-0">
      <p>logo</p>
    </button>
    <div class="flex justify-between items-center my-[12px]">
      <img class="w-[100px]" v-img-src="join_us_arrow_left" alt="" />
      <p class="text-primary mx-[16px]">JOIN US</p>
      <img class="w-[100px]" v-img-src="join_us_arrow_right" alt="" />
    </div>
    <div class="mt-[12px] mb-[24px]">
      <div class="relative">
        <p
          class="text-[#342814] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-bold drop-shadow-md">
          註冊
        </p>
        <img
          v-img-src="login_up_btn"
          alt=""
          :class="Mode === 1 ? 'opacity-1' : 'opacity-0'"
          class="w-[140px]" />
      </div>
    </div>
    <PromotionRegisterPage />
  </div>

  <!-- web -->
  <div class="hidden lg:block absolute right-[2em] bottom-[2em] z-20">
    <div class="relative" v-if="memberService.isShowService">
      <MemberServiceContent />
    </div>
    <button
      class="absolute right-[-2em] bottom-[-2em] w-[50px] h-[50px]"
      @click="memberService.ToggleService">
      <img v-img-src="cs_csbtn" alt="" class="w-full h-full" />
    </button>
  </div>

  <!-- app -->
  <div
    class="lg:hidden absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 z-50 w-full flex justify-center items-center">
    <div class="relative" v-if="memberService.isShowService">
      <MemberServiceContent />
      <button
        class="absolute right-[0em] bottom-[-4em] w-[50px] h-[50px] z-30"
        @click="memberService.ToggleService">
        <img v-img-src="cs_icon_s" alt="" class="w-full h-full" />
      </button>
    </div>
  </div>
  <div
    class="lg:hidden absolute top-0 left-0 bg-black/50 w-full h-[100dvh] z-40"
    v-if="memberService.isShowService"></div>
</template>

<style></style>
