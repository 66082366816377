<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
import { required } from "@vee-validate/rules";
import { useIsSubmitting } from "vee-validate";

import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";

import 文字框_長_A from "@image/memberFunction/文字框/文字框_長_A.png";

interface WithdrawPageFrom {
  withdrawal: string;
  withdrawal_Type: string;
  nMoney: string;
  sPassword: string;
}
const { t } = useI18n();
const router = useRouter();
const memberService = useMemberService();
const store = useCounterStore();
const { MemberWithdrawPage, hasBankCard, WithdrawPageAlertContent } =
  storeToRefs(memberService);
const DispenseFee = computed(() => {
  let returnDispenseFee = "";
  if (!MemberWithdrawPage.value) return returnDispenseFee;
  if (!MemberWithdrawPage.value.setting_param) return returnDispenseFee;
  const mode = MemberWithdrawPage.value.setting_param.DispenseFeeMode;
  switch (mode) {
    case "0":
      returnDispenseFee = `${MemberWithdrawPage.value.setting_param.DispenseFeeFlat} 元`;
      break;
    case "1":
      returnDispenseFee =
        `${MemberWithdrawPage.value.setting_param.DispenseFeePer} %` +
        " + " +
        `${MemberWithdrawPage.value.setting_param.DispenseFeeFlat} 元`;
      break;
    default:
      returnDispenseFee = "";
      break;
  }
  return returnDispenseFee;
});

const WithdrawalsFrom = ref<any>(null);

async function submit(withdrawPageFrom: WithdrawPageFrom) {
  if (!hasBankCard.value) {
    store.toggleAlertBg();
    store.openWithdrawPageAlert();
    WithdrawPageAlertContent.value = t(
      "MemberFunction.Withdraw.BankCardNotFound"
    );
    return;
  }
  const { withdrawal, nMoney, sPassword } = withdrawPageFrom;

  const postData = {
    nTime: MemberWithdrawPage.value.setting_param.nTime.toString(),
    withdrawal: MemberWithdrawPage.value.user_bank[0].nId,
    withdrawal_Type: "1",
    nMoney: nMoney.toString(),
    sPassword,
  };
  await memberService.sendMemberWithdrawals(postData);
  WithdrawalsFrom.value.resetForm();
}

const gotoAddBank = () => {
  router.push({ name: "MemberBankPage" });
};
</script>

<template>
  <div class="relative member-in-border w-full h-full py-[12px] px-[12px]">
    <button
      type="button"
      class="btn-y-border py-[.1em] px-[2em] relative left-1/2 -translate-x-1/2 text-secondary"
      v-if="!hasBankCard"
      @click="gotoAddBank">
      {{ $t("MemberFunction.Withdraw.GotoBind") }}
    </button>
    <VForm @submit="submit" ref="WithdrawalsFrom" v-slot="{ isSubmitting }">
      <div class="text-primary">
        <label
          for=""
          class="grid grid-cols-8 text-[12px] md:text-[20px] mt-[.5em] md:mt-[1vh]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.Withdraw.TimeForWithdraw") }}
          </p>
          <p class="col-span-5">
            {{
              MemberWithdrawPage.setting_param
                ? MemberWithdrawPage.setting_param.WithdrawStartTime
                : ""
            }}
            ~
            {{
              MemberWithdrawPage.setting_param
                ? MemberWithdrawPage.setting_param.WithdrawEndTime
                : ""
            }}
          </p>
        </label>
        <label
          for="phone"
          class="grid grid-cols-8 text-[12px] md:text-[20px] mt-[.5em] md:mt-[2vh]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.Withdraw.MaxAmountForWithdraw") }}
          </p>
          <p class="col-span-5">
            {{
              MemberWithdrawPage.setting_param
                ? MemberWithdrawPage.setting_param.TopWithdraw
                : ""
            }}
            {{ $t("MemberFunction.Withdraw.Yuan") }}
          </p>
        </label>

        <label
          for="phone"
          class="grid grid-cols-8 text-[12px] md:text-[20px] mt-[.5em] md:mt-[2vh]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.Withdraw.WithdrawalFee") }}
          </p>
          <p class="col-span-5">{{ DispenseFee }}</p>
        </label>
        <!-- <label
          for="name"
          class="grid grid-cols-8 text-[12px] md:text-[20px] mt-[.5em] md:mt-[2vh]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.Withdraw.WithdrawTime") }}
          </p>
          <p class="col-span-5">
            {{
              MemberWithdrawPage.setting_param
                ? MemberWithdrawPage.setting_param.nTime
                : ""
            }}
            {{ $t("MemberFunction.Withdraw.Time") }} /
            {{
              MemberWithdrawPage.setting_param
                ? MemberWithdrawPage.setting_param.WithdrawTimes
                : ""
            }}
            {{ $t("MemberFunction.Withdraw.Time") }}
          </p>
        </label> -->
        <label
          for="phone"
          class="grid grid-cols-8 text-[12px] md:text-[20px] mt-[.5em] md:mt-[2vh]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.Withdraw.WithdrawalFreeFeeTime") }}
          </p>
          <p class="col-span-5">
            {{
              MemberWithdrawPage.setting_param
                ? MemberWithdrawPage.setting_param.FreeWithdrawTimes
                : "0"
            }}
            {{ $t("MemberFunction.Withdraw.Time") }}
          </p>
        </label>
        <label
          for="phone"
          class="grid grid-cols-8 text-[12px] md:text-[20px] mt-[.5em] md:mt-[2vh]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.Withdraw.WithdrawType") }}
          </p>
          <p class="col-span-5">{{ $t("MemberFunction.Withdraw.BankCard") }}</p>
        </label>
        <label
          for="withdrawal"
          class="grid grid-cols-8 text-[12px] md:text-[20px] mt-[.5em] md:mt-[2vh]">
          <p class="mr-[1em] col-span-3 self-center">
            {{ $t("MemberFunction.Withdraw.BankCardNumber") }}
          </p>

          <p class="col-span-5">
            {{
              MemberWithdrawPage.user_bank
                ? MemberWithdrawPage.user_bank[0].sPayNum
                : ""
            }}
          </p>
        </label>
        <label
          for="phone"
          class="grid grid-cols-8 text-[12px] md:text-[20px] mt-[.5em] md:mt-[2vh]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.Withdraw.AccountBank") }}
          </p>
          <p class="col-span-5">
            {{
              MemberWithdrawPage.user_bank
                ? MemberWithdrawPage.user_bank[0].sBank
                : ""
            }}
          </p>
        </label>
        <label
          for="bank"
          class="grid grid-cols-8 text-[12px] md:text-[20px] mt-[.5em] md:mt-[2vh]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.Withdraw.AccountBranch") }}
          </p>
          <p class="col-span-5">
            {{
              MemberWithdrawPage.user_bank
                ? MemberWithdrawPage.user_bank[0].sPayAddr
                : ""
            }}
          </p>
        </label>
        <label
          for="nMoney"
          class="grid grid-cols-8 text-[12px] md:text-[20px] mt-[.5em] md:mt-[2vh]">
          <p class="mr-[1em] col-span-3 self-center">
            {{ $t("MemberFunction.Withdraw.WithdrawAmount") }}
          </p>
          <div class="col-span-5 md:col-span-2">
            <div class="relative">
              <div class="h-[26px]">
                <img v-img-src="文字框_長_A" alt="" class="h-[26px] w-full" />
              </div>
              <VField
                class="absolute h-[26px] top-0 rounded-md bg-transparent w-full px-2"
                :label="$t('MemberFunction.Withdraw.WithdrawAmount')"
                name="nMoney"
                type="number"
                :min="
                  MemberWithdrawPage.setting_param
                    ? MemberWithdrawPage.setting_param.LowestWithdraw
                    : 0
                "
                :rules="{
                  required: true,
                  min_value: MemberWithdrawPage.setting_param
                    ? MemberWithdrawPage.setting_param.LowestWithdraw
                    : 0,
                }" />
            </div>
            <ErrorMessage
              name="nMoney"
              class="text-red-400 text-[12px] text-center static lg:absolute" />
          </div>
        </label>
        <label
          for="sPassword"
          class="grid grid-cols-8 text-[12px] md:text-[20px] mt-[.5em] md:mt-[2vh]">
          <p class="mr-[1em] col-span-3 self-center">
            {{ $t("MemberFunction.Withdraw.CheckPassword") }}
          </p>
          <div class="col-span-5 md:col-span-2">
            <div class="relative">
              <div class="h-[26px]">
                <img v-img-src="文字框_長_A" alt="" class="h-[26px] w-full" />
              </div>
              <VField
                class="absolute h-[26px] top-0 rounded-md bg-transparent w-full px-2"
                :label="t('MemberFunction.Withdraw.CheckPassword')"
                name="sPassword"
                type="password"
                :min="
                  MemberWithdrawPage.setting_param
                    ? MemberWithdrawPage.setting_param.LowestWithdraw
                    : 0
                "
                :rules="{ required: true }" />
            </div>
            <ErrorMessage
              name="sPassword"
              class="text-red-400 text-[12px] text-center static lg:absolute" />
          </div>
        </label>
        <label
          for="phone"
          class="grid grid-cols-8 text-[12px] md:text-[20px] mt-[.5em] md:mt-[2vh]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.Withdraw.WageringLimit") }}
          </p>
          <p class="col-span-5">
            {{
              MemberWithdrawPage.setting_param
                ? MemberWithdrawPage.setting_param.nAccess
                : ""
            }}
          </p>
        </label>
        <label
          for="phone"
          class="grid grid-cols-8 text-[12px] md:text-[20px] mt-[.5em] md:mt-[2vh]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.Withdraw.RequiredWageringAmount") }}
          </p>
          <p class="col-span-5">
            {{
              MemberWithdrawPage.setting_param
                ? MemberWithdrawPage.setting_param.nAccessMin
                : ""
            }}
          </p>
        </label>
        <label
          for="phone"
          class="grid grid-cols-8 text-[12px] md:text-[20px] mt-[.5em] md:mt-[2vh]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.Withdraw.MinimumWithdrawal") }}
          </p>
          <p class="col-span-5">
            {{
              MemberWithdrawPage.setting_param
                ? MemberWithdrawPage.setting_param.LowestWithdraw
                : ""
            }}
            {{ $t("MemberFunction.Withdraw.Yuan") }}
          </p>
        </label>
      </div>
      <div
        class="absolute lg:static bottom-[-12%] lg:bottom-[0] left-1/2 mt-[2em] -translate-x-1/2 lg:translate-x-0 flex items-center justify-center">
        <button
          type="submit"
          class="btn-y-border"
          :class="{ submitting: isSubmitting }"
          :disabled="isSubmitting">
          <p
            class="text-[12px] md:text-[18px] text-secondary px-[1.2em] py-[.1em] z-10">
            {{ isSubmitting ? "" : $t("MemberFunction.Withdraw.Next") }}
          </p>
        </button>
      </div>
    </VForm>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
</style>
