<script setup lang="ts">
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";
import { useContentInfo } from "@/stores/contentInfo";
import { useRouter } from "vue-router";

import hot_btn from "@image/memberFunction/01/hot_btn.png";
import marquee_bg from "@image/memberFunction/01/marquee_bg.png";

const router = useRouter();
const contentInfoStore = useContentInfo();
const { contentInfoAMarquee, MarqueeSeconds } = storeToRefs(contentInfoStore);

// const MarqueeText = ref<string>("");
const textRef = ref<HTMLParagraphElement | null>(null);
const containerRef = ref<HTMLDivElement | null>(null);

const goNewMes = () => {
  router.push({ name: "NewMessage" });
};

function getBillboardWidth() {
  if (!textRef.value) return 0;
  return textRef.value.offsetWidth;
}
function getWindowWidth() {
  if (!containerRef.value) return 0;
  return containerRef.value.clientWidth;
}

function startBillboard() {
  let initLeft = getWindowWidth();
  let timer = setInterval(() => {
    if (!textRef.value) return;
    if (initLeft < getBillboardWidth() * -1) {
      initLeft = getWindowWidth();
    }
    initLeft -= 1;
    textRef.value.style.left = initLeft + "px";
  }, 20);
}

watch(contentInfoAMarquee, () => {
  startBillboard();
});

onMounted(async () => {
  if (contentInfoAMarquee.value) {
    startBillboard();
  }
  // window.addEventListener("resize", adjustMarquee);
});
onBeforeUnmount(() => {
  // window.removeEventListener("resize", adjustMarquee);
});
</script>

<template>
  <div class="flex items-center">
    <div class="marquee-app-neon-border">
      <div class="relative ml-[10%] mr-[5%] py-[2px]">
        <img v-img-src="marquee_bg" alt="" class="opacity-0 w-[100%]" />
        <div class="absolute top-1/2 -translate-y-1/2 w-full h-full">
          <div
            ref="containerRef"
            class="relative overflow-hidden w-full h-full"
            style="white-space: nowrap">
            <p
              class="text-primary text-[12px] absolute left-0 top-[3px]"
              ref="textRef">
              {{ contentInfoAMarquee }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="relative" @click="goNewMes">
      <img v-img-src="hot_btn" alt="" />
      <p
        class="truncate leading-[9px] absolute top-1/2 -translate-y-1/2 left-[60%] -translate-x-1/2 w-[82%] text-notice font-bold text-[9px]">
        {{ $t("Context.Notice") }}
      </p>
    </div>
  </div>
</template>

<style>
.marquee1 {
  animation: marquee 6s linear infinite;
  animation-timing-function: linear;
  white-space: nowrap;
}
.marquee2 {
  animation: marquee 8s linear infinite;
  animation-timing-function: linear;
  white-space: nowrap;
}
.marquee3 {
  animation: marquee 10s linear infinite;
  animation-timing-function: linear;
  white-space: nowrap;
}
.marquee4 {
  animation: marquee 12s linear infinite;
  animation-timing-function: linear;
  white-space: nowrap;
}
.marquee5 {
  animation: marquee 14s linear infinite;
  animation-timing-function: linear;
  white-space: nowrap;
}
.bg-100 {
  background-size: 100% 100%;
}
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.marquee-app-neon-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/01/marquee_bg.png");
  -webkit-border-image: url("@image/memberFunction/01/marquee_bg.png");
  -o-border-image: url("@image/memberFunction/01/marquee_bg.png");
  border-image: url("@image/memberFunction/01/marquee_bg.png") 1 fill;
}
</style>
