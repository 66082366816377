<script setup lang="ts">
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useMemberService } from "@/stores/memberFunction/memberService";

import Chat_Bg from "@image/memberFunction/11_web/cs_chatroom.png";
import Service_Bg from "@image/memberFunction/11_web/chat_bg.png";
import cs from "@image/memberFunction/04/cs.png";
import cs_emoji from "@image/memberFunction/04/cs_emoji.png";
import twofourhour from "@image/memberFunction/04/24hour.png";
import cs_photo from "@image/memberFunction/04/cs_photo.png";
import line_icon from "@image/memberFunction/04/line_icon.png";
const memberService = useMemberService();
const { WebTalk, isService } = storeToRefs(memberService);

function ToLineLink() {
  memberService.getWebTalkLineService();
}
function openService() {
  memberService.openService();
}

// c: 會員發出的訊息, a: 管理員發出的訊息
const chatId = (name: string | number) => {
  if (typeof name === "number") return "a";
  const parts = name.split("_");
  const lastPart = parts[parts.length - 1];
  return lastPart;
};

const textareaValue = ref<string>("");
const inputValue = ref<HTMLInputElement | null>(null);
const inputValueName = ref<string>("");
const chatScroll = ref<any>();
let isSending = false;

async function sendText() {
  // 如果檔案與文字都沒有
  if (textareaValue.value.trim() === "" && !inputValue.value) {
    return (textareaValue.value = "");
  }
  // 如果沒有文字
  if (textareaValue.value.trim() === "") {
    textareaValue.value = "";
  }
  let file: Blob | string = "";
  if (inputValue.value) {
    file = (inputValue.value.files as FileList)[0];
  }
  isSending = true;
  await memberService.sendWebTalk(textareaValue.value, file);
  ScrollTop();
  textareaValue.value = "";
  if (inputValue.value) {
    inputValue.value.value = "";
  }
  isSending = false;
}
function getFileName() {
  if (!inputValue.value) return;
  const file = (inputValue.value.files as FileList)[0];
  inputValueName.value = file.name;
}
function clearValue() {
  if (!inputValue.value) return;
  inputValue.value.value = "";
}

function ScrollTop() {
  chatScroll.value.scrollTop = chatScroll.value.scrollHeight;
}
watch(
  () => WebTalk.value,
  () => {
    ScrollTop();
  }
);
</script>

<template>
  <img class="w-[340px]" v-if="isService" :v-img-src="Chat_Bg" alt="" />
  <img class="w-[340px]" v-else v-img-src="Service_Bg" alt="" />
  <div class="" v-show="isService">
    <p
      class="absolute text-[18px] font-bold text-primary top-[30px] left-1/2 -translate-x-1/2">
      {{ $t("MemberFunction.ServiceContent.ServiceContent") }}
    </p>
    <div
      class="absolute w-[90%] h-[63%] top-[5em] pl-[2rem] pr-[.5rem] pb-[2rem] overflow-y-scroll flex flex-col text-secondary service-content-scrollbar"
      ref="chatScroll">
      <template v-for="(value, key) in memberService.WebTalk" :key="key">
        <div
          class="mt-[2em] flex justify-start items-end"
          v-if="chatId(key) === 'a'">
          <img v-img-src="cs" alt="" class="w-[35px] mr-1" />
          <p
            class="max-w-[calc(100%-35px)] service-s-chat-border py-1 px-4 inline text-black">
            {{ value }}
          </p>
        </div>
        <div
          class="mt-[2em] flex justify-start items-end"
          v-if="chatId(key) === 'aimg'">
          <img v-img-src="cs" alt="" class="w-[35px] mr-1" />
          <img
            :src="value"
            class="max-w-[calc(80%-35px)] bg-white p-4 rounded-lg rounded-bl-none inline text-secondary"
            @load="ScrollTop" />
        </div>
        <div
          class="mt-[2em] flex justify-end items-end w-full"
          v-if="chatId(key) === 'c'">
          <p class="service-c-chat-border py-1 px-3 text-secondary break-all">
            {{ value }}
          </p>
        </div>
        <div
          class="mt-[2em] flex justify-end items-end"
          v-if="chatId(key) === 'cimg'">
          <img
            :src="value"
            class="max-w-[calc(80%-35px)] bg-white p-4 rounded-lg rounded-br-none inline text-secondary"
            @load="ScrollTop" />
        </div>
      </template>
    </div>
    <div>
      <div
        class="absolute bottom-[8%] left-[8%] h-[9%] flex justify-start items-center w-full">
        <textarea
          @keydown.enter.prevent="sendText"
          v-model="textareaValue"
          name=""
          id=""
          cols="24"
          rows="8"
          class="bg-transparent h-full w-[190px] p-1 resize-none rounded-[12px] text-secondary service-content-scrollbar"></textarea>
        <label
          class="text-primary text-[24px] w-[25px] h-[25px] rounded-full ml-[5px] relative z-20"
          for="inputValue">
          <input
            id="inputValue"
            type="file"
            class="hidden"
            ref="inputValue"
            accept=".jpg,.png"
            @change="getFileName" />

          <img v-img-src="cs_emoji" alt="" class="object-contain" />
        </label>
      </div>
      <div
        class="absolute flex justify-start items-center left-[1vw]"
        v-if="inputValue && inputValue.value">
        <button
          class="bg-red-600 w-[18px] h-[18px] rounded-full flex justify-center items-center text-primary"
          @click="clearValue">
          <p>x</p>
        </button>
        <p class="bg-white text-black/60 px-1 rounded ml-[12px]">
          {{ inputValueName }}
        </p>
      </div>
    </div>
    <div class="absolute bottom-[7.5%] right-[2.2rem] flex items-center">
      <button
        type="button"
        @click="sendText"
        :disabled="isSending"
        class="service-cs-btn-border text-primary text-[24px] w-[42px] h-[46px] rounded">
        <p class="text-primary text-[12px]">
          {{ $t("MemberFunction.ServiceContent.Send") }}
        </p>
      </button>
    </div>
  </div>
  <div
    class="absolute top-[1rem] py-[2em] px-[2em] right-1 w-full"
    v-show="!isService">
    <img v-img-src="twofourhour" alt="" class="w-[30%]" />
    <img v-img-src="cs_photo" alt="" class="w-[240px] mx-auto" />
    <div
      class="flex justify-around items-center service-add-bg-border py-[1em] mt-[2em] rounded-[12px]">
      <img class="w-[25px]" v-img-src="line_icon" alt="" />
      <p class="text-primary text-[12px]">
        {{ $t("MemberFunction.ServiceContent.LineService") }}
      </p>
      <button
        type="button"
        class="flex justify-center items-center btn-y-border w-[90px] h-[26px]"
        @click="ToLineLink">
        <div class="absolute text-[12px] text-secondary font-bold">
          {{ $t("MemberFunction.ServiceContent.Join") }}
        </div>
      </button>
    </div>
  </div>
</template>

<style>
.service-content-scrollbar {
}
.service-content-scrollbar::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.service-content-scrollbar::-webkit-scrollbar-track {
  background: #21252927;
  border-radius: 20px;
}

/* Handle */
.service-content-scrollbar::-webkit-scrollbar-thumb {
  background: #21252969;
  border-radius: 20px;
}

/* Handle on hover */
.service-content-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #212529;
}

.service-add-bg-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/04/cs_add_bg.png") 1;
  -webkit-border-image: url("@image/memberFunction/04/cs_add_bg.png") 1;
  -o-border-image: url("@image/memberFunction/04/cs_add_bg.png") 1;
  border-image: url("@image/memberFunction/04/cs_add_bg.png") 1 fill;
}
.service-cs-btn-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/04/cs_btn.png") 1;
  -webkit-border-image: url("@image/memberFunction/04/cs_btn.png") 1;
  -o-border-image: url("@image/memberFunction/04/cs_btn.png") 1;
  border-image: url("@image/memberFunction/04/cs_btn.png") 1 fill;
}
.service-s-chat-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/04/話框01.png") 1;
  -webkit-border-image: url("@image/memberFunction/04/話框01.png") 1;
  -o-border-image: url("@image/memberFunction/04/話框01.png") 1;
  border-image: url("@image/memberFunction/04/話框01.png") 1 fill;
}
.service-c-chat-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/04/話框02.png") 1;
  -webkit-border-image: url("@image/memberFunction/04/話框02.png") 1;
  -o-border-image: url("@image/memberFunction/04/話框02.png") 1;
  border-image: url("@image/memberFunction/04/話框02.png") 1 fill;
}
</style>
