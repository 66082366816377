<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useErrorAlert } from "@/stores/errorAlert";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useLogin } from "@/stores/login";

import member_pic_01 from "@image/memberFunction/main/member_pic_01.png";
import id_bg from "@image/memberFunction/04_web/id_bg.png";
import L_btn_p from "@image/memberFunction/01/L_btn_p.png";

import topBtnsImg1 from "@image/memberFunction/05/my_app_deposit.png";
import topBtnsImg2 from "@image/memberFunction/05/my_app_pick.png";
import topBtnsImg5 from "@image/memberFunction/05/my_app_card.png";

import MemberAccountIcon_p from "@image/memberFunction/04_web/我的帳戶_p.png";
import MemberAccountIcon from "@image/memberFunction/04_web/我的帳戶.png";
import VIPPrivilegeIcon_p from "@image/memberFunction/04_web/VIP_p.png";
import VIPPrivilegeIcon from "@image/memberFunction/04_web/VIP.png";
import MemberListIcon_p from "@image/memberFunction/04_web/會員列表_p.png";
import MemberListIcon from "@image/memberFunction/04_web/會員列表.png";

import MemberMailIcon_p from "@image/memberFunction/04_web/會員信箱_p.png";
import MemberMailIcon from "@image/memberFunction/04_web/會員信箱.png";

import TakeOverCoinIcon_p from "@image/memberFunction/04_web/我的錢包_p.png";
import TakeOverCoinIcon from "@image/memberFunction/04_web/我的錢包.png";

import BetRecordIcon_p from "@image/memberFunction/04_web/下注紀錄_p.png";
import BetRecordIcon from "@image/memberFunction/04_web/下注紀錄.png";

import TradeRecordIcon_p from "@image/memberFunction/04_web/交易紀錄_p.png";
import TradeRecordIcon from "@image/memberFunction/04_web/交易紀錄.png";

import PromoteLinkIcon_p from "@image/memberFunction/04_web/推廣_p.png";
import PromoteLinkIcon from "@image/memberFunction/04_web/推廣.png";

import RankingListIcon_p from "@image/memberFunction/04_web/排行_p.png";
import RankingListIcon from "@image/memberFunction/04_web/排行.png";

import NewMessageIcon_p from "@image/memberFunction/04_web/系統公告_p.png";
import NewMessageIcon from "@image/memberFunction/04_web/系統公告.png";

import ShareMakeFortunePageIcon_p from "@image/memberFunction/04_web/分享發財_p.png";
import ShareMakeFortunePageIcon from "@image/memberFunction/04_web/分享發財.png";

interface PathList {
  title: string;
  pathName: string;
}
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const login = useLogin();
const errorAlert = useErrorAlert();
const { memberData, isCashMember } = storeToRefs(login);

// 上方選單
const topBtns = ref<PathList[]>([
  {
    title: t("MemberFunction.FunctionNavbar.Deposit"),
    pathName: "DepositPage",
  },
  {
    title: t("MemberFunction.FunctionNavbar.Withdraw"),
    pathName: "WithdrawPage",
  },
  {
    title: t("MemberFunction.FunctionNavbar.BankCard"),
    pathName: "MemberBankPage",
  },
]);
const getTopBtnsImg = (title: string) => {
  let image;
  switch (title) {
    case t("MemberFunction.FunctionNavbar.Deposit"):
      image = topBtnsImg1;
      break;
    case t("MemberFunction.FunctionNavbar.Withdraw"):
      image = topBtnsImg2;
      break;
    case t("MemberFunction.FunctionNavbar.BankCard"):
      image = topBtnsImg5;
      break;
    default:
      break;
  }
  return image;
};

// 側邊選單列表

const pathList1 = computed(() => {
  if (isCashMember.value) {
    if (memberData.value.nRank === "0") {
      return [
        {
          title: t("MemberFunction.FunctionNavbar.MemberAccount"),
          pathName: "MemberAccount",
        },
        {
          title: t("MemberFunction.FunctionNavbar.VIPPrivilege"),
          pathName: "VIPPrivilege",
        },
        {
          title: t("MemberFunction.FunctionNavbar.MemberMail"),
          pathName: "MemberMail",
        },
      ];
    } else {
      return [
        {
          title: t("MemberFunction.FunctionNavbar.MemberAccount"),
          pathName: "MemberAccount",
        },
        {
          title: t("MemberFunction.FunctionNavbar.VIPPrivilege"),
          pathName: "VIPPrivilege",
        },
        {
          title: t("MemberFunction.FunctionNavbar.MemberList"),
          pathName: "MemberList",
        },
        {
          title: t("MemberFunction.FunctionNavbar.MemberMail"),
          pathName: "MemberMail",
        },
      ];
    }
  } else {
    if (memberData.value.nRank === "0") {
      return [
        {
          title: t("MemberFunction.FunctionNavbar.MemberAccount"),
          pathName: "MemberAccount",
        },
        {
          title: t("MemberFunction.FunctionNavbar.MemberMail"),
          pathName: "MemberMail",
        },
      ];
    } else {
      return [
        {
          title: t("MemberFunction.FunctionNavbar.MemberAccount"),
          pathName: "MemberAccount",
        },
        {
          title: t("MemberFunction.FunctionNavbar.MemberList"),
          pathName: "MemberList",
        },
        {
          title: t("MemberFunction.FunctionNavbar.MemberMail"),
          pathName: "MemberMail",
        },
      ];
    }
  }
});
const pathList2 = computed(() => {
  if (isCashMember.value) {
    return [
      {
        title: t("MemberFunction.FunctionNavbar.TakeOverCoin"),
        pathName: "TakeOverCoin",
      },
      {
        title: t("MemberFunction.FunctionNavbar.BetRecord"),
        pathName: "BetRecord",
      },
      {
        title: t("MemberFunction.FunctionNavbar.TradeRecord"),
        pathName: "TradeRecord",
      },
    ];
  } else {
    return [
      {
        title: t("MemberFunction.FunctionNavbar.TakeOverCoin"),
        pathName: "TakeOverCoin",
      },
      {
        title: t("MemberFunction.FunctionNavbar.BetRecord"),
        pathName: "BetRecord",
      },
    ];
  }
});
const pathList3 = computed(() => {
  if (isCashMember.value) {
    if (memberData.value.nRank === "0") {
      return [
        {
          title: t("MemberFunction.FunctionNavbar.RankingList"),
          pathName: "RankingList",
        },
        {
          title: t("MemberFunction.FunctionNavbar.NewMessage"),
          pathName: "NewMessage",
        },
        {
          title: t("MemberFunction.FunctionNavbar.ShareMakeFortunePage"),
          pathName: "ShareMakeFortunePage",
        },
      ];
    } else {
      return [
        {
          title: t("MemberFunction.FunctionNavbar.Promote"),
          pathName: "PromoteLink",
        },
        {
          title: t("MemberFunction.FunctionNavbar.RankingList"),
          pathName: "RankingList",
        },
        {
          title: t("MemberFunction.FunctionNavbar.NewMessage"),
          pathName: "NewMessage",
        },
      ];
    }
  } else {
    if (memberData.value.nRank === "0") {
      return [
        {
          title: t("MemberFunction.FunctionNavbar.RankingList"),
          pathName: "RankingList",
        },
        {
          title: t("MemberFunction.FunctionNavbar.NewMessage"),
          pathName: "NewMessage",
        },
      ];
    } else {
      return [
        {
          title: t("MemberFunction.FunctionNavbar.RankingList"),
          pathName: "RankingList",
        },
        {
          title: t("MemberFunction.FunctionNavbar.NewMessage"),
          pathName: "NewMessage",
        },
      ];
    }
  }
});

// 取得選單icon
function getIconOn(path: string) {
  let image;
  switch (path) {
    case "MemberAccount":
      image = MemberAccountIcon_p;
      break;
    case "VIPPrivilege":
      image = VIPPrivilegeIcon_p;
      break;
    case "MemberMail":
      image = MemberMailIcon_p;
      break;
    case "TakeOverCoin":
      image = TakeOverCoinIcon_p;
      break;
    case "MemberList":
      image = MemberListIcon_p;
      break;
    case "BetRecord":
      image = BetRecordIcon_p;
      break;
    case "TradeRecord":
      image = TradeRecordIcon_p;
      break;
    case "PromoteLink":
      image = PromoteLinkIcon_p;
      break;
    case "RankingList":
      image = RankingListIcon_p;
      break;
    case "NewMessage":
      image = NewMessageIcon_p;
      break;
    case "ShareMakeFortunePage":
      image = ShareMakeFortunePageIcon_p;
      break;
    default:
      image = MemberAccountIcon_p;
      break;
  }
  return image;
}
function getIconOff(path: string) {
  let image;
  switch (path) {
    case "MemberAccount":
      image = MemberAccountIcon;
      break;
    case "VIPPrivilege":
      image = VIPPrivilegeIcon;
      break;
    case "MemberMail":
      image = MemberMailIcon;
      break;
    case "MemberList":
      image = MemberListIcon;
      break;
    case "TakeOverCoin":
      image = TakeOverCoinIcon;
      break;
    case "BetRecord":
      image = BetRecordIcon;
      break;
    case "TradeRecord":
      image = TradeRecordIcon;
      break;
    case "PromoteLink":
      image = PromoteLinkIcon;
      break;
    case "RankingList":
      image = RankingListIcon;
      break;
    case "NewMessage":
      image = NewMessageIcon;
      break;
    case "ShareMakeFortunePage":
      image = ShareMakeFortunePageIcon;
      break;
    default:
      image = MemberAccountIcon;
      break;
  }
  return image;
}

// 檢查當前route path
const isSelectPath = (pathName: string) => {
  // console.log(route.name);
  return route.name === pathName;
};

// 進入選單
function toPath(pathName: string) {
  // if (pathName === "ShareMakeFortunePage") {
  //   return errorAlert.AddErrorAlert("敬請期待");
  // }
  router.push({ name: pathName });
}
</script>
<template>
  <div class="relative">
    <div class="sidebar-gold-border rounded-[24px] py-[5dvh] min-h-[100dvh]">
      <div class="flex flex-col justify-center items-center px-[1em]">
        <div class="relative">
          <img v-img-src="member_pic_01" alt="" class="w-[80px] absolute" />
          <img v-img-src="id_bg" alt="" class="w-[80px] relative" />
        </div>
        <p class="text-primary text-[18px] font-bold mt-[1dvh]">
          {{ memberData.sAccount }}
          {{ login.isCashMember ? "" : "信用" }}
        </p>
        <p class="text-primary text-[14px] mt-[1dvh]">
          {{ $t("MemberFunction.FunctionNavbar.LastLoginTime") }}
          {{ memberData.sLastLogin }}
        </p>
      </div>

      <ul class="grid grid-cols-3 z-20 my-[3dvh]" v-if="isCashMember">
        <li
          class="flex flex-col justify-center items-center px-[1em]"
          v-for="item in topBtns"
          :key="item + '-topBtn'">
          <button
            class="flex flex-col items-center"
            @click="toPath(item.pathName)">
            <img
              v-img-src="getTopBtnsImg(item.title)"
              alt=""
              class="w-[36px] h-[36px] object-contain" />
            <p class="text-coin text-[14px]">
              {{ item.title }}
            </p>
          </button>
        </li>
      </ul>
      <div class="my-[3dvh]" v-else />
      <ul class="">
        <li v-for="type in pathList1" :key="type.title + 'PathNavbar'">
          <button
            type="button"
            class="group relative w-full pl-[3.5vw] py-[1.5dvh]"
            @click="toPath(type.pathName)">
            <div
              class="h-full w-[calc(100%)] absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
              :class="{
                'hidden group-hover:block': !isSelectPath(type.pathName),
                block: isSelectPath(type.pathName),
              }">
              <img v-img-src="L_btn_p" alt="" class="w-full" />
            </div>
            <div
              class="relative z-10 w-full h-full flex justify-start items-center">
              <img
                v-img-src="getIconOn(type.pathName)"
                alt=""
                class="mr-[2vw] w-[20px]"
                :class="{
                  'hidden group-hover:block': !isSelectPath(type.pathName),
                  block: isSelectPath(type.pathName),
                }" />
              <img
                v-img-src="getIconOff(type.pathName)"
                alt=""
                class="mr-[2vw] w-[20px]"
                :class="{
                  'block group-hover:hidden': !isSelectPath(type.pathName),
                  ' hidden': isSelectPath(type.pathName),
                }" />
              <p
                class="font-bold text-[16px] xl:text-[18px]"
                :class="{
                  'text-primary group-hover:text-secondary': !isSelectPath(
                    type.pathName
                  ),
                  ' text-secondary': isSelectPath(type.pathName),
                }">
                {{ type.title }}
              </p>
            </div>
          </button>
        </li>
        <div
          class="h-[.5dvh] w-[80%] my-[1.5dvh] mx-auto rounded-md bg-yellow-200"></div>
        <li v-for="type in pathList2" :key="type.title + 'PathNavbar'">
          <button
            type="button"
            class="group relative w-full pl-[3.5vw] py-[1.5dvh]"
            @click="toPath(type.pathName)">
            <div
              class="h-full w-[calc(100%)] absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
              :class="{
                'hidden group-hover:block': !isSelectPath(type.pathName),
                block: isSelectPath(type.pathName),
              }">
              <img v-img-src="L_btn_p" alt="" class="w-full" />
            </div>
            <div
              class="relative z-10 w-full h-full flex justify-start items-center">
              <img
                v-img-src="getIconOn(type.pathName)"
                alt=""
                class="mr-[2vw] w-[20px] h-[20px]"
                :class="{
                  'hidden group-hover:block': !isSelectPath(type.pathName),
                  block: isSelectPath(type.pathName),
                }" />
              <img
                v-img-src="getIconOff(type.pathName)"
                alt=""
                class="mr-[2vw] w-[20px] h-[20px]"
                :class="{
                  'block group-hover:hidden': !isSelectPath(type.pathName),
                  ' hidden': isSelectPath(type.pathName),
                }" />
              <p
                class="font-bold text-[16px] xl:text-[18px]"
                :class="{
                  'text-primary group-hover:text-secondary': !isSelectPath(
                    type.pathName
                  ),
                  ' text-secondary': isSelectPath(type.pathName),
                }">
                {{ type.title }}
              </p>
            </div>
          </button>
        </li>
        <div
          class="h-[.5dvh] w-[80%] my-[1.5dvh] mx-auto rounded-md bg-yellow-200"></div>
        <li v-for="type in pathList3" :key="type.title + 'PathNavbar'">
          <button
            type="button"
            class="group relative w-full pl-[3.5vw] py-[1.5dvh]"
            @click="toPath(type.pathName)">
            <div
              class="h-full w-[calc(100%)] absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
              :class="{
                'hidden group-hover:block': !isSelectPath(type.pathName),
                block: isSelectPath(type.pathName),
              }">
              <img v-img-src="L_btn_p" alt="" class="w-full" />
            </div>
            <div
              class="relative z-10 w-full h-full flex justify-start items-center">
              <img
                v-img-src="getIconOn(type.pathName)"
                alt=""
                class="mr-[2vw] w-[20px] h-[20px]"
                :class="{
                  'hidden group-hover:block': !isSelectPath(type.pathName),
                  block: isSelectPath(type.pathName),
                }" />
              <img
                v-img-src="getIconOff(type.pathName)"
                alt=""
                class="mr-[2vw] w-[20px] h-[20px]"
                :class="{
                  'block group-hover:hidden': !isSelectPath(type.pathName),
                  ' hidden': isSelectPath(type.pathName),
                }" />
              <p
                class="font-bold text-[16px] xl:text-[18px]"
                :class="{
                  'text-primary group-hover:text-secondary': !isSelectPath(
                    type.pathName
                  ),
                  ' text-secondary': isSelectPath(type.pathName),
                }">
                {{ type.title }}
              </p>
            </div>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style>
@property --bg-angle {
  inherits: false;
  initial-value: 0deg;
  syntax: "<angle>";
}
.sidebar-gold-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/01/L_bg.png") 1;
  -webkit-border-image: url("@image/memberFunction/01/L_bg.png") 1;
  -o-border-image: url("@image/memberFunction/01/L_bg.png") 1;
  border-image: url("@image/memberFunction/01/L_bg.png") 1 fill;
}
</style>
