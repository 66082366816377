<script setup lang="ts">
import { ref, watch, watchEffect, computed } from "vue";
import { storeToRefs } from "pinia";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
import { useLogin } from "@/stores/login";
import { required } from "@vee-validate/rules";

import 文字框_長_A from "@image/memberFunction/文字框/文字框_長_A.png";
import triangle_icon from "@image/memberFunction/common/triangle_icon.png";
import 反水底 from "@image/memberFunction/13/反水底.png";
import 反水btn from "@image/memberFunction/13/反水btn.png";

const login = useLogin();
const store = useCounterStore();
const { memberData } = storeToRefs(login);
const memberService = useMemberService();
interface AddPromotionFormData {
  sTitle: string;
  nRank: string;
  TogetherPer: string;
  ActGame: string;
  GameType1: string;
  Baccarat: string;
  APIGame: string;
  GameType2: string;
}
const defaultData = ref<AddPromotionFormData>({
  sTitle: "",
  nRank: "0",
  TogetherPer: "0",
  GameType1: "0",
  ActGame: "0",
  Baccarat: "0",
  APIGame: "0",
  GameType2: "0",
});

// 反水設定
// const rebateSetting = ref<boolean>(false);
// function toggleRebateSetting() {
//   rebateSetting.value = !rebateSetting.value;
// }
// watch(rebateSetting, (nVal) => {
//   if (nVal) {
//     defaultData.value.GameType1 = memberData.value.aFs
//       ? memberData.value.aFs.GameType1
//       : "0";
//     defaultData.value.ActGame = memberData.value.aFs
//       ? memberData.value.aFs.ActGame
//       : "0";
//     defaultData.value.Baccarat = memberData.value.aFs
//       ? memberData.value.aFs.Baccarat
//       : "0";
//     defaultData.value.APIGame = memberData.value.aFs
//       ? memberData.value.aFs.APIGame
//       : "0";
//     defaultData.value.GameType2 = memberData.value.aFs
//       ? memberData.value.aFs.GameType2
//       : "0";
//   } else {
//     defaultData.value.GameType1 = "0";
//     defaultData.value.ActGame = "0";
//     defaultData.value.Baccarat = "0";
//     defaultData.value.APIGame = "0";
//     defaultData.value.GameType2 = "0";
//   }
// });

// 反水值
const rebateSetting = ref<number>(0);
const rebatePer = computed(() => {
  let max = 0;
  if (!memberData.value) return 0;
  const gameType1Value = parseFloat(memberData.value.aFs.GameType1);
  const baccaratValue = parseFloat(memberData.value.aFs.Baccarat);
  const apiGameValue = parseFloat(memberData.value.aFs.APIGame);
  const gameType2Value = parseFloat(memberData.value.aFs.GameType2);
  max = Math.max(gameType1Value, baccaratValue, apiGameValue, gameType2Value);
  if (!max) return 0;
  return max * 100;
});
watch(rebateSetting, (nVal) => {
  if (nVal) {
    defaultData.value.GameType1 = nVal.toString();
    defaultData.value.ActGame = "0";
    defaultData.value.Baccarat = nVal.toString();
    defaultData.value.APIGame = nVal.toString();
    defaultData.value.GameType2 = nVal.toString();
  } else {
    defaultData.value.GameType1 = "0";
    defaultData.value.ActGame = "0";
    defaultData.value.Baccarat = "0";
    defaultData.value.APIGame = "0";
    defaultData.value.GameType2 = "0";
  }
});

// 切換 會員 代理
watchEffect(() => {
  if (defaultData.value.nRank === "0") {
    defaultData.value.TogetherPer = "0";
  }
});

async function submit(VForm: AddPromotionFormData) {
  // console.log(VForm);
  await memberService.addPromotion(VForm);
  defaultData.value.sTitle = "";
}
</script>

<template>
  <div class="sticky top-[40%] -translate-y-1/2 mx-auto w-[95vw] md:w-[500px]">
    <VForm
      @submit="submit"
      v-slot="{ meta, isSubmitting }"
      ref="loginFrom"
      class="relative bg-[url('@image/memberFunction/08/detail_board.png')] bg-100 px-[3vw]">
      <h3
        class="absolute left-1/2 -translate-x-1/2 text-center text-secondary text-[20px] xl:text-[24px] font-bold"
        :class="{
          'top-[22px]': defaultData.nRank !== '0',
          'top-[14px]': defaultData.nRank === '0',
        }">
        {{ $t("MemberFunction.Promote.AddPromoteLink") }}
      </h3>
      <div
        class="p-[3em] w-full h-full top-0 bg-100 text-primary flex flex-col text-[14px] md:text-[18px]">
        <label
          :for="$t('MemberFunction.Promote.NameSetting')"
          class="grid grid-cols-8 mt-[60px]">
          <p class="mr-[1em] col-span-3">
            {{ $t("MemberFunction.Promote.NameSetting") }}
          </p>
          <div class="flex flex-col items-end col-span-5">
            <div class="relative w-full">
              <img
                v-img-src="文字框_長_A"
                alt=""
                class="h-[26px] absolute w-full" />
              <VField
                class="rounded-md px-[10px] z-10 relative h-[26px] w-full bg-transparent"
                id="sTitle"
                :label="$t('MemberFunction.Promote.NameSetting')"
                name="sTitle"
                type="text"
                :rules="{ required: true }"
                v-model="defaultData.sTitle" />
            </div>
            <ErrorMessage
              name="sTitle"
              class="text-red-400 text-[12px] text-end" />
          </div>
        </label>
        <label
          :for="$t('MemberFunction.Promote.RankSetting')"
          class="grid grid-cols-8 mt-[24px]">
          <p class="mr-[1em] col-span-3 text-primary">
            {{ $t("MemberFunction.Promote.RankSetting") }}
          </p>
          <div class="col-span-5 relative inline-block w-full">
            <div class="">
              <img
                v-img-src="triangle_icon"
                alt=""
                class="absolute top-1/2 -translate-y-1/2 right-[2.5%] pointer-events-none h-[10px] z-20" />
              <VField
                id="nRank"
                :label="$t('MemberFunction.Promote.RankSetting')"
                name="nRank"
                v-slot="{ field }"
                v-model="defaultData.nRank"
                class="relative">
                <select
                  v-bind="field"
                  class="absolute select-bg-border top-0 bg-transparent px-2 rounded w-full h-[26px] text-primary">
                  <option class="text-black" value="0">
                    {{ $t("MemberFunction.Promote.Member") }}
                  </option>
                  <!-- <option class="text-black" value="1">
                    {{ $t("MemberFunction.Promote.Proxy") }}
                  </option> -->
                </select>
              </VField>
            </div>
            <ErrorMessage
              name="nRank"
              class="text-primary text-[12px] text-center" />
          </div>
        </label>
        <!-- <label for="反水設定" class="grid grid-cols-8 mt-[24px]">
          <p class="mr-[1em] self-center col-span-3">反水設定</p>
          <div class="col-span-5">
            <div class="relative h-[26px]" @click="toggleRebateSetting">
              <button type="button" class="relative">
                <img
                  v-img-src="反水底"
                  alt=""
                  class="h-[32px]"
                />
                <div class="absolute top-0">
                  <div class="relative">
                    <img
                      v-img-src="反水btn"
                      alt=""
                      class="h-[30px] scale-[90%] translate-y-[0.5px] -translate-x-[1px] origin-center"
                      :class="{
                        'opacity-0': rebateSetting === false,
                      }"
                    />
                    <p
                      class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[11px] font-bold"
                      :class="{
                        'text-secondary': rebateSetting === true,
                      }"
                    >
                      全退
                    </p>
                  </div>
                </div>
                <div class="absolute top-0 right-0">
                  <div class="relative">
                    <img
                      v-img-src="反水btn"
                      alt=""
                      class="h-[30px] scale-[90%] translate-y-[0.5px] translate-x-[1px] origin-center"
                      :class="{
                        'opacity-0': rebateSetting === true,
                      }"
                    />
                    <p
                      class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-[11px] font-bold"
                      :class="{
                        'text-secondary': rebateSetting === false,
                      }"
                    >
                      全不退
                    </p>
                  </div>
                </div>
              </button>
              <input
                name="rebateSetting"
                label="反水設定"
                type="checkbox"
                class="hidden"
                v-model="rebateSetting"
              />
            </div>
            <ErrorMessage
              name="rebateSetting"
              class="text-primary text-[12px] text-center"
            />
          </div>
        </label> -->
        <label for="反水值" class="grid grid-cols-8 mt-[24px]" v-show="false">
          <div class="mr-[1em] col-span-3">
            <p>反水值</p>
          </div>
          <div class="relative col-span-5 h-[26px] self-center">
            <img v-img-src="文字框_長_A" alt="" class="h-[26px]" />
            <select
              class="px-[12px] absolute top-0 left-0 bg-transparent w-full"
              v-if="memberData.aFs"
              v-model="rebateSetting">
              <option class="text-black" :value="0">0</option>
              <option
                v-for="count in rebatePer"
                :key="'TogetherPer' + count"
                :value="count / 100"
                class="text-secondary">
                {{ count / 100 }}
              </option>
            </select>
          </div>
          <ErrorMessage
            name="TogetherPer"
            class="text-primary text-[12px] text-center" />
        </label>
        <label
          for="佔成"
          class="grid grid-cols-8 mt-[24px]"
          v-show="defaultData.nRank !== '0'">
          <div class="mr-[1em] col-span-3">
            <p>佔成</p>
          </div>
          <div class="relative col-span-5 h-[26px] self-center">
            <img v-img-src="文字框_長_A" alt="" class="h-[26px]" />
            <VField
              id="TogetherPer"
              label="佔成"
              name="TogetherPer"
              type="number"
              v-slot="{ field }"
              v-model="defaultData.TogetherPer">
              <select
                v-bind="field"
                class="px-[12px] absolute top-0 left-0 bg-transparent w-full"
                v-if="memberData.aFs">
                <template v-if="memberData.aFs.TogetherPer">
                  <option class="text-black" :value="0">0</option>
                  <option
                    v-for="count in parseInt(memberData.aFs.TogetherPer)"
                    :key="'TogetherPer' + count"
                    :value="count"
                    class="text-secondary">
                    {{ count }}
                  </option>
                </template>
                <template v-else>
                  <option class="text-black" :value="0">0</option>
                </template>
              </select>
            </VField>
            <p
              class="absolute text-primary right-[5%] top-1/2 -translate-y-1/2">
              %
            </p>
          </div>
          <ErrorMessage
            name="TogetherPer"
            class="text-primary text-[12px] text-center" />
        </label>
        <label for="Act遊戲" class="hidden">
          <div class="mr-[1em] col-span-3">
            <p
              class="relative after:absolute after:content-['|'] after:w-full after:h-full after:text-primary after:-left-1/4 after:top-1/2 after:translate-y-1/2 after:translate-x-1/2">
              Act遊戲
            </p>
            <br />
            <p>Act遊戲</p>
          </div>
          <div class="relative col-span-5 h-[26px] self-center">
            <img v-img-src="文字框_長_A" alt="" class="h-[26px]" />
            <VField
              id="ActGame"
              label="Act遊戲"
              name="ActGame"
              type="number"
              v-slot="{ field }"
              v-model="defaultData.ActGame">
              <select
                v-bind="field"
                class="px-[12px] absolute top-0 left-0 bg-transparent w-full">
                <option class="text-black" :value="0">0</option>
                <option
                  v-for="count in 99"
                  :key="'ActGame' + count"
                  :value="store.getClearPot(count * 0.01, 2)"
                  class="text-black">
                  {{ store.getClearPot(count * 0.01, 2) }}
                </option>
                <option :value="1">1</option>
              </select>
            </VField>
            <p
              class="absolute text-primary right-[5%] top-1/2 -translate-y-1/2">
              %
            </p>
          </div>
          <ErrorMessage
            name="ActGame"
            class="text-primary text-[12px] text-center" />
        </label>
        <label for="博帝彩票" class="hidden">
          <p class="mr-[1em]">博帝彩票-博帝彩票 返點</p>
          <div class="relative">
            <img v-img-src="文字框_長_A" alt="" class="opacity-0" />
            <VField
              id="GameType1"
              label="博帝彩票"
              name="GameType1"
              type="number"
              v-slot="{ field }"
              v-model="defaultData.GameType1">
              <select
                v-bind="field"
                class="px-[12px] text-secondary absolute top-0 left-0 w-[80%] h-[80%] bg-cover bg-center rounded-[10px] bg-[url('@image/memberFunction/文字框/文字框_長_A.png')]">
                <option :value="0">0</option>
                <option
                  v-for="count in 99"
                  :key="'GameType1' + count"
                  :value="store.getClearPot(count * 0.01, 2)">
                  {{ store.getClearPot(count * 0.01, 2) }}
                </option>
                <option :value="1">1</option>
              </select>
            </VField>
            <p class="absolute text-primary right-[25%] top-[15%]">%</p>
          </div>
          <ErrorMessage
            name="GameType1"
            class="text-primary text-[12px] text-center" />
        </label>
        <label for="博帝彩票2" class="hidden">
          <p class="mr-[1em]">博帝彩票2-博帝彩票2 返點</p>
          <div class="relative">
            <img v-img-src="文字框_長_A" alt="" class="opacity-0" />
            <VField
              id="GameType2"
              label="博帝彩票2"
              name="GameType2"
              type="number"
              v-slot="{ field }"
              v-model="defaultData.GameType2">
              <select
                v-bind="field"
                class="px-[12px] text-secondary absolute top-0 left-0 w-[80%] h-[80%] bg-cover bg-center rounded-[10px] bg-[url('@image/memberFunction/文字框/文字框_長_A.png')]">
                <option :value="0">0</option>
                <option
                  v-for="count in 99"
                  :key="'GameType2' + count"
                  :value="store.getClearPot(count * 0.01, 2)">
                  {{ store.getClearPot(count * 0.01, 2) }}
                </option>
                <option :value="1">1</option>
              </select>
            </VField>
            <p class="absolute text-primary right-[25%] top-[15%]">%</p>
          </div>
          <ErrorMessage
            name="GameType2"
            class="text-primary text-[12px] text-center" />
        </label>
        <label for="外接" class="hidden">
          <p class="mr-[1em]">外接-外接 返點</p>
          <div class="relative">
            <img v-img-src="文字框_長_A" alt="" class="opacity-0" />
            <VField
              class="p-[12px] absolute top-0 left-0 w-[80%] h-[80%] bg-cover bg-center rounded-[10px] bg-[url('@image/memberFunction/文字框/文字框_長_A.png')]"
              id="APIGame"
              label="外接"
              name="APIGame"
              type="number"
              v-slot="{ field }"
              v-model="defaultData.APIGame">
              <select
                v-bind="field"
                class="px-[12px] text-secondary absolute top-0 left-0 w-[80%] h-[80%] bg-cover bg-center rounded-[10px] bg-[url('@image/memberFunction/文字框/文字框_長_A.png')]">
                <option :value="0">0</option>
                <option
                  v-for="count in 99"
                  :key="'APIGame' + count"
                  :value="store.getClearPot(count * 0.01, 2)">
                  {{ store.getClearPot(count * 0.01, 2) }}
                </option>
                <option :value="1">1</option>
              </select>
            </VField>
            <p class="absolute text-primary right-[25%] top-[15%]">%</p>
          </div>
          <ErrorMessage
            name="APIGame"
            class="text-primary text-[12px] text-center" />
        </label>
        <label for="真人百家" class="hidden">
          <p class="mr-[1em]">真人百家-真人百家 返點</p>
          <div class="relative inline-block">
            <div class="h-[26px] w-full"></div>
            <img
              src="@image/memberFunction/common/triangle_icon.png"
              alt=""
              class="absolute top-0 right-0 pointer-events-none h-[26px]" />
            <VField
              class="p-[12px] absolute top-0 left-0 w-[80%] h-[80%] bg-cover bg-center rounded-[10px] bg-[url('@image/memberFunction/文字框/文字框_長_A.png')]"
              id="Baccarat"
              label="真人百家"
              name="Baccarat"
              type="number"
              v-slot="{ field }"
              v-model="defaultData.Baccarat">
              <select
                v-bind="field"
                class="absolute select-bg-border top-0 bg-transparent px-2 rounded w-full h-[26px] text-primary">
                <option :value="0">0</option>
                <option
                  v-for="count in 99"
                  :key="'Baccarat' + count"
                  :value="store.getClearPot(count * 0.01, 2)">
                  {{ store.getClearPot(count * 0.01, 2) }}
                </option>
                <option :value="1">1</option>
              </select>
            </VField>
            <p class="absolute text-primary right-[25%] top-[15%]">%</p>
          </div>
          <ErrorMessage
            name="Baccarat"
            class="text-primary text-[12px] text-center" />
        </label>

        <div class="mt-[60px] md:mt-[5vh] flex items-center justify-center">
          <button
            type="submit"
            class="btn-y-border"
            :class="{ submitting: isSubmitting }"
            :disabled="isSubmitting">
            <p
              class="text-[12px] md:text-[16px] text-secondary px-[2em] py-[.2em]">
              {{ isSubmitting ? "" : $t("MemberFunction.Promote.Next") }}
            </p>
          </button>
        </div>
      </div>
    </VForm>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
</style>
