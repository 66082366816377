<script setup lang="ts">
import { onMounted, onUnmounted, computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import type { RouteRecordName } from "vue-router";
import * as APItypes from "@/assets/API/APItype";
// import { useI18n } from "vue-i18n/dist/vue-i18n.js";
import HeaderLoginWeb from "@/components/header/headerLogin/headerLoginWeb.vue";
import HeaderInfoWeb from "@/components/header/headerLogin/headerInfoWeb.vue";
import HeaderLanguage from "@/components/header/headerLanguage.vue";
import HeaderSwiper from "@/components/header/headerSwiper.vue";
import BgSoundControl from "@/components/bgSoundControl.vue";

import icvlogo_01 from "@image/memberFunction/01/icvlogo_01.gif";
import 跳轉按鈕 from "@image/memberFunction/common/跳轉按鈕.png";
import on_bg from "@image/memberFunction/bg_audio/on_bg.png";
import off_bg from "@image/memberFunction/bg_audio/off_bg.png";
import btnImage from "@image/memberFunction/bg_audio/btn.png";

import { useErrorAlert } from "@/stores/errorAlert";
import { useLogin } from "@/stores/login";
import { useContentInfo } from "@/stores/contentInfo";
import { useCounterStore } from "@/stores/counter";
import { useBgAudio } from "@/stores/bgAudio";
const { t } = useI18n();
const errorAlert = useErrorAlert();
const login = useLogin();
const contentInfoStore = useContentInfo();
const store = useCounterStore();
const bgAudio = useBgAudio();
const { contentInfo, gamePlatformList, BgMusic } =
  storeToRefs(contentInfoStore);
const { memberData, isLoadingLogin, isCashMember, isLogin } =
  storeToRefs(login);

const router = useRouter();
const route = useRoute();
interface HeaderList {
  data: APItypes.AGameKind;
  isSelect: boolean;
}

const HeaderName = computed(() => {
  return route.name;
});

const headerLists = ref<HeaderList[]>([]);

watch(contentInfo, (nVal, oVal) => {
  const aGameKind = nVal.aGameKind;
  const watchLists = aGameKind.map((item) => {
    return {
      data: Object.values(item)[0],
      isSelect: false,
    };
  });
  headerLists.value = watchLists;
  // console.log(headerLists.value, "watch");
});
function clickList(getIndex: number) {
  headerLists.value.forEach((item: HeaderList, index: number) => {
    if (getIndex === index) {
      item.isSelect = !item.isSelect;
    } else {
      item.isSelect = false;
    }
  });
}
function hiddenSwiper() {
  headerLists.value.forEach((item: HeaderList) => {
    item.isSelect = false;
  });
}
const isShowHeaderSwiper = computed(() => {
  let state = headerLists.value.some((item) => item.isSelect);
  // console.log(state, "isShowHeaderSwiper");
  return state;
});

const SwiperType = computed(() => {
  let state = headerLists.value.find((item) => item.isSelect)?.data.nBKid;
  return state;
});

function toMainPath() {
  router.push({ name: "main" });
}

const myPath = ref<RouteRecordName | null | undefined>("");
const myPathName = ref<RouteRecordName | null | undefined>("");
const windowSize = ref<number>(0);
const isAPPMemberPath = computed(() => {
  if (!myPath.value) return false;
  const isAppSize = windowSize.value <= 860;
  const isMemberPath = myPath.value.toString().includes("member");
  if (isAppSize && isMemberPath) {
    return true;
  } else {
    return false;
  }
});
function checkPath(
  path: RouteRecordName | null | undefined,
  name: RouteRecordName | null | undefined
) {
  myPath.value = path;
  myPathName.value = name;
  windowSize.value = window.innerWidth;
}
const pathName = computed(() => {
  let name;
  switch (myPathName.value) {
    case "MemberAccount":
      name = t("Header.MemberAccount");
      break;
    case "MemberManagement":
      name = t("MemberFunction.MemberAccount.MemberManagement");
      break;
    case "MemberList":
      name = t("Header.MemberList");
      break;
    case "TakeOverCoin":
      name = t("Header.TakeOverCoin");
      break;
    case "BetRecord":
      name = t("Header.BetRecord");
      break;
    case "MemberMail":
      name = t("Header.MemberMail");
      break;
    case "TradeRecord":
      name = t("Header.TradeRecord");
      break;
    case "PromoteLink":
      name = t("Header.PromoteLink");
      break;
    case "WithdrawPage":
      name = t("Header.Withdraw");
      break;
    case "DepositPage":
      name = t("Header.Deposit");
      break;
    case "VIPPrivilege":
      name = t("Header.VIPPrivilege");
      break;
    case "NewMessage":
      name = t("Header.NewMessage");
      break;
    case "ShareMakeFortunePage":
      name = t("Header.ShareMakeFortunePage");
      break;
    case "MemberAddBank":
      name = t("MemberFunction.AddBank.AddBankCard");
      break;
    case "MemberBankPage":
      name = t("MemberFunction.BankPage.BankCard");
      break;
    case "BankVerified":
      name = t("MemberFunction.BankVerified.Verified");
      break;
    case "GameLobby":
      name = route.params.GameName;
      break;
    default:
      name = "";
      break;
  }
  return name;
});
function toHistory() {
  router.back();
}
function toDiscount() {
  if (!login.isLogin) return router.push({ name: "login" });
  // if (!isCashMember.value)
  //   return errorAlert.AddErrorAlert("您的帳號無法使用此優惠");
  return router.push({ name: "Discount" });
}

function reSizeEvent() {
  checkPath(route.path, route.name);
}
onBeforeRouteUpdate((to, from, next) => {
  // console.log({ from, to });
  next();
  checkPath(to.fullPath, to.name);
}),
  onMounted(() => {
    reSizeEvent();
    // window.addEventListener("resize", reSizeEvent);
    router.beforeEach(() => {
      hiddenSwiper();
    });
  });
onUnmounted(() => {
  // window.removeEventListener("resize", reSizeEvent);
});

// 背景音樂
function changeMusicState() {
  bgAudio.changeMusicState();
}
</script>

<template>
  <header
    v-if="!isAPPMemberPath"
    class="sticky top-0 z-50 w-full header-neon-border md:h-auto h-[5.5dvh] flex justify-between items-center p-[1em] md:py-[.5em] py-0">
    <!-- 左邊 -->
    <div class="flex justify-start items-center">
      <button @click="toMainPath">
        <img
          v-img-src="icvlogo_01"
          alt=""
          class="absolute lg:static top-0 lg:h-[5dvh] h-[calc(7.8dvh)] mr-0 lg:mr-[1.3em]"
          :class="{ 'mr-0 lg:mr-[1.3em]': login.isLogin }" />
      </button>

      <ul class="text-[14px] xl:text-[18px] hidden lg:flex">
        <li
          class="mr-[1em] lg:mr-[1.5em]"
          v-for="(item, index) in headerLists"
          :key="item.data.nBKid + index">
          <button
            class="group relative font-bold"
            :class="
              item.isSelect
                ? 'text-yellow-300'
                : 'text-primary hover:text-yellow-300'
            "
            @click="clickList(index)">
            {{ item.data.sTitle0 }}
            <span
              class="absolute -bottom-[2px] rounded left-1/2 -translate-x-1/2 h-[3px] w-full bg-yellow-100 group-hover:block"
              :class="item.isSelect ? 'block' : 'hidden'"></span>
          </button>
        </li>
        <li class="mr-[1em] lg:mr-[1.5em]">
          <button
            class="group relative font-bold text-primary hover:text-yellow-300"
            @click="toDiscount()">
            {{ $t("Footer.DiscountPage") }}
            <span
              class="absolute -bottom-[2px] rounded left-1/2 -translate-x-1/2 h-[3px] w-full bg-yellow-100 group-hover:block hidden"></span>
          </button>
        </li>
      </ul>
    </div>
    <!-- 右邊 -->
    <div class="flex justify-center items-center">
      <div
        class="web-top-people-neon-border text-primary text-[14px] justify-center items-center xl:flex hidden mr-[1vw]">
        <p class="px-[1vw] py-[.2dvh]">
          <span class="text-[#ffdf7d]">
            {{ $t("Header.OnlineUsers") }}:
            {{ store.addCommas(login.userSum) }}</span
          >
        </p>
      </div>
      <div class="hidden lg:block" v-if="!isLoadingLogin">
        <HeaderInfoWeb v-if="login.isLogin" />
        <HeaderLoginWeb class="mr-[1em]" v-else />
      </div>
      <div
        class="app-top-people-neon-border text-primary text-[12px] flex justify-center items-center px-[3vw] py-[.2dvh] mr-1 lg:hidden">
        <p>
          <span class="text-[#ffdf7d]"> {{ $t("Header.OnlineUsers") }}:</span>
          {{ store.addCommas(login.userSum) }}
        </p>
      </div>
      <BgSoundControl class="mr-[.5vw]" />
      <HeaderLanguage />
    </div>
    <!-- 點擊navbar後頁面 -->
    <div
      v-show="isShowHeaderSwiper"
      class="absolute left-0 top-[100%] bg-[url('@image/memberFunction/02_web/alert_bg.png')] bg-cover bg-center w-full px-[5em] py-[2em] hidden lg:block">
      <HeaderSwiper :SwiperType="SwiperType" />
    </div>
    <div
      class="w-full h-[3px] absolute bottom-0 left-1/2 -translate-x-1/2 md:block hidden"></div>
  </header>
  <header
    v-else
    class="sticky z-50 w-full header-neon-border h-auto lg:h-[10dvh] flex justify-between items-center px-[1em] py-[1dvh]"
    :class="{
      'header-neon-border':
        HeaderName !== 'MemberAccount' && HeaderName !== 'VIPPrivilege',
      'header-neon-border_second':
        HeaderName === 'MemberAccount' || HeaderName === 'VIPPrivilege',
    }">
    <button @click="toHistory">
      <img v-img-src="跳轉按鈕" class="h-[16px]" alt="" />
    </button>
    <p
      class="absolute left-1/2 -translate-x-1/2 text-primary text-[16px] font-bold whitespace-nowrap">
      {{ pathName }}
    </p>
    <button class="relative" @click="changeMusicState" v-if="BgMusic">
      <img
        v-img-src="on_bg"
        class="object-contain h-[3dvh]"
        alt=""
        v-if="bgAudio.musicState" />
      <img v-img-src="off_bg" class="object-contain h-[3dvh]" alt="" v-else />
      <div
        class="absolute top-1/2 -translate-y-1/2 z-10 h-[2.3vh] w-full px-[1vw]">
        <Transition
          enter-active-class="delay-[300ms]"
          enter-from-class="transform opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="duration-300 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="transform opacity-100 translate-x-[50%]">
          <div class="w-full h-full absolute" v-if="!bgAudio.musicState">
            <img
              v-img-src="btnImage"
              class="object-contain h-full right-0"
              alt="" />
          </div>
        </Transition>
        <Transition
          enter-active-class="delay-[300ms]"
          enter-from-class="transform opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="duration-300 "
          leave-from-class="opacity-100  translate-x-0"
          leave-to-class="transform opacity-100 translate-x-[-50%]">
          <div
            class="w-full h-full top-0 translate-y-0"
            v-if="bgAudio.musicState">
            <img
              v-img-src="btnImage"
              class="object-contain h-full absolute right-0"
              alt="" />
          </div>
        </Transition>
      </div>
    </button>
  </header>
</template>

<style>
.scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.scrollbar::-webkit-scrollbar-button {
  display: none;
}
.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.4);
}
.header-neon-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/01/app_top_bg_main.png");
  -webkit-border-image: url("@image/memberFunction/01/app_top_bg_main.png");
  -o-border-image: url("@image/memberFunction/01/app_top_bg_main.png");
  border-image: url("@image/memberFunction/01/app_top_bg_main.png") 1 fill;
}
.header-neon-border_second {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/01/app_top_bg_second.png");
  -webkit-border-image: url("@image/memberFunction/01/app_top_bg_second.png");
  -o-border-image: url("@image/memberFunction/01/app_top_bg_second.png");
  border-image: url("@image/memberFunction/01/app_top_bg_second.png") 1 fill;
}
@media screen and (min-width: 768px) {
  .header-neon-border {
    border-style: solid;
    border-width: 1px;
    -moz-border-image: url("@image/memberFunction/01/web_top_bg.png");
    -webkit-border-image: url("@image/memberFunction/01/web_top_bg.png");
    -o-border-image: url("@image/memberFunction/01/web_top_bg.png");
    border-image: url("@image/memberFunction/01/web_top_bg.png") 1 fill;
  }
}
@media screen and (min-width: 768px) {
  .header-neon-border_second {
    border-style: solid;
    border-width: 1px;
    -moz-border-image: url("@image/memberFunction/01/web_top_bg.png");
    -webkit-border-image: url("@image/memberFunction/01/web_top_bg.png");
    -o-border-image: url("@image/memberFunction/01/web_top_bg.png");
    border-image: url("@image/memberFunction/01/web_top_bg.png") 1 fill;
  }
}
.app-top-people-neon-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/01/app_top_people_bg.png") 1;
  -webkit-border-image: url("@image/memberFunction/01/app_top_people_bg.png") 1;
  -o-border-image: url("@image/memberFunction/01/app_top_people_bg.png") 1;
  border-image: url("@image/memberFunction/01/app_top_people_bg.png") 1 fill;
}
.web-top-people-neon-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/01/web_top_people_bg.png") 1;
  -webkit-border-image: url("@image/memberFunction/01/web_top_people_bg.png") 1;
  -o-border-image: url("@image/memberFunction/01/web_top_people_bg.png") 1;
  border-image: url("@image/memberFunction/01/web_top_people_bg.png") 1 fill;
}
</style>
