<script setup lang="ts">
import { computed } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper/modules";
import "swiper/css/bundle";
import "swiper/css";
import { useContentInfo } from "@/stores/contentInfo";
import * as APItypes from "@/assets/API/APItype";

import logo_bg from "@image/memberFunction/02_web/logo_bg.png";

interface List {
  [key: string]: APItypes.IndexInfoGameDataListInfo;
}
const modules = [Pagination];

const props = defineProps({
  SwiperType: String,
});

const contentInfoStore = useContentInfo();

const SwiperList = computed(() => {
  if (!props.SwiperType) return [] as List[];
  return contentInfoStore.getContentListInfo(props.SwiperType);
});

function goUrl(itemInfo: APItypes.IndexInfoGameDataListInfo) {
  contentInfoStore.goUrl(itemInfo);
}
</script>

<template>
  <div class="relative">
    <Swiper
      :slides-per-view="6"
      :space-between="50"
      :modules="modules"
      :pagination="{
        clickable: true,
        clickableClass: 'header-swiper-pagination',
        renderBullet: (index, className) => {
          return `<span class='swiper-pagination-bullet yyy'></span>`;
        },
      }">
      <SwiperSlide
        v-for="item in SwiperList"
        :key="'headerSwiper' + Object.values(item)[0].nId">
        <button
          class="relative m-2"
          :class="{ grayscale: Object.values(item)[0].nActive === '0' }"
          :disabled="Object.values(item)[0].nActive === '0'"
          @click="goUrl(Object.values(item)[0])">
          <div
            class="flex justify-center items-center text-primary text-[16px] xl:text-[20px]">
            <div class="relative mr-[.5vw]">
              <img
                :src="Object.values(item)[0].sImgIcon"
                alt=""
                class="object-cover absolute w-[80%] top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2" />
              <img
                v-img-src="logo_bg"
                alt=""
                class="object-cover w-[45px] xl:w-[60px]" />
            </div>
            <!-- <img :src="item.sImgIcon" alt="" /> -->
            <p class="font-bold">{{ Object.values(item)[0].sTitle0 }}</p>
          </div>
          <img
            :src="Object.values(item)[0].sImgUrl"
            alt=""
            class="object-contain mx-auto w-full" />
          <div
            class="btn-y-border relative left-1/2 -translate-x-1/2 mt-[1dvh] w-[5em] xl:w-[7em]">
            <p
              class="text-center text-secondary text-[12px] xl:text-[14px] font-bold py-1">
              {{ $t("Header.ToGame") }}
            </p>
          </div>
          <p
            class="text-primary xl:text-[24px] text-[18px] absolute top-1/2 left-1/2 -translate-x-1/2 font-bold"
            v-if="Object.values(item)[0].nActive === '0'">
            {{ $t("Header.InMaintenance") }}
          </p>
        </button>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<style>
/* 自訂 pagination 樣式 */
.header-swiper-pagination {
  position: relative !important;
  bottom: 0 !important;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-pagination-bullet {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
  border: 1px solid white;
}

.swiper-pagination-bullet {
  width: 6px !important;
  height: 6px !important;

  opacity: 0.5 !important;
  border-radius: 50%;
  margin: 0 6px !important;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: rgb(247, 247, 247) !important;
}

.swiper-pagination-bullet-active {
  opacity: 1 !important;
  border-radius: 12px !important;
  border: 0px solid white;
  background-color: white !important;
}
</style>
