<script setup lang="ts">
import { useMemberService } from "@/stores/memberFunction/memberService";
import twofourhour from "@image/memberFunction/04/24hour.png";
import cs_photo from "@image/memberFunction/04/cs_photo.png";
import cs_add_bg from "@image/memberFunction/04/cs_add_bg.png";
import line_icon from "@image/memberFunction/04/line_icon.png";

const memberService = useMemberService();
function ToLineLink() {
  memberService.getWebTalkLineService();
}
</script>

<template>
  <div class="p-5 text-[#fff] mb-5">
    <button type="button">
      <img v-img-src="twofourhour" alt="" class="w-[60%]" />
    </button>
    <div class="flex justify-center items-center mb-5">
      <img v-img-src="cs_photo" alt="" />
    </div>

    <!-- 1 -->
    <div class="relative flex justify-around items-center mb-5">
      <img v-img-src="cs_add_bg" alt="" />
      <div
        class="absolute flex justify-evenly gap-x-10 items-center text-[16px] w-[90%] text-[#fff] leading-5">
        <div class="flex justify-around items-center gap-5">
          <img class="w-[35px]" v-img-src="line_icon" alt="" />
          {{ $t("Context.Service.LineService") }}
        </div>
        <button
          class="flex justify-center items-center btn-y-border w-[120px] h-[36px]"
          @click="ToLineLink">
          <div class="text-[16px] text-secondary">
            {{ $t("Context.Service.Join") }}
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<style></style>
