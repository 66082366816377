<script setup lang="ts">
import { onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useMemberService } from "@/stores/memberFunction/memberService";
import search_bg_head from "@image/memberFunction/03/search_bg_head.png";
import no_file from "@image/memberFunction/文字框/no_file.png";
const memberService = useMemberService();
const { ShareList, ShareListCount } = storeToRefs(memberService);

onMounted(() => {
  memberService.toShareRecord();
});
</script>
<template>
  <div>
    <div>
      <p
        class="text-primary text-[14px] md:text-[18px] mt-[12px] px-0 md:px-[1vw]">
        {{ $t("MemberFunction.ShareMakeFortune.FriendCount") }}
        <span class="ml-[2vw]">
          {{ ShareListCount }}
        </span>
      </p>
      <!-- <p class="text-primary text-[16px] mt-[12px] px-1">{{ ShareListCount }}</p> -->
      <div class="h-[1px] my-[1dvh] bg-white md:h-0"></div>
    </div>

    <div class="relative m-3 md:m-0">
      <div
        class="absolute flex justify-between items-center text-primary text-[12px] md:text-[16px] w-full px-2 md:px-[1vw] top-1 md:top-[2dvh]">
        <p>{{ $t("MemberFunction.ShareMakeFortune.MemberAccount") }}</p>
        <p>{{ $t("MemberFunction.ShareMakeFortune.RegistrationDate") }}</p>
      </div>
      <div class="">
        <img v-img-src="search_bg_head" alt="" class="" />
        <div
          class="bg-black h-[350px] w-full overflow-y-auto px-2 md:px-[1vw] rounded-none md:rounded-b-[12px]">
          <p
            class="text-white/80 text-[12px] md:text-[16px] absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2"
            v-if="ShareListCount <= 0">
            <img v-img-src="no_file" alt="" class="w-[35px] mx-auto" />
            {{ $t("MemberFunction.ShareMakeFortune.NoData") }}
          </p>
          <table
            class="w-full text-primary/80 text-[12px] md:text-[16px]"
            v-else>
            <tbody class="">
              <tr
                v-for="(value, index) in ShareList"
                :key="'shareList' + value.sRegisterDate + index"
                class="border-b last:border-0 border-white/30">
                <td class="text-start w-[50%] h-[40px]">
                  {{ value.sAccount }}
                </td>
                <td class="text-end w-[50%] h-[40px]">
                  {{ value.sRegisterDate }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
