<script setup lang="ts">
import { ref } from "vue";
import { storeToRefs } from "pinia";

import { useMemberService } from "@/stores/memberFunction/memberService";

import OnlineDeposit from "@/components/menberFunction/depositPage/onlineDeposit.vue";
import CompanyDeposit from "@/components/menberFunction/depositPage/companyDeposit.vue";
import UploadDeposit from "@/components/menberFunction/depositPage/uploadDeposit.vue";
import USDTWithdraw from "@/components/menberFunction/withdrawPage/USDTwithdraw.vue";
import BankWithdraw from "@/components/menberFunction/withdrawPage/BankWithdraw.vue";

import RecordWithdraw from "@/components/menberFunction/withdrawPage/RecordWithdraw.vue";

import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";

import top_btn_p from "@image/memberFunction/common/top_btn_p.png";
import top_btn from "@image/memberFunction/common/top_btn.png";

const memberService = useMemberService();
const { MemberCompanyDepositMsg, MemberRecharge } = storeToRefs(memberService);

const showWithdrawPage = ref<number>(1);
function changeWithdrawPage(page: number) {
  showWithdrawPage.value = page;
  MemberCompanyDepositMsg.value = "";
  if (page === 1) {
    memberService.toWithdrawPage();
  }
  if (page === 2) {
    memberService.toUSDTWithdrawPage();
  }
}
</script>

<template>
  <div
    class="md:grid grid-cols-12 gap-x-[3vw] block gap-y-[1dvh] px-[1vw] pb-[140px] pt-0 md:pt-[3rem]">
    <SidebarWeb class="col-span-3 hidden md:block mb-[7dvh]" />
    <div class="col-span-9 relative">
      <div
        class="px-0 pt-0 pb-0 md:px-[5vw] md:pt-[2dvh] md:pb-[4dvh] relative z-10 web-gold-border rounded-[24px]">
        <p class="mt-[1dvh] text-[24px] text-primary font-bold hidden md:block">
          {{ $t("MemberFunction.Withdraw.Withdraw") }}
        </p>
        <div class="flex justify-center items-center mt-5 mx-3">
          <button
            class="group relative flex justify-center items-center mr-[1vw]"
            @click="changeWithdrawPage(1)"
            :class="{
              'text-secondary': showWithdrawPage === 1,
              'text-primary hover:text-secondary': showWithdrawPage !== 1,
            }">
            <img
              class="w-[100px]"
              v-img-src="top_btn_p"
              alt=""
              :class="{
                block: showWithdrawPage === 1,
                'hidden group-hover:block': showWithdrawPage !== 1,
              }" />
            <img
              class="w-[100px]"
              v-img-src="top_btn"
              alt=""
              :class="{
                hidden: showWithdrawPage === 1,
                'block group-hover:hidden': showWithdrawPage !== 1,
              }" />
            <div class="absolute text-[14px] font-bold">
              {{ $t("MemberFunction.Withdraw.AccountWithdraw") }}
            </div>
          </button>
          <!-- <button
            class="group relative flex justify-center items-center mr-[1vw]"
            @click="changeWithdrawPage(2)"
            :class="{
              'text-secondary': showWithdrawPage === 2,
              'text-primary hover:text-secondary': showWithdrawPage !== 2,
            }"
          >
            <img
              class="w-[100px]"
              v-img-src="top_btn_p"
              alt=""
              :class="{
                block: showWithdrawPage === 2,
                'hidden group-hover:block': showWithdrawPage !== 2,
              }"
            />
            <img
              class="w-[100px]"
              v-img-src="top_btn"
              alt=""
              :class="{
                hidden: showWithdrawPage === 2,
                'block group-hover:hidden': showWithdrawPage !== 2,
              }"
            />

            <div class="absolute text-[14px] font-bold">USDT</div>
          </button> -->
          <button
            class="group relative flex justify-center items-center"
            @click="changeWithdrawPage(99)"
            :class="{
              'text-secondary': showWithdrawPage === 99,
              'text-primary hover:text-secondary': showWithdrawPage !== 99,
            }">
            <img
              class="w-[100px]"
              v-img-src="top_btn_p"
              alt=""
              :class="{
                block: showWithdrawPage === 99,
                'hidden group-hover:block': showWithdrawPage !== 99,
              }" />
            <img
              class="w-[100px]"
              v-img-src="top_btn"
              alt=""
              :class="{
                hidden: showWithdrawPage === 99,
                'block group-hover:hidden': showWithdrawPage !== 99,
              }" />
            <div class="absolute text-[14px] font-bold">
              {{ $t("MemberFunction.Withdraw.WithdrawRecord") }}
            </div>
          </button>
        </div>
        <div class="relative member-in-bg w-full h-full">
          <!-- main -->
          <BankWithdraw v-show="showWithdrawPage === 1" />
          <!-- <USDTWithdraw v-else-if="showWithdrawPage === 2" /> -->

          <!-- <USDTWithdraw v-else /> -->
          <RecordWithdraw v-show="showWithdrawPage === 99" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
</style>
