<script setup lang="ts">
import { getCurrentInstance, onMounted } from "vue";
import type { ComponentInternalInstance } from "vue";
import { storeToRefs } from "pinia";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";

import triangle_icon from "@image/memberFunction/common/triangle_icon.png";
import 文字框_長_A from "@image/memberFunction/文字框/文字框_長_A.png";
import 驚嘆號icon from "@image/memberFunction/11/驚嘆號icon.png";

const { appContext } = getCurrentInstance() as ComponentInternalInstance;
const memberService = useMemberService();
const store = useCounterStore();

const { openAdminUSDTList, AdminUSDTList } = storeToRefs(memberService);
const { isWithdrawPageAlert } = storeToRefs(store);
interface AddUSDTFormData {
  nPay_usdt: string;
  sPay_address: string;
}

async function submit(addBankForm: AddUSDTFormData) {
  if (!addBankForm) return;
  await memberService.sendAddMemberUSDT(addBankForm);
  // addBankForm.value.resetForm();
}

onMounted(() => {
  appContext.config.globalProperties.$mitt.all.delete(
    "submit-add-bank",
    submit
  );
  appContext.config.globalProperties.$mitt.on("submit-add-bank", submit);
});
</script>

<template>
  <div
    class="md:grid grid-cols-12 gap-x-[3vw] block gap-y-[1dvh] px-[1vw] pb-[3rem] pt-0 md:pt-[3rem]">
    <SidebarWeb class="col-span-3 hidden md:block mb-[7dvh]" />
    <div class="col-span-9 relative">
      <div
        class="px-0 py-0 md:px-[5vw] md:py-[2dvh] relative z-10 web-gold-border rounded-[24px]">
        <p
          class="mt-[1dvh] md:mt-[2dvh] text-[24px] text-primary font-bold hidden md:block">
          {{ $t("MemberFunction.AddUSDT.AddVirtualWallet") }}
        </p>

        <div
          class="px-0 pt-0 md:px-[1em] md:pt-[1em] pb-[25dvh] lg:pb-0"
          v-if="!isWithdrawPageAlert">
          <!-- APP -->

          <div
            class="relative member-bg-border mt-[1vh] w-full h-full py-[2em] px-[1em] md:px-[3vw]">
            <div v-if="!openAdminUSDTList" class="relative submitting"></div>
            <VForm
              @submit="submit"
              ref="OnlineDepositForm"
              class="py-[1em] px-[1vw] md:px-[8vw]"
              v-else>
              <div class="text-primary">
                <label
                  for="other"
                  class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[12px] md:mt-[3dvh]">
                  <p class="mr-[1em] text-primary col-span-3">
                    {{ $t("MemberFunction.AddUSDT.WalletProtocol") }}
                  </p>

                  <div class="relative col-span-9">
                    <div class="h-[26px] w-full">
                      <img
                        v-img-src="triangle_icon"
                        alt=""
                        class="h-[12px] absolute right-[2.5%] md:right-[3.4%] top-[13px] -translate-y-1/2 z-20" />
                    </div>
                    <VField
                      id="nPay_usdt"
                      :label="$t('MemberFunction.AddUSDT.WalletProtocol')"
                      name="nPay_usdt"
                      v-slot="{ field }"
                      class="relative w-full">
                      <select
                        v-bind="field"
                        class="absolute select-bg-border top-0 bg-transparent px-2 rounded w-full h-[26px] text-primary">
                        <option class="text-black" value="">
                          {{ $t("MemberFunction.AddUSDT.SelectDepositMethod") }}
                        </option>
                        <option
                          class="text-black"
                          v-for="value in AdminUSDTList"
                          :key="value + 'onlineDepositOther'"
                          :value="value.nId">
                          {{ value.sProtocol }}
                        </option>
                      </select>
                    </VField>
                  </div>
                </label>
                <label
                  for="payCount"
                  class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[16px] md:mt-[3dvh]">
                  <p class="mr-[1em] col-span-3">
                    {{ $t("MemberFunction.AddUSDT.WalletAddress") }}
                  </p>
                  <div class="relative inline-block col-span-9">
                    <div class="h-[52px]">
                      <img
                        v-img-src="文字框_長_A"
                        alt=""
                        class="h-[52px] w-full" />
                    </div>
                    <VField
                      class="col-span-1 absolute h-[26px] top-0 rounded-md bg-transparent w-full px-2"
                      id="sPay_address"
                      :label="$t('MemberFunction.AddUSDT.MemberWalletAddress')"
                      name="sPay_address"
                      type="text"
                      :rules="{ required: true }"
                      v-slot="{ field }">
                      <textarea
                        :placeholder="
                          $t(
                            'MemberFunction.AddUSDT.MemberWalletAddressPlaceholder'
                          )
                        "
                        v-bind="field"
                        class="rounded-md text-primary resize-none w-full absolute top-0 bg-transparent px-2 discount-scrollbar"></textarea>
                    </VField>
                  </div>
                </label>
              </div>
              <div
                class="mt-[3dvh] md:static flex flex-col justify-center items-center">
                <button type="submit" class="btn-y-border">
                  <p
                    class="text-[14px] md:text-[14px] font-bold text-secondary px-[2em] py-[.1em]">
                    {{ $t("MemberFunction.AddUSDT.Next") }}
                  </p>
                </button>
                <div class="mt-[3dvh]">
                  <div class="flex justify-center items-center leading-[36px]">
                    <img v-img-src="驚嘆號icon" alt="" class="w-[16px] mr-1" />
                    <span class="text-red-600">
                      {{ $t("MemberFunction.AddUSDT.Notice") }}
                    </span>
                  </div>
                  <div
                    class="text-primary text-[14px] md:text-[16px] leading-[20px]">
                    <p class="mb-[1vh]">
                      <span class="text-red-600 mr-1">{{
                        $t("MemberFunction.AddUSDT.NoticeListStyle")
                      }}</span
                      >{{ $t("MemberFunction.AddUSDT.NoticeList1") }}
                    </p>
                    <p class="mb-[1vh]">
                      <span class="text-red-600 mr-1">{{
                        $t("MemberFunction.AddUSDT.NoticeListStyle")
                      }}</span
                      >{{ $t("MemberFunction.AddUSDT.NoticeList2") }}
                    </p>
                    <p class="mb-[1vh]">
                      <span class="text-red-600 mr-1">{{
                        $t("MemberFunction.AddUSDT.NoticeListStyle")
                      }}</span
                      >{{ $t("MemberFunction.AddUSDT.NoticeList3") }}
                    </p>
                  </div>
                </div>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
</style>
