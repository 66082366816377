<script setup lang="ts">
import { ref, watch, getCurrentInstance, onMounted } from "vue";
import type { ComponentInternalInstance } from "vue";
import { storeToRefs } from "pinia";
import type * as APItypes from "@/assets/API/APItype";
import { useContentInfo } from "@/stores/contentInfo";

const { appContext } = getCurrentInstance() as ComponentInternalInstance;
const contentInfoStore = useContentInfo();

const { contentInfo } = storeToRefs(contentInfoStore);
interface GamePlatformAPPList {
  list: APItypes.AGameKind;
  isSelect: boolean;
}

const emit = defineEmits(["slideToGamePlatformSwiper"]);

const GamePlatformList = ref<HTMLUListElement | null>(null);
const GamePlatformAPPLists = ref<GamePlatformAPPList[]>([]);
watch(contentInfo, (nVal, oVal) => {
  const gameDataList = nVal.aGameKind.map((item) => {
    const key = Object.keys(item)[0];
    const innerObject = item[key];
    return { ...innerObject };
  });
  const watchLists = gameDataList.map((item, index) => {
    if (index === 0) {
      return {
        list: item,
        isSelect: true,
      };
    }
    return {
      list: item,
      isSelect: false,
    };
  });
  GamePlatformAPPLists.value = watchLists;
});

const getGamePlatformAPPImg = (
  imgOn: string,
  imgOff: string,
  isSelect: boolean
) => {
  let image;
  image = isSelect ? imgOn : imgOff;
  return image;
};

function selectGamePlatformBtn(index: number) {
  emit("slideToGamePlatformSwiper", index);
  openGamePlatformBtn(index);
}

function openGamePlatformBtn(index: number) {
  GamePlatformAPPLists.value.forEach((item, itemIndex) => {
    item.isSelect = false;
    if (index === itemIndex) {
      item.isSelect = true;
    }
  });
  if (!GamePlatformList.value) return;
  const target = GamePlatformList.value.children[index];
  target.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
}

onMounted(() => {
  appContext.config.globalProperties.$mitt.on(
    "open-game-platform-btn",
    openGamePlatformBtn
  );
});
</script>

<template>
  <ul
    ref="GamePlatformList"
    class="overflow-y-auto overflow-x-hidden max-h-[calc(100%)] md:max-h-[calc(100%-55px)] lists-scrollbar">
    <li
      v-for="(item, index) in GamePlatformAPPLists"
      :key="item.list.sTitle0 + item.list.nMenuId"
      class="flex">
      <button
        class="py-[10px] w-full relative"
        :class="{
          'platform-list-s-neon-border': item.isSelect,
          'platform-list-neon-border': !item.isSelect,
        }"
        @click="selectGamePlatformBtn(index)">
        <div class="w-full flex flex-col items-center">
          <img
            :src="
              getGamePlatformAPPImg(
                item.list.sImgIconOn,
                item.list.sImgIconOff,
                item.isSelect
              )
            "
            alt=""
            class="absolute object-contain top-0 w-[32px] h-[32px] md:w-[64px] md:h-[64px]" />
          <div class="w-[18px] h-[18px] md:w-[64px] md:h-[64px]"></div>
          <p
            class="truncate text-primary text-[12px] font-bold mx-1 relative top-1">
            {{ item.list.sTitle0 }}
          </p>
        </div>
      </button>
    </li>
    <li class="h-[56px]"></li>
  </ul>
</template>

<style>
.lists-scrollbar {
  /* -ms-overflow-style: none; */
  /* scrollbar-width: none;  */
}
.lists-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Track */
.lists-scrollbar::-webkit-scrollbar-track {
  background: #21252927;
  border-radius: 20px;
}

/* Handle */
.lists-scrollbar::-webkit-scrollbar-thumb {
  background: #21252969;
  border-radius: 20px;
}

/* Handle on hover */
.lists-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #212529;
}

.platform-list-s-neon-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/01/left_btn_s.png") 1;
  -webkit-border-image: url("@image/memberFunction/01/left_btn_s.png") 1;
  -o-border-image: url("@image/memberFunction/01/left_btn_s.png") 1;
  border-image: url("@image/memberFunction/01/left_btn_s.png") 0.8 fill;
}
.platform-list-neon-border {
  border-style: solid;
  border-width: 1px;
  -moz-border-image: url("@image/memberFunction/01/left_btn.png") 1;
  -webkit-border-image: url("@image/memberFunction/01/left_btn.png") 1;
  -o-border-image: url("@image/memberFunction/01/left_btn.png") 1;
  border-image: url("@image/memberFunction/01/left_btn.png") 0.8 fill;
}
</style>
