<script setup lang="ts">
import { ref, watch, computed, onMounted, getCurrentInstance } from "vue";
import type { ComponentInternalInstance } from "vue";
import { storeToRefs } from "pinia";
import * as APItypes from "@/assets/API/APItype";

import { useContentInfo } from "@/stores/contentInfo";
import ContextGamePlatformPageWeb from "@/components/content/contextGamePlatformPageWeb.vue";

import section_title from "@image/memberFunction/02_web/section_title.png";
import section_nav_btn_p from "@image/memberFunction/02_web/section_nav_btn_p.png";
import section_nav_btn from "@image/memberFunction/02_web/section_nav_btn.png";

const contentInfoStore = useContentInfo();
const { contentInfo } = storeToRefs(contentInfoStore);
const { appContext } = getCurrentInstance() as ComponentInternalInstance;

interface GamePlatformList {
  data: APItypes.AGameKind;
  isSelect: boolean;
}
const gamePlatformLists = ref<GamePlatformList[]>([]);
const gamePlatformList = computed(() => {
  const Game = gamePlatformLists.value.find((item) => {
    return item.isSelect;
  });
  return Game ? Game.data : ({} as APItypes.AGameKind);
});
watch(contentInfo, (nVal, oVal) => {
  const aGameKind = nVal.aGameKind;
  const watchLists = aGameKind.map((item) => {
    return {
      data: Object.values(item)[0],
      isSelect: false,
    };
  });
  gamePlatformLists.value = watchLists.filter((item) => {
    return item.data.nBKid !== "50";
  });
  gamePlatformLists.value[0].isSelect = true;
});

function selectPlatform(index: number) {
  gamePlatformLists.value.forEach((item, itemIndex) => {
    item.isSelect = false;
    if (index === itemIndex) {
      item.isSelect = true;
    }
  });
}
const GamePlatformType = computed(() => {
  let state = gamePlatformLists.value.find((item) => item.isSelect)?.data.nBKid;
  return state;
});

const BetAreaList = ref<HTMLDivElement | null>(null);
function scrollToBetAreaList() {
  if (!BetAreaList.value) return;
  BetAreaList.value.scrollIntoView({ behavior: "smooth" });
}

onMounted(() => {
  appContext.config.globalProperties.$mitt.on(
    "scroll-to-bet-area-list",
    scrollToBetAreaList
  );
});
</script>

<template>
  <div class="py-[10vh]">
    <div class="flex items-center justify-center">
      <img v-img-src="section_title" alt="" class="w-[80%]" />
    </div>
    <div
      class="bg-[url('@image/memberFunction/02_web/section_nav_bg.png')] bg-cover bg-100 py-[1vh] px-[1vw] mx-[2.5vw] mt-[10vh]"
      ref="BetAreaList">
      <ul class="flex justify-between items-center relative top-[4px]">
        <li v-for="(item, index) in gamePlatformLists" :key="item.data.nBKid">
          <button class="group" @click="selectPlatform(index)">
            <div class="relative flex items-center w-full px-[.5vw]">
              <img
                v-img-src="section_nav_btn_p"
                alt=""
                :class="item.isSelect ? 'block' : 'hidden group-hover:block'" />
              <img
                v-img-src="section_nav_btn"
                alt=""
                :class="
                  item.isSelect ? 'hidden' : 'block group-hover:hidden'
                " />
              <div
                class="absolute flex justify-center items-center top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
                <img
                  :src="item.data.sImgIconPc"
                  alt=""
                  class="object-contain w-[24px] h-[24px]" />
                <p
                  class="text-[16px] w-[60px] font-bold mx-1 group-hover:text-secondary"
                  style="text-wrap: nowrap"
                  :class="{
                    'text-primary': !item.isSelect,
                    'text-secondary': item.isSelect,
                  }">
                  {{ item.data.sTitle0 }}
                </p>
              </div>
            </div>
            <p
              class="text-[16px] xl:text-[18px] text-center font-bold"
              :class="
                item.isSelect
                  ? 'text-primary'
                  : 'text-primary/80 group-hover:text-primary'
              "></p>
          </button>
        </li>
      </ul>
    </div>
    <ContextGamePlatformPageWeb
      :GamePlatformType="GamePlatformType"
      :GameKind="gamePlatformList" />
  </div>
</template>
<style scoped>
.bg-100 {
  background-size: 100% 100%;
}
</style>
