<script setup lang="ts">
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useMemberService } from "@/stores/memberFunction/memberService";

import tradeRecord from "@/components/menberFunction/tradeRecordPage/tradeRecord.vue";
import SidebarWeb from "@/components/menberFunction/functionNavbar/sidebarWeb.vue";

import top_btn_p from "@image/memberFunction/common/top_btn_p.png";
import top_btn from "@image/memberFunction/common/top_btn.png";

const { t } = useI18n();
const memberService = useMemberService();
const { defaultTransactionRecord } = storeToRefs(memberService);

//#region 點擊查詢類別
interface TypeList {
  title: string;
  fn: Function;
  isSelect: boolean;
}
const typeLists = ref<TypeList[]>([
  {
    title: t("MemberFunction.TradeRecord.All"),
    fn: getAllType,
    isSelect: true,
  },
  {
    title: t("MemberFunction.TradeRecord.Deposit"),
    fn: getDepositType,
    isSelect: false,
  },
  {
    title: t("MemberFunction.TradeRecord.Withdraw"),
    fn: getWithdrawType,
    isSelect: false,
  },
  {
    title: t("MemberFunction.TradeRecord.BonusOffers"),
    fn: getBonusType,
    isSelect: false,
  },
  {
    title: t("MemberFunction.TradeRecord.TransferPoints"),
    fn: getPointType,
    isSelect: false,
  },
  {
    title: t("MemberFunction.TradeRecord.RebateRecord"),
    fn: getBackMoneyType,
    isSelect: false,
  },
]);
function selectType(index: number) {
  typeLists.value.forEach((type, typeIndex) => {
    type.isSelect = false;
    if (typeIndex === index) {
      type.isSelect = true;
    }
  });
}
function getAllType() {
  defaultTransactionRecord.value.nType = -1;
  defaultTransactionRecord.value.nType = -1;

  selectType(0);
  getTradeRecordData();
}
function getDepositType() {
  defaultTransactionRecord.value.nType = 1;
  defaultTransactionRecord.value.nType = 1;

  selectType(1);
  getTradeRecordData();
}
function getWithdrawType() {
  defaultTransactionRecord.value.nType = 2;
  defaultTransactionRecord.value.nType = 2;

  selectType(2);
  getTradeRecordData();
}
function getBonusType() {
  defaultTransactionRecord.value.nType = 3;
  defaultTransactionRecord.value.nType = 3;

  selectType(3);
  getTradeRecordData();
}
function getPointType() {
  defaultTransactionRecord.value.nType = 4;
  defaultTransactionRecord.value.nType = 4;

  selectType(4);
  getTradeRecordData();
}
function getBackMoneyType() {
  defaultTransactionRecord.value.nType = 5;
  defaultTransactionRecord.value.nType = 5;

  selectType(5);
  getTradeRecordData();
}

//#endregion
function getTradeRecordData() {
  memberService.getTradeRecordData();
}
</script>

<template>
  <div
    class="md:grid grid-cols-12 gap-x-[3vw] block gap-y-[1dvh] px-[1vw] pb-[3rem] pt-0 md:pt-[3rem]">
    <SidebarWeb class="col-span-3 hidden md:block mb-[7dvh]" />
    <div class="col-span-9 relative">
      <div
        class="px-0 pt-0 pb-0 md:px-[5vw] md:pb-[10dvh] md:pt-[2dvh] relative z-10 web-gold-border rounded-[24px]">
        <p class="mt-[1dvh] text-[24px] text-primary font-bold hidden md:block">
          {{ $t("MemberFunction.TradeRecord.TradeRecord") }}
        </p>
        <div
          class="flex justify-center items-center overflow-x-auto overflow-y-hidden relative mt-5 mx-2 tradeRecord-scrollbar">
          <img class="w-[100px] opacity-0" v-img-src="top_btn_p" alt="" />
          <div
            class="flex absolute -left-0 lg:left-1/2 -translate-x-0 lg:-translate-x-1/2 top-0 mx-auto">
            <button
              v-for="item in typeLists"
              :key="item.title"
              class="group relative w-[100px] mr-[1vw]"
              @click="item.fn"
              :class="{
                'text-secondary': item.isSelect,
                'text-primary hover:text-secondary': !item.isSelect,
              }">
              <img
                class="w-full"
                v-img-src="top_btn_p"
                alt=""
                :class="{
                  block: item.isSelect,
                  'hidden group-hover:block': !item.isSelect,
                }" />
              <img
                class="w-full"
                v-img-src="top_btn"
                alt=""
                :class="{
                  hidden: item.isSelect,
                  'block group-hover:hidden': !item.isSelect,
                }" />
              <div
                class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-[120%] text-[14px] font-bold">
                {{ item.title }}
              </div>
            </button>
          </div>
        </div>
        <div class="relative member-in-bg w-full h-full">
          <!-- main -->

          <tradeRecord />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bg-100 {
  background-size: 100% 100%;
}
.tradeRecord-scrollbar {
}
.tradeRecord-scrollbar::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
.tradeRecord-scrollbar::-webkit-scrollbar-track {
  background: #21252927;
  border-radius: 2px;
}

/* Handle */
.tradeRecord-scrollbar::-webkit-scrollbar-thumb {
  background: #ffffff69;
  border-radius: 2spx;
}

/* Handle on hover */
.tradeRecord-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #212529;
}
</style>
