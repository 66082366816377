import { ref, watch, computed, watchEffect } from "vue";
import { useContentInfo } from "./contentInfo";
import { defineStore, storeToRefs } from "pinia";
export const useBgAudio = defineStore("BgAudio", () => {
  const contentInfo = useContentInfo();
  const BgAudio = ref<HTMLAudioElement | null>(null);
  const musicState = ref<boolean>(false);
  const { BgMusic } = storeToRefs(contentInfo);

  function BgAudioMounted() {
    if (BgAudio.value) return;
    BgAudio.value = document.createElement("audio");
    BgAudio.value.autoplay = true;
    BgAudio.value.loop = true;
    BgAudio.value.hidden = true;
    BgAudio.value.src = contentInfo.BgMusic;
    BgAudio.value.onplay = startMusic;
    document.body.appendChild(BgAudio.value);
    console.log(BgAudio.value, contentInfo.BgMusic);

    window.addEventListener("click", playMusic);
    window.addEventListener("touchStart", playMusic);
    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === "hidden") {
        // 页面不可见，停止音频播放
        stopMusic();
      } else {
        reloadMusic();
      }
    });
  }
  watch(BgMusic, () => {
    BgAudioMounted();
  });
  function startMusic() {
    musicState.value = true;
    window.removeEventListener("click", playMusic);
    window.removeEventListener("touchStart", playMusic);
  }
  function playMusic() {
    if (BgAudio.value?.paused) {
      BgAudio.value?.play();
    }
  }
  function changeMusicState() {
    console.log(BgAudio.value?.paused, "changeMusicState");
    if (BgAudio.value?.paused) {
      BgAudio.value?.play();
    } else {
      BgAudio.value?.pause();
      musicState.value = false;
    }
  }
  function stopMusic() {
    BgAudio.value?.pause();
  }
  function reloadMusic() {
    if (musicState.value) {
      BgAudio.value?.play();
    } else {
      BgAudio.value?.pause();
    }
  }

  return {
    musicState,
    BgAudioMounted,
    changeMusicState,
    playMusic,
    stopMusic,
    reloadMusic,
  };
});
