<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useContentInfo } from "@/stores/contentInfo";

import Arrow_for_left from "@image/memberFunction/文字框/Arrow_for_left.gif";
import Arrow_for_right from "@image/memberFunction/文字框/Arrow_for_right.gif";

const contentInfoStore = useContentInfo();

const { slidesTipState } = storeToRefs(contentInfoStore);
</script>

<template>
  <div class="absolute z-[50] right-0">
    <div class="relative flex w-full top-[-2vh]">
      <img
        v-img-src="Arrow_for_left"
        class="h-[24px] object-contain mr-[4vw]"
        :class="{
          'opacity-0': slidesTipState === 'none' || slidesTipState === 'left',
        }"
        alt="" />
      <img
        v-img-src="Arrow_for_right"
        class="h-[24px] object-contain relative mr-[2vw]"
        :class="{
          'opacity-0': slidesTipState === 'none' || slidesTipState === 'right',
        }"
        alt="" />
    </div>
  </div>
</template>
