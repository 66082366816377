<script setup lang="ts">
import { getCurrentInstance } from "vue";
import type { ComponentInternalInstance } from "vue";
import { useRouter } from "vue-router";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
import 驚嘆號icon from "@image/memberFunction/11/驚嘆號icon.png";

const { appContext } = getCurrentInstance() as ComponentInternalInstance;
const router = useRouter();
const memberService = useMemberService();
const store = useCounterStore();

function closeMailBoxAlert() {
  store.hiddenAllAlert();
  store.hiddenAlertBg();
}

function submitAddBank() {
  closeMailBoxAlert();
  appContext.config.globalProperties.$mitt.emit("submit-add-bank");
}

function toService() {
  router.push({ name: "Service" });
}
</script>

<template>
  <div
    class="sticky top-[30%] md:top-[50%] -translate-y-1/2 left-0 md:left-1/2 translate-x-0 md:-translate-x-1/2 w-[95vw] md:w-[600px]">
    <div
      class="relative bg-[url('@image/memberFunction/08/detail_board.png')] bg-100 h-[350px]">
      <h3
        class="absolute top-[18px] left-1/2 -translate-x-1/2 text-center text-secondary text-[20px] font-bold">
        {{ $t("MemberFunction.AddBank.Notice") }}
      </h3>
      <div
        class="absolute left-1/2 -translate-x-1/2 top-[10dvh] md:top-[6dvh] px-[3em] w-full md:w-[500px] h-full bg-100 text-primary text-[14px] md:text-[20px]">
        <div class="mt-[2dvh]">
          <div class="flex justify-center items-center mb-[1dvh]">
            <img v-img-src="驚嘆號icon" alt="" class="w-[18px] mr-1" />
            <p class="text-[18px] text-red-600 tracking-[.3rem]">
              {{ $t("MemberFunction.AddBank.Notice") }}
            </p>
          </div>
          <p class="leading-[24px]">
            {{ $t("MemberFunction.AddBank.NoticeAlert1List") }}
          </p>
        </div>
        <div class="static w-full">
          <div class="flex justify-center items-center mt-[12px] md:mt-[18px]">
            <button
              class="mr-[10%] btn-y-border"
              @click="submitAddBank"
              type="button">
              <p
                class="text-[14px] md:text-[16px] text-secondary px-[1em] py-[.1em]">
                {{ $t("MemberFunction.AddBank.ConfirmAdd") }}
              </p>
            </button>
            <button
              class="btn-y-border"
              @click="closeMailBoxAlert"
              type="button">
              <p
                class="text-[14px] md:text-[16px] text-secondary px-[1em] py-[.1em]">
                {{ $t("MemberFunction.AddBank.Return") }}
              </p>
            </button>
          </div>
          <div class="text-center mt-[12px]">
            <span></span>
            <button
              type="button"
              class="mx-2 underline decoration-1 text-blue-300 hover:text-blue-400"
              @click="toService">
              {{ $t("MemberFunction.AddBank.ContactService") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.bgLinearMailBox {
  background: linear-gradient(45deg, #2c343e, #576879);
  border-style: solid;
  border-image: linear-gradient(45deg, #563d2a, #edbe7b) 1;
  border-image-slice: 1 1 1 1;
}
.bg-linear-mail-box-title {
  background: linear-gradient(#624380, #472161);
}
.bg-100 {
  background-size: 100% 100%;
}
</style>
