<script setup lang="ts">
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useCounterStore } from "@/stores/counter";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useBgAudio } from "@/stores/bgAudio";
import NewsType1 from "@image/memberFunction/08/news_btn_01.png";
import NewsType2 from "@image/memberFunction/08/news_btn_02.png";
import NewsType3 from "@image/memberFunction/08/news_btn_03.png";

import password_btn from "@image/memberFunction/10/password_btn.png";
import password_s_btn from "@image/memberFunction/10/password_s_btn.png";
import news_line from "@image/memberFunction/08/news_line.png";

const store = useCounterStore();
const memberService = useMemberService();
const bgAudio = useBgAudio();

const { NewJumpMes } = storeToRefs(memberService);

const TodayState = ref<boolean>(false);
function saveTodayNewsCookies() {
  TodayState.value = !TodayState.value;
  if (TodayState.value) {
    store.saveTodayNewsCookies("2");
  } else {
    store.saveTodayNewsCookies("1");
  }
}
function toggleAlert() {
  store.toggleAlertBg();
  store.hiddenAllAlert();
}
function openNewsContentAlert(id: string) {
  memberService.selectNewsContentAlert(id);
  store.openNewsContentAlert();
}

function playMusic() {
  bgAudio.playMusic();
}
function NewsTypeImage(type: string) {
  let image;
  switch (type) {
    case "客服":
      image = NewsType1;
      break;
    case "活動":
      image = NewsType2;
      break;
    case "維護":
      image = NewsType3;
      break;
    default:
      image = NewsType1;
      break;
  }
  return image;
}
onMounted(async () => {
  // await memberService.getNewMessageData();
  // if (NewJumpMes.value.length === 4) {
  //   toggleAlert();
  // }
});
</script>
<template>
  <div
    class="sticky top-[10%] w-[95vw] md:w-[500px] mx-auto"
    @click="playMusic">
    <div
      class="relative bg-[url('@image/memberFunction/08/detail_board.png')] bg-100 h-[500px] md:h-[53dvh]">
      <h3
        class="absolute top-[30px] md:top-[3.2vh] left-1/2 -translate-x-1/2 text-center text-secondary text-[20px] font-bold">
        {{ $t("Context.NewsAlert.News") }}
      </h3>
      <button
        class="absolute top-[30px] md:top-[3.2vh] right-[8%] -translate-x-1/2 text-center text-secondary text-[20px] font-bold"
        @click="toggleAlert"
        type="button">
        X
      </button>
      <button
        class="w-[83.5vw] md:w-[440px] absolute z-20 bottom-[8%] left-1/2 -translate-x-1/2 text-[12px] flex items-center justify-end px-[2vw]"
        @click="saveTodayNewsCookies"
        type="button">
        <div class="h-[12px] w-[12px] relative mr-1">
          <img v-img-src="password_btn" alt="" class="absolute h-full w-full" />
          <img
            v-img-src="password_s_btn"
            alt=""
            class="absolute h-full w-full"
            v-if="TodayState === true" />
        </div>
        <p>{{ $t("Context.NewsAlert.HideToday") }}</p>
      </button>

      <div
        class="w-[83.5vw] md:w-[440px] h-[350px] md:h-[38dvh] relative top-[15%] left-1/2 -translate-x-1/2 bg-100 text-primary flex flex-col text-[14px] md:text-[16px] overflow-y-auto news-scrollbar">
        <button
          type="button"
          v-for="item in NewJumpMes"
          :key="item + 'newsAlertList'"
          @click="openNewsContentAlert(item.nId)">
          <div
            class="py-[1vh] px-[2vw] md:px-[1vw] flex justify-start items-center">
            <div class="relative">
              <img
                v-img-src="NewsTypeImage(item.sKindTitle0)"
                alt=""
                class="w-[50px] h-[25px]" />
              <p
                class="truncate absolute top-1/2 -translate-y-1/2 w-[90%] left-1/2 -translate-x-1/2 text-black font-bold">
                {{ item.sKindTitle0 }}
              </p>
            </div>
            <div class="md:ml-[1vw] ml-[2vw]">
              <p class="truncate max-w-[235px] text-left text-[15px]">
                {{ item.sTitle0 }}
              </p>

              <p
                class="text-[11px] md:text-[14px] text-news-sub-title text-left">
                {{ item.sCreateTime }}
              </p>
            </div>
          </div>
          <img v-img-src="news_line" alt="" class="w-full" />
        </button>
      </div>
    </div>
  </div>
</template>

<style>
.news-scrollbar {
}
.news-scrollbar::-webkit-scrollbar {
  width: 8px;
}
/* Track */
.news-scrollbar::-webkit-scrollbar-track {
  background: #21252927;
  border-radius: 20px;
}

/* Handle */
.news-scrollbar::-webkit-scrollbar-thumb {
  background: #ffffff69;
  border-radius: 20px;
}

/* Handle on hover */
.news-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #212529;
}
</style>
