<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { defineRule } from "vee-validate";
import { storeToRefs } from "pinia";
import SelectStoreDeposit from "@/components/menberFunction/depositPage/SelectStoreDeposit.vue";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useLogin } from "@/stores/login";
import type { MemberRechargePage2ListData } from "@/assets/API/APItype";
import triangle_icon from "@image/memberFunction/common/triangle_icon.png";
import 文字框_長_A from "@image/memberFunction/文字框/文字框_長_A.png";
import 驚嘆號icon from "@image/memberFunction/11/驚嘆號icon.png";

interface DepositFormData {
  payCount: number;
  aTunnel: string;
}
interface DepositPostData {
  nId: string;
  sPay_name: string;
  sNickname: string;
  nMax: string;
  nMin: string;
  sGids: string;
  nMoney: string;
  t: string;
  a: string;
  aTunnel: string;
  postUrl: string;
}
const memberService = useMemberService();
const { DepositStoreList } = storeToRefs(memberService);
const login = useLogin();

const props = defineProps({
  Info: Object as () => MemberRechargePage2ListData,
});
const emit = defineEmits(["changeDepositPage"]);
function changeDepositPage(page: number) {
  emit("changeDepositPage", page);
}

const DepositForm = ref<any>(null);
const CanDepositForm = computed(() => {
  if (DepositStoreList.value.length <= 0) return false;
  const hasUnCheck = DepositStoreList.value.every((list) => {
    return list.nStatus === "0";
  });
  return !hasUnCheck;
});

// 支付渠道 aTunnel
const PayATunnel = computed(() => {
  if (!props.Info) return {};
  return props.Info.aPayment.aTunnel;
});

// 判斷個位數是否為 0
function isUnitDigitZero(value: number) {
  // 確保是數字類型
  return value % 10 === 0;
}
defineRule("unitDigitZero", (value: number) => {
  return isUnitDigitZero(value) || "*個位數必須為 0";
});

function submit(VForm: DepositFormData) {
  if (!props.Info) return;
  const { nId, sPay_name, sNickname, nMax, nMin, sGids, t, a, postUrl } =
    props.Info.aPayment;
  const { payCount, aTunnel } = VForm;

  const postData: DepositPostData = {
    nId,
    sPay_name,
    sNickname,
    nMax,
    nMin,
    sGids,
    t: t.toString(),
    a,
    nMoney: payCount.toString(),
    aTunnel,
    postUrl,
  };
  DepositForm.value.resetForm();
  memberService.getMemberRecharge2(postData);
}
</script>

<template>
  <VForm @submit="submit" ref="DepositForm" class="py-[2em] px-[9vw]">
    <div class="text-primary mb-[3dvh]" v-if="CanDepositForm">
      <label
        for="aTunnel"
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[16px] md:mt-[3dvh]"
        v-if="JSON.stringify(PayATunnel) !== '{}'">
        <p class="mr-[1em] col-span-4">
          {{ $t("MemberFunction.Deposit.PaymentMethod") }}
        </p>
        <div class="relative col-span-8">
          <div class="h-[26px] w-full">
            <img
              v-img-src="triangle_icon"
              alt=""
              class="h-[12px] absolute right-[2.5%] md:right-[3.4%] top-[13px] -translate-y-1/2 z-20" />
          </div>
          <VField
            id="aTunnel"
            :label="$t('MemberFunction.Deposit.PaymentMethod')"
            name="aTunnel"
            v-slot="{ field }"
            class="relative"
            :rules="{ required: true }">
            <select
              v-bind="field"
              class="absolute select-bg-border top-0 bg-transparent px-2 rounded w-full h-[26px] text-primary">
              <option class="text-black" value="">
                {{ $t("MemberFunction.Deposit.SelectPaymentMethod") }}
              </option>
              <option
                class="text-black"
                v-for="(value, key) in PayATunnel"
                :key="value + 'onlineDepositOther'"
                :value="key">
                {{ value }}
              </option>
            </select>
          </VField>
          <ErrorMessage
            name="StorePayment"
            class="text-primary text-[10px]"
            v-slot="{ message }">
            <p class="text-primary text-[10px] mr-[6px]">
              {{
                message.includes("必填")
                  ? $t("MemberFunction.Deposit.Require")
                  : message
              }}
            </p>
          </ErrorMessage>
        </div>
      </label>
      <label
        for="payCount"
        class="grid grid-cols-12 text-[14px] md:text-[16px] mt-[16px] md:mt-[3dvh]">
        <p class="mr-[1em] col-span-4">
          {{ $t("MemberFunction.Deposit.DepositAmount") }}
        </p>
        <div class="relative inline-block col-span-8">
          <div class="h-[26px]">
            <img v-img-src="文字框_長_A" alt="" class="h-[26px] w-full" />
          </div>
          <VField
            class="col-span-1 absolute h-[26px] top-0 rounded-md bg-transparent w-full px-2"
            id="payCount"
            :label="$t('MemberFunction.Deposit.DepositAmount')"
            name="payCount"
            type="number"
            min="1"
            :rules="{ required: true, min_value: 1, unitDigitZero: true }" />
          <ErrorMessage
            name="payCount"
            class="text-red-600 text-[10px]"
            v-slot="{ message }">
            <p class="text-red-600 text-[10px] mr-[6px]">
              {{
                message.includes("必填")
                  ? $t("MemberFunction.Deposit.Require")
                  : message
              }}
            </p>
          </ErrorMessage>
        </div>
      </label>
    </div>

    <div class="md:static flex flex-col justify-center items-center">
      <button type="submit" class="btn-y-border" v-if="CanDepositForm">
        <p
          class="text-[14px] md:text-[14px] font-bold text-secondary px-[2em] py-[.1em]">
          {{ $t("MemberFunction.Deposit.Next") }}
        </p>
      </button>
      <SelectStoreDeposit @changeDepositPage="changeDepositPage" />
      <div class="mt-[3dvh]">
        <div class="flex justify-center items-center leading-[36px]">
          <img v-img-src="驚嘆號icon" alt="" class="w-[16px] mr-1" />
          <span class="text-red-600">
            {{ $t("MemberFunction.Deposit.StoreNotice") }}
          </span>
        </div>
        <div class="text-primary text-[14px] md:text-[16px] leading-[20px]">
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>
              {{ $t("MemberFunction.Deposit.StoreNoticeList1") }}
            </p>
          </div>
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>{{ $t("MemberFunction.Deposit.StoreNoticeList2") }}</p>
          </div>
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>
              {{ $t("MemberFunction.Deposit.StoreNoticeList3") }}
            </p>
          </div>
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>
              {{ $t("MemberFunction.Deposit.StoreNoticeList4") }}
            </p>
          </div>
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>
              {{ $t("MemberFunction.Deposit.StoreNoticeList5") }}
            </p>
          </div>
          <div class="mb-[1vh] flex">
            <p class="text-red-600 mr-1">※</p>
            <p>
              {{ $t("MemberFunction.Deposit.StoreNoticeList6") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </VForm>
</template>
