<script setup lang="ts">
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";
import { useContentInfo } from "@/stores/contentInfo";
import { useRouter } from "vue-router";

import marquee from "@image/memberFunction/02_web/marquee.png";
const router = useRouter();
const contentInfoStore = useContentInfo();
const { contentInfoAMarquee, MarqueeSeconds } = storeToRefs(contentInfoStore);

// const MarqueeText = ref<string>("");
const textRef = ref<HTMLParagraphElement | null>(null);
const containerRef = ref<HTMLDivElement | null>(null);

const goNewMes = () => {
  router.push({ name: "NewMessage" });
};

function getBillboardWidth() {
  if (!textRef.value) return 0;
  return textRef.value.offsetWidth;
}
function getWindowWidth() {
  if (!containerRef.value) return 0;
  return containerRef.value.clientWidth;
}

function startBillboard() {
  let initLeft = getWindowWidth();
  let timer = setInterval(() => {
    if (!textRef.value) return;
    if (initLeft < getBillboardWidth() * -1) {
      initLeft = getWindowWidth();
    }
    initLeft -= 1;
    textRef.value.style.left = initLeft + "px";
  }, 20);
}

watch(contentInfoAMarquee, () => {
  startBillboard();
});

onMounted(async () => {
  if (contentInfoAMarquee.value) {
    startBillboard();
  }
  // window.addEventListener("resize", adjustMarquee);
});
onBeforeUnmount(() => {
  // window.removeEventListener("resize", adjustMarquee);
});
</script>

<template>
  <div class="w-full h-[25px] flex items-center">
    <div class="bg-[url('@image/memberFunction/02_web/marquee.png')] bg-100">
      <div class="relative ml-[4.5em] mr-[1vw] py-[3px]">
        <img v-img-src="marquee" alt="" class="opacity-0 w-[100%]" />
        <div class="absolute top-1/2 -translate-y-1/2 w-full h-full">
          <div
            ref="containerRef"
            class="relative overflow-hidden w-full h-full"
            style="white-space: nowrap">
            <p
              class="text-primary absolute left-0 top-1/2 -translate-y-1/2"
              ref="textRef">
              {{ contentInfoAMarquee }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.marquee1 {
  animation: marquee 6s linear infinite;
  animation-timing-function: linear;
  white-space: nowrap;
}
.marquee2 {
  animation: marquee 8s linear infinite;
  animation-timing-function: linear;
  white-space: nowrap;
}
.marquee3 {
  animation: marquee 10s linear infinite;
  animation-timing-function: linear;
  white-space: nowrap;
}
.marquee4 {
  animation: marquee 12s linear infinite;
  animation-timing-function: linear;
  white-space: nowrap;
}
.marquee5 {
  animation: marquee 14s linear infinite;
  animation-timing-function: linear;
  white-space: nowrap;
}
.bg-100 {
  background-size: 100% 100%;
}
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
