<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useLogin } from "@/stores/login";

import VIPFlagImage1 from "@image/memberFunction/common/VIP1.png";
import VIPFlagImage2 from "@image/memberFunction/common/VIP2.png";
import VIPFlagImage3 from "@image/memberFunction/common/VIP3.png";
import VIPFlagImage4 from "@image/memberFunction/common/VIP4.png";
import VIPFlagImage5 from "@image/memberFunction/common/VIP5.png";

import type { MemberDataVipData } from "@/assets/API/APItype";

const { t } = useI18n();

const memberService = useMemberService();
const login = useLogin();
const { VipList, VipListSort, VipListMaxLv } = storeToRefs(memberService);
const { memberData } = storeToRefs(login);

// VIP % 數
const energyPercent = computed(() => {
  let Percent = 0;

  const nextVip = VipList.value.find((item) => {
    return item.nVipLv === getNextVIP(memberData.value.aVipData);
  });
  if (!nextVip) {
    Percent = 0;
    return Math.round(Percent);
  }

  const memberBet = parseInt(memberData.value.aVipData.nBetMoney);
  const nextVipBet = parseInt(nextVip.nVipBet);
  //下注達標
  Percent = (memberBet / nextVipBet) * 100;
  const checkPoint = Math.round((Percent + Number.EPSILON) * 100) / 100;
  console.log(checkPoint);
  return checkPoint === 0 ? "0.00" : checkPoint;
});

const levelBarColorDown = (levelData: MemberDataVipData) => {
  if (!levelData) return "bg-[#1d2251]";
  let level = levelData.nVipLv;
  let color = "";
  switch (level) {
    case "1":
      color = "bg-[#1d2251]";
      break;
    case "2":
      color = "bg-[#56268f]";
      break;
    case "3":
      color = "bg-[#747474]";
      break;
    case "4":
      color = "bg-[#b4813b]";
      break;
    case "5":
      color = "bg-[#191919]";
      break;
    default:
      color = "bg-[#1d2251]";
      break;
  }
  return color;
};

const getNextVIP = (levelData: MemberDataVipData) => {
  if (!levelData) return t("MemberFunction.VIPPrivilege.Maximum");
  if (!VipListSort.value[0]) return t("MemberFunction.VIPPrivilege.Maximum");

  let level = levelData.nVipLv;
  if (VipList.value.length <= 0)
    return t("MemberFunction.VIPPrivilege.Maximum"); // 如果沒有 vip列表

  let nextLevel;
  if (level === "0") {
    //無vip
    return VipListSort.value[0].nVipLv;
  }

  const VIPIndex = VipListSort.value.findIndex((item) => {
    return item.nVipLv === level;
  });
  if (VIPIndex !== -1 && VIPIndex < VipListSort.value.length - 1) {
    const nextVIPIndex = VIPIndex + 1;
    nextLevel = VipListSort.value[nextVIPIndex].nVipLv;
  } else {
    nextLevel = t("MemberFunction.VIPPrivilege.Maximum");
  }

  return nextLevel;
};
function isNumber(str: string) {
  // 檢查是否只包含數字
  return /^\d+$/.test(str);
}

const getVIPIconImage = (level: string) => {
  let image;
  switch (level) {
    case "0":
    case "1":
      // image = VIPIconImage1;
      image = VIPFlagImage1;
      break;
    case "2":
      // image = VIPIconImage2;
      image = VIPFlagImage2;
      break;
    case "3":
      // image = VIPIconImage3;
      image = VIPFlagImage3;
      break;
    case "4":
      // image = VIPIconImage4;
      image = VIPFlagImage4;
      break;
    case "5":
      // image = VIPIconImage5;
      image = VIPFlagImage5;
      break;
    default:
      image = "";
      break;
  }
  return image;
};
</script>

<template>
  <div class="flex items-center justify-center text-primary w-full">
    <!-- <p class="col-span-1 text-[12px] text-center self-center">
      {{
        memberData.aVipData
          ? memberData.aVipData.nVipLv !== "0"
            ? $t("MemberFunction.VIPPrivilege.VIP") + memberData.aVipData.nVipLv
            : $t("MemberFunction.VIPPrivilege.Member")
          : $t("MemberFunction.VIPPrivilege.Member")
      }}
    </p> -->
    <div class="px-[6px]" v-if="memberData.aVipData?.nVipLv">
      <img
        v-img-src="getVIPIconImage(memberData.aVipData.nVipLv)"
        alt=""
        class="h-[24px] top-[-3px] relative" />
    </div>
    <div
      class="relative col-span-6 rounded-[12px] overflow-hidden mx-0 md:mx-[24px] basis-[70%] h-[16px] border border-white/50"
      v-if="
        getNextVIP(memberData.aVipData) !==
        $t('MemberFunction.VIPPrivilege.Maximum')
      ">
      <div
        class="h-full rounded-[12px]"
        :class="levelBarColorDown(memberData.aVipData)"></div>
      <div
        class="h-full rounded-[12px] absolute top-0 bg-gradient-to-b from-[#f9e7a2] to-[#dab26a]"
        :style="{ width: energyPercent + '%' }"></div>
      <p
        class="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-[10px]">
        {{ energyPercent }}%
      </p>
    </div>
    <div class="px-[6px]" v-if="isNumber(getNextVIP(memberData.aVipData))">
      <img
        v-img-src="getVIPIconImage(getNextVIP(memberData.aVipData))"
        alt=""
        class="h-[24px] top-[-3px] relative" />
    </div>
    <p
      class="text-[12px] text-center self-center"
      v-if="
        getNextVIP(memberData.aVipData) ===
        $t('MemberFunction.VIPPrivilege.Maximum')
      "
      :class="{
        'ml-[6px] col-span-2':
          getNextVIP(memberData.aVipData) ===
          $t('MemberFunction.VIPPrivilege.Maximum'),
        'col-span-1 ':
          getNextVIP(memberData.aVipData) !==
          $t('MemberFunction.VIPPrivilege.Maximum'),
      }">
      {{ getNextVIP(memberData.aVipData) }}
    </p>
  </div>
</template>
