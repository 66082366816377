<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useLogin } from "@/stores/login";
import nogood_icon from "@image/memberFunction/10/nogood_icon.png";
import load_icon from "@image/memberFunction/10/load_icon.png";
import good_icon from "@image/memberFunction/10/good_icon.png";
const login = useLogin();
const router = useRouter();
const { memberData } = storeToRefs(login);

const props = defineProps({
  Info: String,
});
function toBankVerified() {
  if (!login.isCashMember) return;
  router.push({ name: "BankVerified" });
}
</script>
<template>
  <p v-if="memberData.nRealNameType !== '-1'" class="relative mr-[3vw]">
    {{ props.Info }}
  </p>

  <button
    type="button"
    class="mr-[3vw] btn-y-border"
    @click="toBankVerified"
    v-if="memberData.nRealNameType === '-1'">
    <p class="text-[12px] text-secondary px-[1em] py-[.1em]">
      {{ $t("MemberFunction.MemberAccount.BankVerified") }}
    </p>
  </button>
  <div
    class="flex justify-center items-center bg-white/30 px-[2vw] md:px-[1vw] rounded-full">
    <img
      v-img-src="nogood_icon"
      alt=""
      class="w-[12px] h-[12px] mr-[1vw]"
      v-if="memberData.nRealNameType === '-1'" />
    <img
      v-img-src="load_icon"
      alt=""
      class="w-[12px] h-[12px] mr-[1vw]"
      v-else-if="memberData.nRealNameType === '0'" />
    <img
      v-img-src="good_icon"
      alt=""
      class="w-[12px] h-[12px] mr-[1vw]"
      v-else-if="memberData.nRealNameType === '1'" />
    <img
      v-img-src="load_icon"
      alt=""
      class="w-[12px] h-[12px] mr-[1vw]"
      v-else />
    <p class="text-primary text-[12px]">
      {{
        memberData.nRealNameType === "-1"
          ? $t("MemberFunction.MemberAccount.NoVerified")
          : memberData.nRealNameType === "0"
          ? $t("MemberFunction.MemberAccount.Verifying")
          : memberData.nRealNameType === "1"
          ? $t("MemberFunction.MemberAccount.Verified")
          : $t("MemberFunction.MemberAccount.Undefined")
      }}
    </p>
  </div>
</template>
