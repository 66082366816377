<script setup lang="ts">
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useLogin } from "@/stores/login";
import { useErrorAlert } from "@/stores/errorAlert";
import { useMemberService } from "@/stores/memberFunction/memberService";
import { useCounterStore } from "@/stores/counter";
import { useContentInfo } from "@/stores/contentInfo";
import { useI18n } from "vue-i18n";

import app_foot_left_0 from "@image/memberFunction/01/app_foot_left_0.png";
import app_foot_extend_0 from "@image/memberFunction/01/app_foot_extend_0.png";
import app_foot_center_0 from "@image/memberFunction/01/app_foot_center_0.png";
import app_foot_right_0 from "@image/memberFunction/01/app_foot_right_0.png";
import gift02_120120 from "@image/memberFunction/01/gift02_120x120.gif";
import app_foot_share from "@image/memberFunction/01/app_foot_share.png";
import app_foot_customer from "@image/memberFunction/01/app_foot_customer.png";
import app_foot_my from "@image/memberFunction/01/app_foot_my.png";

import mainBtnImg from "@image/memberFunction/01/app_foot_home.png";
import mainBtnImg_s from "@image/memberFunction/01/app_foot_home.png";

const { t } = useI18n();
const login = useLogin();
const router = useRouter();
const memberService = useMemberService();
const contentInfoStore = useContentInfo();
const errorAlert = useErrorAlert();
const store = useCounterStore();
const { MailCount } = storeToRefs(memberService);
const { memberData, isCashMember, isLogin } = storeToRefs(login);
const { UserAgentWebview } = storeToRefs(store);
const { WebCreditType } = storeToRefs(contentInfoStore);

function toPath(path: string) {
  // console.log(login.isLogin, memberData.value.nRank);

  if (login.isLogin) {
    // if (path === "ShareMakeFortunePage") {
    //   return errorAlert.AddErrorAlert("敬請期待");
    // }
    if (path === "ShareMakeFortunePage" && memberData.value.nRank !== "0") {
      return errorAlert.AddErrorAlert(t("Footer.onlyMmembers"));
    }
    if (path === "ShareMakeFortunePage" && !isCashMember.value) {
      return errorAlert.AddErrorAlert("您的帳號無法使用此優惠");
    }
    // if (path === "Discount" && !isCashMember.value) {
    //   return errorAlert.AddErrorAlert("您的帳號無法使用此優惠");
    // }
    router.push({ name: path });
  } else {
    router.push({ name: "login" });
  }
}

function toService() {
  router.push({ name: "Service" });
}
function toDiscount() {
  router.push({ name: "Discount" });
}

const activeBtn = ref<boolean>(true);
function touchStartMainBtn() {
  activeBtn.value = true;
}
function touchEndMainBtn() {
  activeBtn.value = false;
}
</script>

<template>
  <div
    class="fixed w-[100%]"
    :class="
      UserAgentWebview ? 'h-[70px]  bottom-[0px]' : 'h-[56px] -bottom-[4px]'
    ">
    <!-- bg -->
    <section
      class="flex justify-between"
      :class="UserAgentWebview ? 'h-[70px] ' : 'h-[56px] '">
      <img v-img-src="app_foot_left_0" alt="" class="object-fill grow-0" />
      <div class="grow">
        <img
          v-img-src="app_foot_extend_0"
          alt=""
          class="h-full object-fill w-full" />
      </div>
      <img v-img-src="app_foot_center_0" alt="" class="w-[28%] grow-0" />
      <div class="grow">
        <img
          v-img-src="app_foot_extend_0"
          alt=""
          class="h-full object-fill w-full" />
      </div>
      <img
        v-img-src="app_foot_right_0"
        alt=""
        class="h-full object-fill grow-0" />
    </section>

    <button
      @click="toPath('main')"
      @touchstart="touchStartMainBtn"
      @touchend="touchEndMainBtn">
      <img
        v-img-src="!activeBtn ? mainBtnImg : mainBtnImg_s"
        alt=""
        class="absolute -translate-y-1/2 -translate-x-1/2"
        :class="
          UserAgentWebview
            ? 'top-[12px] left-[calc(50%)] h-[68%]'
            : 'top-[2px] left-[calc(50%-1px)] h-[90%]'
        " />
      <p
        class="absolute text-[12px] -translate-x-1/2 left-1/2 text-primary font-bold"
        :class="UserAgentWebview ? 'bottom-[14%] ' : 'bottom-[9.5%] '">
        {{ $t("Footer.MainPage") }}
      </p>
    </button>
    <ul
      class="absolute top-[54.5%] w-[26%] -translate-y-1/2 left-[17.5%] -translate-x-1/2 grid grid-cols-2 gap-[25%]">
      <li></li>
      <li class="absolute -bottom-[75%] -translate-y-1/2">
        <button
          class="flex flex-col justify-center items-center w-[70px]"
          @click="toPath('Discount')">
          <img
            v-img-src="gift02_120120"
            alt=""
            class="h-[50px] object-contain" />
          <p
            class="text-primary text-[12px] translate-y-0 font-bold whitespace-nowrap">
            {{ $t("Footer.DiscountPage") }}
          </p>
        </button>
      </li>

      <li class="flex flex-col justify-center items-center w-[80px]">
        <button
          v-if="(!isCashMember && isLogin) || (WebCreditType === 1 && !isLogin)"
          class="flex flex-col justify-center items-center"
          @click="toPath('BetRecord')">
          <img
            v-img-src="app_foot_share"
            alt=""
            class="h-[25px] object-contain" />
          <p class="text-primary text-[12px] font-bold">
            {{ $t("Footer.BetRecord") }}
          </p>
        </button>
        <button
          v-else
          class="flex flex-col justify-center items-center"
          @click="toPath('ShareMakeFortunePage')">
          <img
            v-img-src="app_foot_share"
            alt=""
            class="h-[25px] object-contain" />
          <p class="text-primary text-[12px] font-bold">
            {{ $t("Footer.SharePage") }}
          </p>
        </button>
      </li>
    </ul>
    <ul
      class="absolute top-[54.5%] w-[26%] -translate-y-1/2 right-[22.5%] translate-x-1/2 grid grid-cols-2 gap-[30%]">
      <li class="flex flex-col justify-center items-center w-[50px]">
        <button
          @click="toService"
          class="flex flex-col justify-center items-center">
          <img
            v-img-src="app_foot_customer"
            alt=""
            class="h-[25px] object-contain" />
          <p class="text-primary text-[12px] font-bold">
            {{ $t("Footer.ServicePage") }}
          </p>
        </button>
      </li>
      <li class="flex flex-col justify-center items-center w-[50px]">
        <button
          @click="toPath('MemberAccount')"
          class="relative flex flex-col justify-center items-center">
          <img v-img-src="app_foot_my" alt="" class="h-[25px] object-contain" />
          <div
            class="absolute -top-1 right-0 bg-red-600 w-[8px] h-[8px] rounded-full"
            v-if="MailCount !== '0'"></div>
          <p class="text-primary text-[12px] font-bold">
            {{ $t("Footer.MyPage") }}
          </p>
        </button>
      </li>
    </ul>
  </div>
</template>

<style>
.bg-0100 {
  background-size: 10% 100%;
}
</style>
